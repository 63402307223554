import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import ayan from "../../assets/testimonials/ayan.webp"
import mihir from "../../assets/testimonials/mihir.webp"
import jitendra from "../../assets/testimonials/jitendra.webp"
import faizan from "../../assets/testimonials/faizen.webp"
import naved from '../../assets/testimonials/navedbhai.jpg'
import { Link } from 'react-router-dom';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

// import "../../Styles/Footer.css"

const Testimonials = () => {
  // const [display, setDisplay] = useState(true);
  // const [width, setWidth] = useState(600);

  const settings = {
    autoplay: true,
    autoplaySpeed: 1200,
    arrow: true,
    pauseOnFocus: false,
    pauseOnHover: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  const testimonialData = {
    testimonial1: {
      name: "Ayan Ansari",
      occupation: "BCA,Student",
      testimony:
        `"Bytefaze is the best way to give up..❤️Giving best education and performance and there's all faculty is truly doing great work for students"`,
      image: ayan,
      linkedin: "https://www.linkedin.com/in/ayan-ansari",
      instagram: "",
      github: "https://github.com/rohanansari7"
    },
    testimonial2: {
      name: "Gupta Mihir",
      occupation: "B-Tech,Student",
      testimony: `"The faculty has good knowledge of the course contents and is very helpful in understanding the concepts"`,
      image: mihir,
      linkedin: "https://www.linkedin.com/in/mihir-gupta-174aab2a3/",
      instagram: "",
      github: "https://github.com/mihir502"
    },
    testimonial3: {
      name: "Faizan Saiyad",
      occupation: "B-Com,Student",
      testimony: `"Having a good experience in Bytefaze Web Solutions, For Non-IT students and It students. This institute is very helpful and easy to understand.And had Great Experience of Learning NEW THINGS.Thank you Bytefaze"`,
      image: faizan,
      linkedin: "",
      instagram: "",
      github: ""
    },
    testimonial4: {
      name: "Jitendra Sharma",
      occupation: "B-Tech,Student",
      testimony: `
      "The initial impression upon arriving for training was somewhat exaggerated, but I'm now thoroughly satisfied with the services and technological guidance provided by the developers. Overall, the training experience has been beneficial, helping us pave the way for a brighter future."`,
      image: jitendra,
      linkedin: "https://www.linkedin.com/in/jitendrasharma08/",
      instagram: "",
      github: "https://github.com/im-jitendra"
    },
    
    testimonial5: {
      name: "Naved Husain Shaikh",
      occupation: "B.Sc IT Student",
      testimony: `
      "Collaborating with mentors during our Django course at Bytefaze was a fantastic experience. They consistently showed a deep understanding of the material and were always willing to help fellow students. Their problem-solving skills and attention to detail were evident in every project we worked on together."`,
      image: naved,
      linkedin: "https://www.linkedin.com/in/naved-husain-shaikh-82b017258/",
      instagram: "",
      github: ""
    }

  };
  return (

    <div style={{ background: "var(--footer-bg)" }} className='py-4'>


      <div className="container overflow-hidden px-4 " >
        <div className="slider-container ">
          <h3 className='mb-5 text-center  ' style={{ color: "var(--primary-color)" }}>What Our Students Say</h3>

          <Slider {...settings} >

            {Object.keys(testimonialData).map(key => {
              const { name, occupation, testimony, image, linkedin, instagram, github } = testimonialData[key];
              return (
                <div className="col-md-4 testi-container border" key={key} >
                  <div className=" text-center ">
                    <div className="" >
                      {/* <h5 className="card-title">{name}</h5>
                <p className="card-text">{occupation}</p> */}
                      <p className="card-text">{testimony}</p>
                    </div>
                    <img 
                      src={(`${image}`)}
                      className="card-img-top mx-auto mt-3 mb-3"
                      style={{ width: "80px", height: "80px", borderRadius: "100%" }}
                      alt={name}
                    />
                    <h5 className="card-title">{name}</h5>
                    <span className='px-3 d-flex gap-2 justify-content-center'>
                      <div>

                        {
                          linkedin ? <Link to={linkedin ? linkedin : ""} target='_blank' className='top-para' > <i className="ri-linkedin-box-fill h5"></i></Link> : ""
                        }
                      </div>
                      <div>
                        {
                          instagram ? <Link to={instagram ? instagram : ""} target='_blank' className='top-para' ><i className="ri-instagram-fill h5"></i></Link> : ""
                        }

                      </div>
                      <div>
                        {
                          github ? <Link to={github ? github : ""} target="_blank" className='top-para' > <i className="ri-github-fill h5"></i></Link> : ""}

                      </div>
                    </span>
                    <p className="card-text">{occupation}</p>




                  </div>

                </div>
              );
            })}

          </Slider>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Testimonials)