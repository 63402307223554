import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase/firebase';
import { useAuth } from '../context/AuthContext';

const AdminRoute = ({ children }) => {
    const { user, isLoading } = useAuth();
    const navigate = useNavigate();
  
    if (isLoading) {
      return <div>Loading...</div>; // Show a loading indicator while checking the auth state
    }
  
    if (!user || user.role !== 'admin') {
      navigate('/'); // Redirect to homepage if not logged in or not an admin
      return null;
    }
  
    return children;
  };
  

export default AdminRoute;