// IndustriesComponent.js
import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { FaDollarSign, FaHeartbeat, FaCar, FaPlug, FaShoppingBag, FaFilm, FaCalendarAlt, FaPlane, FaBullhorn, FaBuilding, FaBook, FaDumbbell, FaFootballBall, FaGamepad, FaShieldAlt } from 'react-icons/fa';

const industries = [
  { icon: <FaDollarSign />, label: 'Financial' },
  { icon: <FaHeartbeat />, label: 'Healthcare' },
  { icon: <FaShieldAlt />, label: 'Insurance' },
  { icon: <FaCar />, label: 'Automobile' },
  { icon: <FaPlug />, label: 'Energy and Utility' },
  { icon: <FaShoppingBag />, label: 'Retail & Ecommerce' },
  { icon: <FaFilm />, label: 'Media & Entertainment' },
  { icon: <FaCalendarAlt />, label: 'Events & Exhibitions' },
  { icon: <FaPlane />, label: 'Travel & Tourism' },
  { icon: <FaBullhorn />, label: 'Branding & Promotion' },
  { icon: <FaBuilding />, label: 'Real Estate' },
  { icon: <FaBook />, label: 'Education' },
  { icon: <FaDumbbell />, label: 'Fitness & Wellness' },
  { icon: <FaFootballBall />, label: 'Sports' },
  { icon: <FaGamepad />, label: 'Gaming' },
];

const IndustriesComponent = () => {
  return (
    <div className="container top-para text-center mt-5 mb-3" >
      <h2>Expanding Our Expertise Across Diverse Industries as a Leading Development Firm</h2>
      <p className='mt-3'>Industries we deal in:</p>
      <Row>
        {industries.map((industry, index) => (
          <Col key={index} xs={6} md={4} lg={3} className="my-3">
            <div className="p-3 border rounded shadow-sm text-center">
              <div className="mb-2 arvr-icon" style={{ fontSize: '20px' }}>
                {industry.icon}
              </div>
              <h5 className='top-para text-center'>{industry.label}</h5>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default IndustriesComponent;
 