import React from "react";

import Demo from "./Demo";
import QuickLinks from "./QuickLinks";
import Layouts from "../layout/Layouts";
import webdesign from "../../assets/WebpImages/webdesign.webp";
import res2 from "../../assets/WebpImages/res2.webp";
import web3 from "../../assets/WebpImages/web32.webp";
import ecom1 from "../../assets/WebpImages/website-maintenance.webp"
import res from "../../assets/WebpImages/responsive-website.webp";
const WebDesignSanfrancisco = () => {
  const heroSection = {
    backgroundImage: "https://via.placeholder.com/1600x400",
    heading: "Web Design in San Francisco ",
    paragraph:
      "Transform Your Digital Presence with Bytefaze's Premier Web Design & development services in San Francisco",
  };
  const contentArray = [
    {
      image: webdesign,
      heading: "Custom Website Design",
      text: "At Bytefaze, we understand that a one-size-fits-all approach doesn’t work in today’s competitive market. That’s why our custom website design services are tailored to reflect the unique identity of your business. Our team of skilled designers and developers works closely with you to create a website that not only looks stunning but also functions seamlessly. We focus on your brand’s values and goals to craft a site that speaks directly to your target audience, setting you apart from your competitors.",
    },
    {
      image: res2,
      heading: "Responsive Website Design",
      text: "With an increasing number of users accessing websites from mobile devices, responsive website design is crucial. At Bytefaze, we prioritize creating websites that offer an optimal viewing experience across all devices—whether it's a smartphone, tablet, or desktop computer. Our responsive designs ensure that your website adapts to different screen sizes and resolutions, providing a smooth and engaging user experience no matter where your audience is browsing from. This approach not only enhances user satisfaction but also improves your site’s SEO performance.",
    },
    {
      image: web3,
      heading: "Web Application Development",
      text: "As a leading web application development company, Bytefaze is equipped to build sophisticated web applications that drive your business forward. Whether you need a customer portal, a complex data management system, or a custom CRM solution, our development team has the expertise to deliver high-quality applications tailored to your specific requirements. We leverage the latest technologies and best practices to ensure that your web applications are robust, scalable, and secure.",
    },
    {
        image: res,
        heading: "Branding and SEO Services",
        text: "Effective branding is essential for establishing a strong online presence. Our branding services focus on creating a cohesive and impactful brand identity that resonates with your target audience. We help you define your brand’s voice, visual elements, and messaging to ensure consistency across all platforms. Complementing our branding efforts, our SEO services are designed to enhance your website’s visibility on search engines. We conduct thorough keyword research, implement on-page and off-page SEO strategies, and continuously monitor and adjust our tactics to improve your search engine rankings. By optimizing your website for relevant keywords, we help drive organic traffic and increase your chances of attracting potential customers in the San Francisco area.",
      },
      {
        image: ecom1,
        heading: "E-commerce Website Design",
        text: "For businesses looking to expand their online sales, our e-commerce website design services offer a comprehensive solution. We design and develop user-friendly e-commerce platforms that facilitate smooth transactions and enhance the shopping experience. Our e-commerce websites are equipped with features such as secure payment gateways, product catalog management, and customer support tools to help you build a successful online store. Whether you’re starting from scratch or upgrading an existing store, we provide customized solutions that align with your business objectives and drive sales growth.",
      },
    // more content objects
  ];

  const ctaContent = {
    text: "Take the Next Step Towards Digital Excellence!",
    para : "Contact us today to discuss how our expert team can bring your vision to life with innovative and effective web design solutions tailored to your needs. Let Bytefaze be your partner in achieving online success.",
    buttonLabel: "Request A Meeting",
  };

  const ctaIndex = 2;

  const textSections = [
    {
      index: 0,
      heading: "Top Website Designing Company",
      text: "Welcome to Bytefaze Web Solutions, your premier destination for exceptional website designing services in San Jose. In today’s digital landscape, having a professionally designed website is crucial for standing out in the competitive market. Our team of skilled designers and developers is dedicated to crafting bespoke websites that not only captivate your audience but also drive results. Whether you're a startup or an established business, we provide tailored solutions to meet your unique needs and goals.",
    },
    {
      index: 2,
      heading: "Why Choose Us",
      text: "Choosing Bytefaze Web Solutions means partnering with a team that prioritizes your success. Here’s why you should consider us for your website designing needs: <ul><li><b>Expertise and Experience:</b> Our team has extensive experience in designing and developing high-quality websites that meet industry standards and best practices.</li><li><b>Client-Centric Approach:</b> We take the time to understand your business and goals, ensuring that our solutions are tailored to your specific needs.</li><li><b>Timely Delivery:</b> We take the time to understand your business and goals, ensuring that our solutions are tailored to your specific needs.</li><li><b>Ongoing Support:</b> Post-launch, we provide ongoing support to address any issues and make necessary updates. We also offer optimization services to enhance performance, security, and SEO.</li></ul>",
    },

    // Add as many text sections as needed
  ];

  const linksData = [
    {
      text: "Ecommerce Website Development",
      url: "/ecommerce-website",
    },
    { text: "Mobile Application Development", url: "/application-development" },
    { text: "Latest Trends of Website Designing", url: "/blog/riding-the-wave-unveiling-the-latest-trends-in-web-design" },
    { text: "Responsive Website Design ", url: "/responsive-website" },
    
    // Add more links as needed
  ];

  const searchKeywordsData = [
    "Top Web Designers in San Francisco",
    "Web Development Company in Japan",
    "Best Web Designing Services in San Francisco","Customized Web Designing Services","Mobile Optimized Web Apps Developer","Web Marketing Agency in San Francisco", "Social Media Marketing in San Francisco",
    // Add more keywords as needed
  ];

  return (

    <Layouts
      title={"Website Design in San Francisco | Affordable Custom Web Design Services"}
      keywords={
        "Website Design San Francisco, Web Design Agency, Custom Website Design, Responsive Website design, Web Application Development Company, Branding, SEO Services, Social Media Marketing, E-commerce Website Design"
      }
      description={
        "Elevate your brand with Bytefaze Web Solutions, the top choice for website design in San Francisco. Custom, responsive designs, e-commerce, SEO, and more. Contact us today"
      }
      canonical={"https://www.bytefaze.com/website-design-in-san-francisco"}
      ldName={"Web Design & Development in San Francisco"}
      serviceType={"Website Designing & Development Service in San Francisco"}
    >

    

      <Demo
        contentArray={contentArray}
        textSections={textSections}
        ctaContent={ctaContent}
        ctaIndex={ctaIndex}
        heroSection={heroSection}
        myQuicklinks={<QuickLinks links={linksData} searchKeywords={searchKeywordsData} />}
      />
  </Layouts>


  );
};

export default WebDesignSanfrancisco;
