import React from 'react';
import webcalc from "../../assets/pricing/webcalculator.svg"
import seocalc from "../../assets/WebpImages/web-design.webp"
import portfolio from "../../assets/pricing/portfolio-icon.png"
import calcSvg from "../../assets/pricing/Calculator-cuate.svg"
import { Link } from 'react-router-dom';
const WebCalculator = () => {
  return (

    <div className="container">
         <h2 className="text-center mt-5 top-para">
          Website Design Cost Calculator 
        </h2>
        <hr
          style={{
            borderTop: "3px solid #ffc107",
            width: "100px",
            margin: "20px auto",
            opacity: "1",
          }}
        />
      <div className="row">
        
        {/* Main Image on the Left */}
        <div className="col-md-6 d-flex align-items-center justify-content-center">
          <img
            src={calcSvg}
            alt="website development cost calculator"
            className="img-fluid"
          />
        </div>

        {/* Boxes on the Right */}
        <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
          {/* SEO Cost Calculator */}
          <Link className="card mb-3 w-75 ml-md-5 " to={"/web-development-packages-pricing"} >
            <div className="card-body card-body-cool d-flex align-items-center">
              <img
                src={seocalc}
                alt="website Design Cost Estimate"
                className="me-3"
                style={{ width: '50px', height: '50px' }}
              />
              <h5 className="card-title mb-0">Want to Know Our Website Packages? </h5>
            </div>
          </Link>

          {/* Website Design Cost Calculator */}
          <Link className="card mb-3 w-75"  to={"/website-design-cost-calculator"}>
            <div className="card-body card-body-cool d-flex align-items-center">
              <img
                src={webcalc}
                alt="Free Online Website Cost Calculator"
                className="me-5"
                style={{ width: '50px', height: '50px' }}
              />
              <h5 className="card-title mb-0">Calculate Your Custom Website Cost</h5>
            </div>
          </Link>

          {/* Logo Design Cost Calculator */}
          <Link className="card mb-3 w-75" to={"/portfolio"}>
            <div className="card-body card-body-cool d-flex align-items-center">
              <img
                src={portfolio}
                alt="Free Website Cost Calculator"
                className="me-3"
                style={{ width: '50px', height: '50px', objectFit:"contain" }}
              />
              <h5 className="card-title mb-0">Visit Our Portfolio</h5>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default WebCalculator;
