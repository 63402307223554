import React from 'react'
import Layout from '../../layout/Layouts'
import img1 from '../../../assets/WebpImages/web-design-internship.webp'
import { Link } from 'react-router-dom'
import Card from '../Card'
import logo from '../../../assets/WebpImages/bytedark.webp'
//import { LazyLoadImage } from "react-lazy-load-image-component";

const WebDevelopmentInternship = () => {

  const data =  
  { 
        tag: "Web Development Internship in Ahmedabad",
        description:"Join bytefaze as a Web Developer Intern. Learn fundamental to advance coding in React JS, Angular JS, C/C++, Java, Python, with 100% job assistance. Apply now for full-time/part-time Front end, Back End, Full stack web development internship & Training in Ahmedabad",
        keywords:"Full Time Web Development Internship, Project training in Ahmedabad, Project training Ahmedabad, Free project training, Free project training Ahmedabad, Live Project Training, Free live project training, MCA Project Training, BE Project Training, BCA Project Training,B.tec Project Training, MBA Project Training, Summer Internship for IT Students in Web Development, Full Stack Web Development Internship, Web Development Internship Ahmedabad, Back End Web Development Internship, Frond End Web Development Internships, Web Development Intern Ahmedabad, Web Development Internship Jobs",
        img: img1,
        altTag: "Web Development Internship Ahmedabad", //image Alt tag 
        label:"Web Development Internship Ahmedabad",
        title:"Best Web Development Internship Near Me | Web Developer Intern Jobs Vaccancy in Bytefaze",
        PageTitle:"Best Web Development Internships in Ahmedabad",
        date: "06 May 2024",
        desc: (<p className="top-para">Ahmedabad, the vibrant heart of Gujarat, is a booming hub for information technology and innovation. Bytefaze is a <b>best web development company </b>specializing in providing Web developer internship & training in <b> C, C++, Java, PHP, Python, React JS, and UI/UX design.</b> We're passionate about nurturing the next generation of tech talent. This blog is your one-stop guide to web development internships in Ahmedabad, particularly if you're a budding programmer eager to take your first steps into the exciting world of web development.
    </p>),
        img2:img1,
        points:(
            <>
            {/* point1 */}
            <div className="container w-100">
            <div className="row  item-align-center col-md-12 text-center">
              <table className="table border"> 
             <thead><tr>
              <th style={{color:"var(--link-color)"}}>Table of Contents</th></tr>
              </thead>
              <tr>
                <td className='pt-1 pb-1'><a href="#web-development-internship-ahmedabad">Why Consider Ahmedabad for Web Development Internship ?</a></td>
                </tr>
                <tr>
                  <td className='pt-1 pb-1'><a href='#what-we-offer'>What We Offer</a></td>
                  </tr>
                <tr>
                  <td className='pt-1 pb-1'><a href='#benefits-of-interning-with-bytefaze'>Benefits of Interning with Bytefaze </a></td>
                  </tr>
                <tr>
                  <td className='pt-1 pb-1'><a href='#skills-for-web-development-internship'>Skills to Develop for a Successful Web Development Internship</a></td>
                </tr> 
                <tr>
                  <td className='pt-1 pb-1'><a href='#landing-your-dream-internship'>Landing Your Dream Internship: Tips & Resources
</a></td>
                </tr>
              </table>
            </div>
            </div>
            
            <h2 style={{color:"var(--link-color)"}} id='web-development-internship-ahmedabad'>Why Ahmedabad is Best for Web Development Internship?</h2>
            
            <div className="top-para">

        
          <p className='top-para'>
          Ahmedabad offers a unique blend of rich cultural heritage and a thriving tech ecosystem. Here's what makes it an ideal location for your<Link style={{ color: 'blue' }} to='/internships'> <b>web development internship</b></Link>:
          </p>
          </div>
            
               {/* point1 */}
           <ul style={{color:"var(--link-color)"}}  className='top-para'>
            <li className="mb-2"><b>Thriving Tech Industry:</b> Ahmedabad boasts numerous web development companies working on cutting-edge IT projects. This translates to diverse web development internship opportunities across various technologies.</li>
            <li className="mb-2"><b>Affordable Living:</b> Compared to bigger metropolises, Ahmedabad offers a lower cost of living, making it easier to manage IT training expenses.</li>
            <li className="mb-2"><b>Learning & Growth Environment:</b> Many companies in Ahmedabad prioritize intern development. You'll gain valuable mentorship, practical experience, <Link to='/blog/live-project-training-ahmedabad'>live project training </Link>and hone your technical skills.</li>
            <li className="mb-2"><b>Networking Opportunities:</b> Internships are a fantastic way to connect with experienced web developers, build your professional network, and gain valuable IT career insights.</li>
           </ul>
            {/* point3 */}
               <h2 style={{color:"var(--link-color)"}} id='what-we-offer'>IT Internship in Web Development with Latest Technologies</h2>  
                      <p className="top-para">As top IT company, we believe in fostering a dynamic learning environment where <Link style={{color:'blue'}} to="/"><b>Web Developer Interns</b></Link> can gain hands-on experience with some of the most sought-after technologies:</p>
                    <ol style={{color:"var(--link-color)"}} className='top-para'>
                        <li className="mb-2"><b>Programming Languages</b>
                        <ul className='top-para'>
                            <li className="mb-2 mt-2"><b>C and C++:</b> Gain a strong foundation in object-oriented programming principles through internships focused on these powerful languages.</li>
                            <li className='mb-2'><b>Java:</b> Dive into the world of enterprise application development by working on real-world projects utilizing Java's robust capabilities.</li>
                            <li className="mb-2"><b>PHP:</b> Get your hands dirty with server-side scripting through PHP internships, crucial for building dynamic web applications.</li>
                            <li className="mb-2"><b>Python:</b> Explore the versatile world of Python, a language perfectly suited for web development, data science, and automation tasks. </li>
                        </ul>
                        </li>
                        <li className="mb-2"><b>Front-End Development Frameworks</b>
                        <ul>
                            <li className="mb-2 mt-2"><b>React JS:</b> Learn how to build interactive and user-friendly web interfaces using React JS, a leading front-end framework.</li>
                            <li className="mb-2"><b>AngularJS:</b> Understand the power of AngularJS, a popular JavaScript framework for building structured and dynamic web applications.</li>
                        </ul>
                        </li>
                        <li className="mb-2"><b>Front-End Development Languages</b>
                        <ul>
                            <li className="mb-2 mt-2"><b>HTML:</b> Master the foundation of web development by learning HTML, the language used to structure web pages.</li>
                            <li className="mb-2"><b>CSS:</b> Gain expertise in CSS, the language that controls the visual style and layout of web pages, allowing you to create beautiful and user-friendly interfaces.</li>
                         </ul>
                        </li>
                        <li className="mb-2"><b>UI/UX Design</b>
                        <ul>
                            <li className="mb-2 mt-2"><b>UI/UX Design:</b> Understand the user journey and create visually appealing and intuitive user interfaces through UI/UX design internships.</li>
                            
                         </ul>
                         </li>
                          </ol>
               {/* point3*/}
                          <h3 style={{color:"var(--link-color)"}} id='benefits-of-interning-with-bytefaze'>Benefits of Interning with Bytefaze (Paid Services)</h3>
                          <p className='top-para'>We offer well structured internship programs designed to equip you with the skills and experience needed to thrive in the IT industry. Our internship fees are affordable, making it an accessible learning opportunity. Here's what you'll gain by interning with us:</p>
           <ul className="top-para">
            <li className="mb-2"><b>Mentorship:</b> Be guided by experienced developers who will not only assign tasks but also answer your questions and provide valuable insights.</li>
            <li className="mb-2"><b>Real-World Projects:</b> Gain practical experience by working on genuine client projects, contributing to a company's growth and development.</li>
            <li className="mb-2"><b>Skill Development:</b> Enhance your technical skills in your chosen area of focus, whether it's back-end development, front-end development, or UI/UX design.</li>
            <li className="mb-2"><b>Industry Exposure:</b> Get a glimpse into the inner workings of a web development company, understanding the project life cycle and team dynamics.</li>
            <li className="mb-2"><b>Networking Opportunities:</b> Network with experienced developers, designers, and other interns, building crucial connections for your future career.</li>
            <li className="mb-2"><b>Potential Job Offer:</b> Stand out with your dedication and performance, potentially landing a full-time job after your internship.</li>
            <li className="mb-2"><b>100% Job Assistance:</b> ByteFaze goes the extra mile! We provide dedicated job placement assistance to help you land your dream role after your internship. Our team will help you with resume building, interview preparation, and connecting you with potential employers in the web development industry.</li>
           </ul>
          <div className='d-flex justify-content-center '>
             <Link to="/contact-us">
                  <button
                    className="button-85 mb-4 col-12 col-lg-12 mt-2"

                  >
                    Join us as Web Developer Intern 
                  </button>
                </Link>
                </div>
            {/* point4*/}
            <h3 style={{color:"var(--link-color)"}} id='skills-for-web-development-internship'>Skills to Develop for a Successful Web Development Internship</h3>
          <p className="top-para">While specific skillsets may vary depending on the internship focus, here are some key areas to focus on:</p>
          
          <ul style={{color:"var(--link-color)"}} className='top-para'>
            <li className="mb-2"><b>Programming Fundamentals:</b> Grasp the fundamental concepts of programming, including data structures, algorithms, and problem-solving techniques.</li>
            <li className="mb-2"><b>Problem-Solving:</b> Develop your ability to analyze problems, identify root causes, and design efficient solutions.</li>
            <li className="mb-2"><b>Communication Skills:</b> Hone your communication abilities to effectively collaborate with team members and express your ideas clearly.</li>
            <li className="mb-2"><b>Teamwork:</b> Learn to work effectively in a team environment, collaborating on projects and achieving common goals.</li>
            <li className="mb-2"><b>Learning Agility:</b> Embrace the ever-evolving nature of technology by demonstrating a willingness to learn new things quickly.</li>
          </ul>
          <h4 style={{color:"var(--link-color)"}} id='landing-your-dream-internship'>Landing Your Dream Internship: Tips & Resources</h4>
          <p className="top-para">The following tips can help you stand out in the competitive IT Training & internship landscape:</p>
          <ul style={{color:"var(--link-color)"}} className='top-para'>
          <li className="mb-2"><b>Build a Strong Online Presence: </b>Create a well-crafted resume and portfolio showcasing your skills and experience. Utilize platforms like GitHub to demonstrate your coding prowess.</li>
          <li className="mb-2"><b>Target Relevant Companies: </b>Research companies specializing in technologies that align with your interests and apply for internship in Web Development programs specifically offered by those companies.</li>
          <li className="mb-2"><b>Prepare for Interviews: </b>Research the company and potential interview questions. Practice your problem</li>
            </ul>
          {/* point5*/}
        
                    
            </>

        ),
        lastp:"Want to know how to get internship as web developer interns in Bytefaze, Ahmedabad, Gujarat, India. Please contact us by email or phone. We will be delighted to assist you with your Web Development Intern inquiry.",
        
        // "In today's digital world, having a strong online presence is essential for any local business. A well-designed website acts as your digital storefront, attracting new customers and keeping existing ones engaged. Here's a breakdown of website design services specifically tailored for local businesses:"
      
    
    
        Fieldtags:( <>
          <div className="container mt-1">
            <div className="row">
              <div className="col">
                <div className="d-flex flex-wrap">
                  <Link to='/' className="btn btn-outline-secondary m-1">Web Development Internship Ahmedabad</Link>
                  <Link to='/website-designing' className="btn btn-outline-secondary m-1">Web Development Intern Jobs in Ahmedabad</Link>
                  <Link to='/#' className="btn btn-outline-secondary m-1">Ahmedabad Web Development Company</Link>
                  <Link to='/responsive-website/' className="btn btn-outline-secondary m-1">Paid Web Development Internship</Link>
                  <Link to='/digital-marketing/' className="btn btn-outline-secondary m-1">Full Time Web Developer Internship</Link>
                  <Link to='/website-designing' className="btn btn-outline-secondary m-1">Part Time Web Developer Internship</Link>
                  <Link to='/ecommerce-website' className="btn btn-outline-secondary m-1">Full Stack Web Developer Internship</Link>
                  <Link to='/#' className="btn btn-outline-secondary m-1">Web Development Internship Near Me</Link>
                  <Link to='/website-designing' className="btn btn-outline-secondary m-1">Intern Web Development Jobs</Link>
                  <Link to='/#' className="btn btn-outline-secondary m-1">How to get Internship as Web Developer</Link>
                  <Link to='/#' className="btn btn-outline-secondary m-1">Web Developer Intern Salary</Link>
          
                </div>
              </div>
            </div>
          </div>
          
          
          </>)

  }

  return (
    <Layout
    title={data?.title}
    keywords={data?.keywords}
    description={data?.description}
    canonical={"https://www.bytefaze.com/blog/web-development-internship-in-ahmedabad/"}
    ldName={"Web Development Internship in Ahmedbaad"}
    ldImage={img1}
  >
{/* <!-- Featured 1 - Bootstrap Brain Component --> */}
<section className="py-3 py-md-5 py-xl-8 ">
<div className="container">
<div className="row gy-3 gy-lg-0 align-items-lg-center justify-content-lg-between" style={{color:"var(--link-color)"}}>
  <div className="col-12 col-lg-7 order-1 order-lg-0">
    <div className="entry-header mb-3">
      <ul className="entry-meta list-unstyled d-flex mb-3">
        <li className="mb-2">
          <a className="d-inline-flex px-2 py-1 link-accent text-accent-emphasis bg-accent-subtle border border-accent-subtle rounded-2 text-decoration-none fs-7"  href="#">{data?.label}</a>
        </li>
      </ul>
      <h1 className="display-3 fw-bold mb-4">{data?.PageTitle}</h1>
    </div>
    <div className="entry-footer">
      <ul className="entry-meta list-unstyled d-flex align-items-center m-0">
        <li className="mb-2">
          <a className="fs-7  text-decoration-none d-flex align-items-center" href="#">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fillRule="currentColor" className="bi bi-calendar3" viewBox="0 0 16 16">
              <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
              <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
            </svg>
            <span className="ms-2 fs-7" style={{color:"var(--link-color)"}}>{data?.date}</span>
          </a>
        </li>
       
   
      </ul>
    </div>
  </div>
  <div className="col-12 col-lg-4">
    <a className="d-block bsb-hover-image overflow-hidden rounded" href="#">
      <img  className="img-fluid bsb-scale bsb-hover-scale-up" src={img1} alt="Web Developement Internship Ahmedabad"/>
    </a>
  </div>
</div>
</div>
</section>

{/* <!-- Main --> */}
<main id="main">

{/* <!-- Entry Content Bootstrap Brain Component --> */}
<section className="py-3 py-md-5 py-xl-10">
<div className="container">
  <div className="row justify-content-md-center gy-3 gy-xl-4">
    <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
      {data?.desc}
      {/* <p className="lead fw-bold">{data?.desc} <a href="#">emotional support</a> in a way that is healthy for both you and your partner.</p> */}
    </div>

    <div className="col-12 text-center">
      <img  className="img-fluid my-4 rounded" src={img1} alt="Web Development Internship Ahmedabad"/>
    </div>

    <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
     {data?.points}
      <p>{data?.points2}</p>
      {/* <h2 style={{color:"var(--link-color)"}}>Offer Physical Affection</h2> */}
      {/* <img  className="img-fluid mt-3 mb-5 rounded" src={img1} alt=""/> */}
      <p>{data?.lastp}</p>
      {/* <p>Different people like different levels of physical affection at different times. If you are feeling unsure, communicate with your partner so you can learn what they are open to receiving and can provide it accordingly.</p> */}
    </div>
  </div>
</div>
</section>

{/* <!-- Entry Meta --> */}
<div className="pb-3 pb-md-5 pb-xl-10" >
<div className="container">
  <div className="row justify-content-md-center">
    <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
      <span className="me-1 fw-bold" style={{color:"var(--link-color)"}}> Tags:</span>
      <ul className="entry-meta list-unstyled d-inline-flex align-items-center justify-content-center mb-3">
       {data.Fieldtags}
      </ul>
    </div>
  </div>
</div>
</div>

{/* <!-- Post Pagination 2 - Bootstrap Brain Component --> */}
<section className="bsb-post-pagination-2  py-0 py-md-0 py-xl-0 bsb-section-py-xxl-1X">
<div className="container overflow-hidden">
  <div className="row justify-content-lg-center">
    <div className="col-12 col-lg-10 col-xl-8 col-xxl-7">

 
    </div>
  </div>
</div>
</section>

{/* <!-- Author 2 - Bootstrap Brain Component --> */}
<section className="bsb-author-2 py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1X">
<div className="container">
  <div className="row justify-content-center">
    <div className="col-12 col-lg-10 col-xl-8 col-xxl-7">
      <div className=" border-light-subtle p-4 border">
        <div className="row gy-3 align-items-center">
          <div className="col-md-4">
            <a href="#" className="bsb-hover-image d-block rounded overflow-hidden">
              <img  className="img-fluid author-avatar bsb-scale bsb-hover-scale-up" src={logo} alt="Website Designing Company"/>
            </a>
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h6 className="card-subtitle mb-2  " style={{color:"var(--link-color)"}}>Article written by</h6>
              <h3 className="card-title mb-2">
                <a className="card-link  text-decoration-none" href="#">Bytefaze</a>
              </h3>
              <p className="card-text mb-3">We are skilled in all aspects of web design, from planning and development to design and testing. I also have experience in web development technologies.</p>
              <ul className="bsb-social-media nav m-0">
                <li className="nav-item">
                  <Link target='_blank' className="nav-link " to="https://www.facebook.com/bytefazewebsolutionsahmedabad/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fillRule="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                    </svg>
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link " href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fillRule="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                    </svg>
                  </a>
                </li> */}
                <li className="nav-item">
                  <Link target='_blank' className="nav-link" to="https://x.com/Bytefaze">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fillRule="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                    </svg>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link target='_blank' className="nav-link" href="https://www.linkedin.com/company/bytefaze/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fillRule="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                    </svg>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</section>

{/* <!-- Blog 5 - Bootstrap Brain Component --> */}
<section className="bsb-blog-5 py-3 py-md-5 py-xl-8 bsb-section-py-xxl-1X ">
<div className="container">
  <div className="row justify-content-md-center">
    <div className="col-12">
      <h2 className="h4 mb-4 mb-md-5" style={{color:"var(--link-color)"}}>Related Articles</h2>
    </div>
  </div>
</div>

{/* <div className="container overflow-hidden px-4"> */}
  {/* <div className="row gy-4 gy-md-5 gx-xl-6 gy-xl-6 gx-xxl-9 gy-xxl-9"> */}
    <Card/>
  {/* </div> */}
{/* </div> */}
</section>

{/* <!-- Newsletter 1 - Bootstrap Brain Component --> */}
<section className="py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1 bg-accent">
<div className="container">
  <div className="row justify-content-md-center">
    <div className="col-12 col-md-9 col-lg-8 col-xl-8 col-xxl-7">
      <h2 className="display-4 fw-bold mb-4 mb-md-5 mb-xxl-6 text-center text-accent-emphasis" style={{color:"var(--link-color)"}}>Sign up for our newsletter and never miss a thing.</h2>
    </div>
  </div>
  <div className="row justify-content-md-center">
    <div className="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
      <form className="row gy-3 gy-lg-0 gx-lg-2 justify-content-center">
        <div className="col-12 col-lg-8">
          <label htmlFor="email-newsletter-component" className="visually-hidden">Email Address</label>
          <input type="email" className="form-control bsb-form-control-3xl" id="email-newsletter-component"  placeholder="Email Address" aria-label="email-newsletter-component" aria-describedby="email-newsletter-help" required/>
          <div id="email-newsletter-help" className="form-text text-center text-lg-start">We'll never share your email with anyone else.</div>
        </div>
        <div className="col-12 col-lg-3 text-center text-lg-start">
          <button type="submit" className="btn border bsb-btn-3xl">Subscribe</button>
        </div>
      </form>
    </div>
  </div>
</div>
</section>

</main>
</Layout>
  )
}

export default WebDevelopmentInternship