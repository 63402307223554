import React from "react";

import Demo from "./Demo";
import QuickLinks from "./QuickLinks";
import Layouts from "../layout/Layouts";
import webdesign from "../../assets/WebpImages/webdesign.webp";
import res2 from "../../assets/WebpImages/res2.webp";
import web3 from "../../assets/WebpImages/web32.webp";
import ecom1 from "../../assets/WebpImages/ecom1.webp";
import res from "../../assets/WebpImages/responsive-website.webp";

const WebDesignNewyork = () => {
  const heroSection = {
    backgroundImage: "https://via.placeholder.com/1600x400",
    heading: "Web Design in New York ",
    paragraph:
      "Transform Your Digital Presence with Bytefaze's Premier Web Design & development services in New York",
  };
  const contentArray = [
    {
      image: webdesign,
      heading: "Top Professional Web Development Firm",
      text: "Recognized as a top web development firm, we offer a wide range of services, from custom web application development to full-scale e-commerce solutions. Our expert developers and designers work closely with you to deliver a website that not only looks great but also functions seamlessly, providing an exceptional user experience. Our professional website development services are designed to help your business succeed online. We use the latest technologies and design principles to create websites that are both visually appealing and highly functional. Whether you need a simple corporate site or a complex web application, we have the expertise to bring your vision to life.",
    },
    {
      image: res2,
      heading: "Affordable Web Design Without Compromising Quality",
      text: "Quality doesn’t have to come at a high price. We offer affordable web design solutions that don’t compromise on quality or performance. Our cost-effective packages are tailored to meet the needs of businesses of all sizes, providing you with a high-quality website that fits within your budget.",
    },
    {
      image: web3,
      heading: "Custom Web Application Development & Maintainace",
      text: "Beyond standard website design, we specialize in custom web application development to meet the unique needs of your business. Whether you need a custom CMS, a tailored e-commerce platform, or a specialized business application, we have the skills to develop solutions that drive efficiency and growth. We also offer website maintenance services to ensure your site remains up-to-date, secure, and running smoothly at all times.",
    },
    {
        image: res,
        heading: "Responsive, SEO-Friendly Website Redesign",
        text: "Is your current website outdated or underperforming? Our website redesign services will give your site a fresh, modern look while enhancing its functionality and user experience. With mobile users accounting for a significant portion of web traffic, having a responsive website design is essential. We create websites that look and perform beautifully on all devices, from desktops to smartphones. Additionally, our SEO-friendly website design ensures your site ranks well in search engines, driving more organic traffic to your business.",
      },
      {
        image: ecom1,
        heading: "E-commerce Solutions",
        text: "Unlock the potential of online sales with our tailored e-commerce solutions. We design and develop e-commerce websites that are secure, easy to manage, and optimized for conversions. Whether you're starting a new online store or looking to enhance your existing platform, we provide the tools and expertise you need to succeed.",
      },
    
    // more content objects
  ];

  const ctaContent = {
    text: "Take the Next Step Towards Digital Excellence!",
    para : "Elevate your online presence with our expert web design and development services. Partner with the leading web design agency in New York and take your business to the next level.",
    buttonLabel: "Request A Meeting",
  };

  const ctaIndex = 4;

  const textSections = [
    {
        index: 0,
        heading: "Best Website Designer for Your New York Business",
        text: "Welcome to the premier web design agency in New York, where creativity meets professionalism. As a leading web design company based in India, we specialize in crafting bespoke, responsive, and SEO-friendly websites that empower businesses to thrive in the competitive New York market. In today’s digital landscape, your website is often the first point of contact with potential customers. As the best website designer for businesses in New York, we understand the importance of making a lasting impression. Our design team creates visually stunning, user-friendly websites tailored to your specific needs, ensuring your brand stands out online.",
      },
    {
      index: 4,
      heading: "Why Choose Us?",
      text: "<ul><li><b>Tailored Solutions: </b>We create custom web solutions tailored to the specific needs of your New York business.</li><li><b>Experienced Team:</b> Our team consists of highly skilled designers and developers with extensive experience in the industry.</li><li><b>Affordability:</b> We provide top-quality web design and development services at competitive prices.</li><li><b>Comprehensive Services: </b> From initial design to ongoing maintenance, we offer a full suite of web services.</li><li><b>Client Satisfaction:</b> Your success is our priority. We work closely with you to ensure your website meets your business goals.</li></ul>",
    },
    
    // Add as many text sections as needed
  ];

  const linksData = [
    {
      text: "Best Website Designer in Tokyo",
      url: "/web-design-development-in-tokyo",
    },
    { text: "Top Web Development in San Francisco", url: "/website-design-in-san-francisco" },
    { text: "Affordable Web Design in London", url: "/web-design-development-in-london" },
    { text: "Website Maintainace Services in Boston", url: "/web-design-boston" },
    { text: "Web Redesign Services in San Joes", url: "/web-design-sanjoes" },
    // Add more links as needed
  ];

  const searchKeywordsData = [
    "Best Website Designer in NYC",
    "Web Development Company in UK",
    "Best Web Designing Services in New York","Professional Website Development New York","Creative Website Developer in New York","SEO-friendly Website Design in New York", "SEO-friendly Website Design Agency in New York","Website Maintainace in New York"
    // Add more keywords as needed
  ];

  return (

    <Layouts
      title={"Affordable Website Design & Developer Company in New York, NYC"}
      keywords={
        "Best Website Designer, Top Web Development Firm, Professional Website Development, Affordable Web Design, Custom Web Application Development, Website Redesign, Website Maintainace, Responsive, E-commerce, SEO-friendly Website Design"
      }
      description={
        "Design Responsive & SEO-friendly Websites with Bytefaze, Best Website development agency located in India offers affordable custom website designing, redesigning services in New York, NYC"
      }
      canonical={"https://www.bytefaze.com/website-design-development-in-newyork"}
      ldName={"Website Design & Development in New York"}
      serviceType={"Web Designing & Development Service in New York"}
    >

    

      <Demo
        contentArray={contentArray}
        textSections={textSections}
        ctaContent={ctaContent}
        ctaIndex={ctaIndex}
        heroSection={heroSection}
        myQuicklinks={<QuickLinks links={linksData} searchKeywords={searchKeywordsData} />}
      />
  </Layouts>


  );
};

export default WebDesignNewyork;
