import React from "react";
import "../../Styles/Pricing.css";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const PricingSection = () => {
  const plans = [
    {
      title: "Startup",
      price: "₹10,000/150 USD",
      features: [
        "Upto 5 Pages",
        "Logo",
        "Slider",
        "Dynamic Website",
        // "Domain-1st Year Free (.com or .in)",
        // "Hosting Free for 1st Year",
        // "1 Email – 500 MB Quota",
        "1 Contact Form",
        "Free SSL",
        "Testimonials",
        "Gallery Section",
        // "Chat Feature",
        "Content – 1 Rs per word",
        "Social Profile Link",
        "Click to Call/Email",
        "Mobile Responsive",
        "Login Details Will Be Given",
        "Delivery in 3 Days",
        "Renewal – 2000 Rs + Domain Price Plus GST",
        "Support – 500 Rs/Hr",
      ],
      button: "Get Started Now!",
    },
    {
      title: "Small Business",
      price: "₹20,000/250 USD",
      features: [
        "10 Pages",
        "Logo",
        "Slider",
        "Dynamic Website",
        // "Domain-1st Year Free (.com or .in)",
        // "Hosting Free for 1st Year",
        // "2 Email – 500 MB Quota Each",
        "1 Contact Form",
        "Free SSL",
        "Testimonials",
        "Gallery Section",
        // "Chat Feature",
        "300 Rs/Extra Page",
        "Content – 1 Rs per word",
        "Social Profile Link",
        "Click to Call/Email",
        "Mobile Responsive",
        "Login Details Will Be Given",
        "Delivery in 7 Days",
        "Renewal – 3000 Rs + Domain Price Plus GST",
        "Free 4 Hours Support",
        "Support – 500 Rs/Hr",
      ],
      button: "Get Started Now!",
    },
    {
      title: "E-Commerce",
      price: "₹35,000/420 USD",
      features: [
        "Up to 50 Products",
        "Logo",
        "Contact Form – 3",
        "Content – 1 Rs per word",
        "300 Rs/Extra Page",
        "WhatsApp Chat Integration",
        "Social Media Profiles Integration",
        "Social Profile Link",
        "Shopify Login Details Will Be Given",
        "Payment Gateway",
        "Extra Support – 500 Rs/Hour",
        "Revisions – 2",
        "Call/Email/Chat Support",
      ],
      button: "Get Started Now!",
    },
  ];

  return (
    <div className="container my-5 ">
      <h2 className="text-center mb-4 top-para mb-3">
        Website Development Packages in India
      </h2>
      <hr
                style={{
                  borderTop: "3px solid #ffc107",
                  width: "10%",
                  margin: "20px auto",
                  opacity: "1",
                }} className="mb-5"
              />
      <div className="row">
        {plans.map((plan, index) => (
          <div className="col-md-4 mb-4" key={index}>
            <div className="card h-100 top-para">
              <div className="card-header top-para">
                <h4>{plan.title}</h4>
                <p className="font-weight-bold">{plan.price}</p>
                <p className="small text-muted">EXCLUSIVE OF ALL TAXES</p>
              </div>
              <div className="card-body bg-danger">
                <ul className="list-unstyled ">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="feature-item">
                      <FaCheckCircle className="text-success mr-2" /> &nbsp;
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="card-footer">
                <Link className="button-33 font-weight-bold" to={"/contact-us"}>
                  {plan.button}
                </Link>
                <p className="small text-muted mt-2">T&C Apply</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingSection;
