import { React, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Layout from "../layout/Layouts";
import axios from "axios";
import Loader from "./Loader";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/firebase";

const Contact = () => {
  const currentDate = new Date();
  const istDateTime = currentDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    city: '',
    area: '',
    message: '',
    subject: '',
    timestamp: istDateTime,
  });
  const[msg,setMsg] = useState('')


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setMsg(<Loader />);
    e.preventDefault();
    try {
     

      await addDoc(collection(db, 'ContactForms'), formData);
      toast.success('Contact form submitted successfully', { position: 'top-center' });
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        city: '',
        area: '',
        message: '',
        subject: '',       
      });
      setMsg('');
    } catch (error) {
      console.error('Error submitting contact form: ', error);
      toast.error('Failed to submit contact form. Please try again.', { position: 'top-center' });
    }
  };

  // const handleSubmit = async (e) => {
  //   setMsg(<Loader />);
  //   e.preventDefault();
  //   console.log('FormData:', formData);

  //   try {
  //     const response = await axios.post(
  //       "https://bytefaze-backend.onrender.com/api/v1/form/user-form-submit",
  //       formData
  //     );
  //     console.log(response);
  //     if (response) {
  //       console.log(response);
  //       setMsg('');
  //       setMsg(response?.data?.message);
  //     }
  //   } catch (error) {
  //     console.log(error, 'error');
  //     if (error?.response?.data?.error === "submitForm validation failed: phoneNumber: Invalid phone number format") {
  //       setMsg("Please Enter a valid Phone Number");
  //     }
  //     if (error?.response?.data?.message) {
  //       setMsg(error?.response?.data?.message);
  //     } else {
  //       setMsg(error?.response?.data?.error);
  //     }
  //     setMsg(error?.response?.data?.message);
  //     setTimeout(() => {
  //       setMsg('');
  //     }, 4000);
  //   }
  // };



  return (
    <Layout
      title={
        "Top IT Services Company in Ahmedabad | Best Website Design - Bytefaze Web Solutions"
      }
      keywords={
        "Web Designing Company in Ahmedabad, SEO Services Provider in Ahmedabad, Best Web Designing Services, Hire Website Developer"
      }
      description={
        "Bytefaze is one of the top IT Services Company in Ahmedabad, offers responsive Website Design in Gujarat, Ahmedabad, Vatva, Naroda, Rakhiyal, Changodar, Sydney (Australia), Baroda, Ankhleshwar, Bharuch, Gandhinagar, Anand, Nadiad and Surat"
      }
      canonical={"https://www.bytefaze.com/contact-us"}
      ldName={"Website Design & Development"}
      serviceType={"Web Design & Development Services"}
      ldImage={
        "https://bytefaze.com/static/media/best-web-designer.76aaa934964c89846462.webp"
      }
    >
      <section className=" py-3 py-md-5 py-xl-8">
        <div className="container">
          <div className="row gy-3 gy-md-4 gy-lg-0 align-items-md-center">
            <div className="col-12 col-lg-6">
              <div className="row justify-content-xl-center">
                <div className="col-12 col-xl-11 cnt-head">
                  <h1
                    className="h1 mb-3"
                    style={{ color: "var(--link-color)" }}
                  >
                    Bytefaze Web Solutions- Web Design & Development Company
                  </h1>
                  <p className="lead fs-4  mb-5">
                    Looking for Web Designing, SEO, Responsive Designing, Web
                    Development & Mobile Application Developer in Ahmedabad,
                    Gujarat, India. Contact us today
                  </p>
                  <div className="d-flex mb-5">
                    <div className="me-4 text-primary">
                      <span className="icon">
                        {" "}
                        <i className="ri-map-pin-line"></i>
                      </span>
                    </div>
                    <div>
                      <h4 className="mb-3">Address</h4>
                      <p className="mb-0 ">
                        B-408, 4th floor, Sumel-8, Ajit mill cross road,
                        Rakhiyal, Ahmedabad, Gujarat — 380023
                      </p>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-12 col-sm-6">
                      <div className="d-flex mb-5 mb-sm-0">
                        <div className="me-4 text-primary">
                          <span className="icon">
                            {" "}
                            <i className="ri-phone-line"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="mb-3">Phone</h4>
                          <p className="mb-0">8469717272</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="d-flex mb-0">
                        <div className="me-4 text-primary">
                          <span className="icon">
                            {" "}
                            <i className="ri-mail-line"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="mb-3">E-Mail</h4>
                          <p className="mb-0">info@bytefaze.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="me-4 text-primary">
                      <span className="icon">
                        <i className="ri-alarm-line"></i>
                      </span>
                    </div>
                    <div>
                      <h4 className="mb-3">Opening Hours</h4>
                      <div className="d-flex mb-1">
                        <p className=" fw-bold mb-0 me-5">Mon - Sat</p>
                        <p className=" mb-0">&nbsp;10am - 7pm</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-5 mx-auto">
              <div className="border rounded shadow-sm overflow-hidden">
                <form method="post" id="form" name="form">
                  <div className="row p-4 gy-3 ">
                    <div className="col-12 col-md-6">
                      <label htmlFor="fullname" className="form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="ri-id-card-line"></i>
                        </span>
                        <input
                          type="text"
                          placeholder="Enter First Name"
                          className="form-control"
                          id="firstName"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="fullname" className="form-label">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="ri-id-card-line"></i>
                        </span>
                        <input
                          type="text"
                          placeholder="Enter Last Name"
                          className="form-control"
                          id="lastName"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="email" className="form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="ri-mail-check-line"></i>
                        </span>
                        <input
                          type="email"
                          placeholder="Enter Email"
                          id="email"
                          className="form-control"
                          onChange={handleChange}
                          value={formData.email}
                          name="email"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="phone" className="form-label">
                        Phone Number
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="ri-phone-line"></i>
                        </span>
                        <input
                          type="tel"
                          placeholder="Enter Phone Number"
                          className="form-control"
                          id="phoneNumber"
                          name="phoneNumber"
                          onChange={handleChange}
                          value={formData.phoneNumber}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="location" className="form-label">
                        City <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="ri-user-location-line"></i>
                        </span>
                        <input
                          type="text"
                          placeholder="Enter City"
                          className="form-control"
                          id="city"
                          name="city"
                          onChange={handleChange}
                          value={formData.city}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="location" className="form-label">
                        Area <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="ri-user-location-line"></i>
                        </span>
                        <input
                          type="text"
                          placeholder="Enter Area"
                          className="form-control"
                          id="area"
                          name="area"
                          onChange={handleChange}
                          value={formData.area}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="subject" className="form-label">
                        Subject <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Subject"
                        className="form-control"
                        id="subject"
                        name="subject"
                        onChange={handleChange}
                        value={formData.subject}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <label htmlFor="message" className="form-label">
                        Message <span className="text-danger">*</span>
                      </label>
                      <textarea
                        placeholder="message"
                        onChange={handleChange}
                        value={formData.message}
                        className="form-control"
                        id="message"
                        name="message"
                        rows="3"
                        required
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <div className="d-grid">
                        <button
                          disabled={msg ? true : false}
                          onClick={handleSubmit}
                          className={`border btn-lg ${msg ? "btn2" : "btn"} `}
                          type="submit"
                        >
                          Send Message
                        </button>
                      </div>
                      <div
                        className="text-center mt-3  "
                        style={{ color: "var(--link-color)" }}
                      >
                        {msg}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </Layout>
  );
};

export default Contact;