import React, { useEffect } from 'react'
// import { useAuthContext } from '../context/AuthContext'
import AdminDashboard from './admin/AdminDashboard'
import UserDashboard from './user/UserDashboard'
// import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
    // const {admin , checkAuth} = useAuthContext()

    // useEffect(() => {
    //     // checkAuth();
    //   }, [checkAuth]);
    
    

  return (
    <div>
      <AdminDashboard/>
        {/* {admin?
        <AdminDashboard/> : <UserDashboard/> 
        } */}
    </div>
  )
}

export default Dashboard