import React,{ useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// import emailjs from "@emailjs/browser";
import "react-toastify/dist/ReactToastify.css";


//import "../../Styles/styles.css";

// import google from "../../assets/WebpImages/Google.webp";
// import clutch from "../../assets/WebpImages/clutch.webp";
// import designrush from "../../assets/WebpImages/designrush.webp";
// import topdevelopers from "../../assets/WebpImages/topdevelopers.webp";
// import goodfrims from "../../assets/WebpImages/goodfirms.webp";
import axios from "axios";
import Loader from "../pages/Loader";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { toast } from "react-toastify";
import { ToastContainer } from "react-bootstrap";
//import { LazyLoadImage } from "react-lazy-load-image-component";
const Footer = () => {


  
  const currentDate = new Date();
  const istDateTime = currentDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    city: '',
    area: '',
    message: '',
    subject: '',
    timestamp: istDateTime,
  });
  const[msg,setMsg] = useState('')

  const [contactus, setContactus] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/contact-us") {
      setContactus(true);
    }
  }, [location]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setMsg(<Loader />);
    e.preventDefault();
    try {
     

      await addDoc(collection(db, 'ContactForms'), formData);
      toast.success('Contact form submitted successfully', { position: 'top-center' });
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        city: '',
        area: '',
        message: '',
        subject: '',       
      });
      setMsg('');
    } catch (error) {
      console.error('Error submitting contact form: ', error);
      toast.error('Failed to submit contact form. Please try again.', { position: 'top-center' });
    }
  };

//  const handleSubmit = async (e) => {
//     setMsg(<Loader />);

//     e.preventDefault();
//     try {
//       const response = await axios.post(
//         "https://bytefaze-backend.onrender.com/api/v1/form/user-form-submit",
//         formData
//       );
//       // console.log(response);
//       if (response) {
//         setMsg("");
      
//       }

//       // Set timeout for 3 seconds (adjust as needed)
//     } catch (error) {
//       // console.log(error, "error");
//       if (error?.response?.data?.message) {
       
//       } else {
      
//       }
//       setMsg(error?.response?.data?.message);
//       setTimeout(() => {
//         setMsg("");
//       }, 4000);
//       // Set timeout for 3 seconds (adjust as needed)
//     }
//   };
  return (
    <>
      <hr className="top-para mt-5" />
      {!contactus ? (
        <>
          <section className="container">
            <div className="row ">
              <div className="col-lg-5 mt-3">
                <div className="footer_contact_form overflow-hidden p-4">
                  <h4 className="text-center">Collaborate with Bytefaze</h4>
                  <form
                    method="post"
                    id="form1"
                    name="form"
                  >
                    <div className="row gy-3 ">
                      <div className="col-12 col-md-6">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="ri-id-card-line"></i>
                          </span>
                          <input
                            type="text"
                            placeholder="Enter First Name"
                            className="form-control"
                            id="firstName1"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="ri-id-card-line"></i>
                          </span>
                          <input
                            type="text"
                            placeholder="Enter Last Name"
                            className="form-control"
                            id="lastName1"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="ri-mail-check-line"></i>
                          </span>
                          <input
                            type="email"
                            placeholder="Enter Email"
                            id="email1"
                            className="form-control"
                            onChange={handleChange}
                            value={formData.email}
                            name="email"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6  ">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="ri-phone-line"></i>
                          </span>
                          <input
                            type="tel"
                            placeholder="Phone Number"
                            className="form-control"
                            id="phoneNumber1"
                            name="phoneNumber"
                            onChange={handleChange}
                            value={formData.phoneNumber}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 py-2">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="ri-user-location-line"></i>
                      </span>
                      <input
                        type="text"
                        placeholder="Enter City"
                        className="form-control"
                        id="city1"
                        name="city"
                        onChange={handleChange}
                        value={formData.city}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 py-2">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="ri-user-location-line"></i>
                      </span>
                      <input
                        type="text"
                        placeholder="Enter Area"
                        className="form-control"
                        id="area1"
                        name="area"
                        onChange={handleChange}
                        value={formData.area}
                        required
                      />
                    </div>
                  </div>

                      <div className="col-12 ">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="ri-chat-thread-line"></i>
                          </span>

                          <input
                            type="text"
                            placeholder="Subject"
                            className="form-control"
                            id="subject1"
                            name="subject"
                            onChange={handleChange}
                            value={formData.subject}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <textarea
                          placeholder="About your project"
                          onChange={handleChange}
                          value={formData.message}
                          className="form-control"
                          id="message1"
                          name="message"
                          rows="2"
                          required
                        ></textarea>
                      </div>
                      <div className="col-12  ">
                        <div className="d-grid col-lg-4">
                          <button 
                          disabled={msg ? true : false}
                          onClick={handleSubmit}
                          className={`border ${msg ? "btn2" : "btn"} `}
                           type="submit">
                            INQUIRE NOW
                          </button>
                        </div>
                        <div
                      className="text-center mt-3  "
                      style={{ color: "var(--link-color)" }}
                    >
                      {msg}
                    </div>
                      </div>
                    </div>
                
                  </form>
                </div>
              </div>
              <div className="col-lg-6 mx-auto">
  <div className="row">
    <div className="col-12 col-md-6 col-lg-6 my-2 mt-3">
      <h4 className="h5 top-headings">Our Office:</h4>
      {/* <p className="top-para">
        <img src={India} alt="India's National Flag" />
        &nbsp;&nbsp;India
      </p> */}
  <p className="h6 pt-2">
  <span className="d-block mb-2">B-408, 4th floor, Sumel-8,</span>
  <span className="d-block mb-2">Ajit mill cross road,</span>
  <span className="d-block mb-2">Rakhiyal Ahmedabad,</span>
  <span className="d-block">Gujarat — 380023</span>
</p>

      <h5 className="h6 top-headings pt-3">Skype:</h5>
      <p className="h5">
        <Link to="https://join.skype.com/invite/rD0feVkZHxlJ" target="_blank" className="footer_list">
          <i className="ri-skype-fill h4"></i> biz.bytefaze
        </Link>
      </p>

      <h5 className="h6 top-headings pt-3">Get In Touch</h5>
      <p className="h5 pt-1">
        <Link to="mailto:info@bytefaze.com" target="_blank" className="footer_list">
        <span style={{fontSize:"20px"}}><i class="ri-mail-fill"></i></span>   info@bytefaze.com
        </Link>
      </p>

      <p className="h5 pt-1">
        <Link to="tel:+91 8469717272" target="_blank" className="footer_list">
        <span style={{fontSize:"20px"}}><i class="ri-phone-fill"></i></span>  +91-846-971-7272
        </Link>
      </p>

   <div className="pt-2">

      <Link className="top-para" to="https://www.linkedin.com/company/bytefaze/" target="_blank">
        <i className="ri-linkedin-box-fill h4"></i>
      </Link>
      <Link className="top-para" to="https://www.instagram.com/bytefaze/" target="_blank">
        <i className="ri-instagram-fill h4 px-2"></i>
      </Link>
      <Link className="top-para" to="https://x.com/Bytefaze" target="_blank">
        <i className="ri-twitter-x-fill h4 px-2"></i>
      </Link>
      <Link className="top-para" to="https://www.facebook.com/bytefazewebsolutionsahmedabad/" target="_blank">
        <i className="ri-facebook-box-fill h4 px-2"></i>
      </Link>
      <Link className="top-para" to="https://wa.me/+918469717272" target="_blank">
        <i className="ri-whatsapp-fill h4 px-2"></i>
      </Link>
      <Link className="top-para" to="https://join.skype.com/invite/rD0feVkZHxlJ" target="_blank">
        <i className="ri-skype-fill h4 px-2"></i>
      </Link>
   </div>
    </div>

    <div className="col-12 col-md-6 col-lg-6 mt-3" >
      <h5 className="top-para fw-bold">Services</h5>
      <ul className="list-unstyled pt-1">
  <li className="my-2">
    <Link className="footer_list" to="/website-designing">
      Website Designing
    </Link>
  </li>
  <li className="my-2">
    <Link className="footer_list" to="/responsive-website">
      Responsive Website
    </Link>
  </li>
  <li className="my-2">
    <Link className="footer_list" to="/application-development">
      Application Development
    </Link>
  </li>
  <li className="my-2">
    <Link className="footer_list" to="/ecommerce-website">
      E-Commerce Website
    </Link>
  </li>
  <li className="my-2">
    <Link className="footer_list" to="/domain-registration">
      Domain Registration
    </Link>
  </li>
  <li className="my-2">
    <Link className="footer_list" to="/corporate-presentation">
      Corporate Presentation
    </Link>
  </li>
  <li className="my-2">
    <Link className="footer_list" to="/web-hosting">
      Web Hosting
    </Link>
  </li>
  <li className="my-2">
    <Link className="footer_list" to="/digital-marketing">
      Digital Marketing
    </Link>
  </li>
  <li className="my-2">
    <Link className="footer_list" to="/logo-designing">
      Logo Designing
    </Link>
  </li>
  <li className="my-2">
    <Link className="footer_list" to="/seo">
      SEO
    </Link>
  </li>
</ul>

    </div>
  </div>
</div>

            </div>
          </section>
        </>
      ) : (
        ""
      )}

      {/* <hr className="top-para"/> */}


   
      <section className="container mt-3 ">
        {/* <div >
          <div className="d-flex align-items-center justify-content-between px-2 scrollable-items gap-5 px-5 ">
            <div className="ratings">
              <Link to='https://clutch.co/profile/bytefaze#highlights' target="_blank">
                <img  className="footer_img" src={clutch} width={70} alt="Clutch" />
                &nbsp;&nbsp;&nbsp;&nbsp;<i className="ri-star-fill"></i>
              </Link>
              &nbsp;&nbsp;5.0
            </div>
            <div className="line-box"></div>
            <div className="ratings">
              <Link to="/" target="_blank">
                <img 
                  className="footer_img"
                  src={goodfrims}
                  width={100}
                  alt="Goodfrims"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;<i className="ri-star-fill"></i>
              </Link>
              &nbsp;&nbsp;5.0
            </div>
            <div className="line-box"></div>
            <div className="ratings">
              <Link to="/" target="_blank">
                <img 
                  className="footer_img"
                  src={designrush}
                  width={80}
                  alt="Designrush"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;<i className="ri-star-half-line"></i>
              </Link>
              &nbsp;&nbsp;4.5
            </div>
            <div className="line-box"></div>
            <div className="ratings">
              <Link to='https://www.topdevelopers.co/profile/bytefaze-web-solutions' target="_blank">
                <img 
                  className="footer_img"
                  src={topdevelopers}
                  width={100}
                  alt="Top Developers"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;<i className="ri-star-half-line"></i>
              </Link>
              &nbsp;&nbsp;4.5
            </div>
            <div className="line-box"></div>
            <div className="ratings">
              <Link to='https://maps.app.goo.gl/htfvmBqbdJubqQ69A' target="_blank">
                <img  className="footer_img" src={google} width={80} alt="Google" />
                &nbsp;&nbsp;&nbsp;&nbsp;<i className="ri-star-half-line"></i>
              </Link>
              &nbsp;&nbsp;4.9
            </div>
          </div>
        </div> */}
        {/* <div className="">
       
          <div className="col-lg-3 col-12 col-md-6">
            <h5 className="top-para">Our Developers</h5>
            <ul className="list-unstyled">
              <li>
                <Link className="footer_list" to="/hire-flutter-app-developers">
                  Flutter Developer
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-ios-app-developers">
                  IOS Developers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-android-app-developers">
                  Android Developers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-swift-developers">
                  Swift Developers
                </Link>
              </li>
              <li>
                <Link
                  className="footer_list"
                  to="/hire-react-native-developers"
                >
                  React Native Developers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-ionic-developers">
                  Ionic Developers
                </Link>
              </li>

              <li>
                <Link className="footer_list" to="/hire-react-developers">
                  React.js Developers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-angular-developers">
                  Angular Developers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-vue-developers">
                  Vue JS Developers
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-12 col-md-6">
            <h3 className="top-para">&nbsp;</h3>
            <ul className="list-unstyled">
              <li>
                <Link className="footer_list" to="/hire-nextjs-developers">
                  Next.js Developers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-golang-developers">
                  Golang Developer
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-nodejs-developers">
                  Node.js Developer
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-php-developers">
                  Php Developer
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-python-developers">
                  Python Developer
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-full-stack-developers">
                  Full Stack Developers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-qa-engineers">
                  QA Engineers
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-12 col-md-6">
            <h3>&nbsp;</h3>
            <ul className="list-unstyled">
              <li>
                <Link className="footer_list" to="/hire-devops-engineers">
                  DevOps Engineers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-game-developers">
                  Game Developers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-data-scientist">
                  Data Scientist
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-ai-developers">
                  Ai Developer
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-ml-developers">
                  Ml Developer
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-ui-ux-developers">
                  UI/UX Designers
                </Link>
              </li>
            </ul>
          </div>
        </div> */}
      </section>
      <hr className="top-para" />

      <section className="container">
        <div className="d-flex justify-content-between flex-lg-row flex-column-reverse ">
          <div>
            <div className="top-para text-center ">
              © 2023 Bytefaze. All rights reserved.
            </div>
          </div>
          <div>
            <ul className="list-unstyled d-flex gap-4 justify-content-center">
              <li>
                <Link className="footer_list" to={"/"}>
                  Home
                </Link>
              </li>
              <li>
                <Link className="footer_list" to={"/who-we-are"}>
                  About Us
                </Link>
              </li>
              <li>
                <Link className="footer_list" to={"/what-we-do"}>
                  Services
                </Link>
              </li>
              <li>
                <Link className="footer_list" to={"/blog"}>
                  Our Blogs
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* <ToastContainer/> */}
      </section>
    </>
  );
};

export default React.memo(Footer);