import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const NewSideNav = () => {
  return (
   <>
     <div className="p-4 shadow-sm " style={{ background: "var(--footer-bg)" }}>
              {/* Side Navigation Links */}
              <ul className="list-group mb-4 top-para
              " style={{listStyle:"none"}} >
                <li >
                <FaCheckCircle className="text-danger mr-2" />  <Link className='top-para' >Website Design Services</Link>
                </li>
                <li >
                  <FaCheckCircle className="text-danger mr-2" /> <Link className='top-para'   >SEO Services</Link>
                </li>
                <li >
                 <FaCheckCircle className="text-danger mr-2" />  <Link className='top-para'  >Content Writing</Link>
                </li>
                <li >
                  <FaCheckCircle className="text-danger mr-2" /> <Link className='top-para'  >Web Hosting</Link>
                </li>
                <li >
                 <FaCheckCircle className="text-danger mr-2" />  <Link className='top-para'   >E-commerce Solutions</Link>
                </li>
                <li >
                 <FaCheckCircle className="text-danger mr-2" />  <Link className='top-para'   >Custom Development</Link>
                </li>
              </ul>

              {/* Inquiry Now Button */}
              <div className="text-center mt-4">
                <button className="button-33 w-100">
                  Inquiry Now
                </button>
              </div>
            </div>
   </>
  )
}

export default NewSideNav