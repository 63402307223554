import React from "react";

// import accordiamimg from "../../assets/Images/accordianimg.png";
// import "../../Styles/Hero.css";

const Accordian = () => {
  return (
    <>
    
      <section className="py-3 py-md-5">
        <div className="container">
          <div className="row gy-5 gy-lg-0 align-items-lg-center">
            <div className="col-12 col-lg-12" >
              <div className="row justify-content-xl-center" >
                <div className="col-12 col-xl-11" >
                  <h2
                    className="  text-capitalize text-center"
                    style={{ color: "var(--link-color)" }}
                  >
                  " Web Designing Ahmedabad FAQ's "
                  </h2>
                  <p
                    className="lead fs-4 mb-5 "
                    style={{ color: "var(--link-color)" }}
                  >
                 
                  </p>
                  <div
                    className="accordion accordion-flush"
                    id="accordionExample"
                    
                  >
                    <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"

                      >
                        What services do you offer?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="top-para mb-2">
                        We offer a wide range of services including <b>custom Website development & designing, web and mobile app development, UI/UX design website</b>, quality assurance, and maintenance & support.
                        </div>
                       
                      </div>
                    </div>
                  </div>

              

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          What is website design?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          Website design is the process by which the look and feel of a website are created. This includes the layout, colors, typography, and imagery of the website.

                        </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                         What website design technologies do you specialize in?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          <div className="
                          top-para mb-2">
                          We specialize in a variety of technologies including but not limited to <b>Java, Python, JavaScript, React JS, Angular JS, Node JS, Swift, and Kotlin.</b> Our team stays up-to-date with the latest trends and technologies to deliver cutting-edge solutions.
                          </div>
                         
                        </div>
                      </div>
                    </div>


                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          What is the difference between custom website design and responsive website design?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          Custom website design is a process by which a website is created that is tailored to the specific needs of a business. Responsive website design is a process by which a website is created that can be viewed on all devices, including desktops, tablets, and smartphones.
                          </p>
                        
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFourr"
                          aria-expanded="false"
                          aria-controls="collapseFourr"
                        >
                          Can Bytefaze create websites designed for online sales?
                        </button>
                      </h2>
                      <div
                        id="collapseFourr"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          Yes, Bytefaze Web Solution offers e-commerce website design services, which involve creating websites that can be used to sell products or services online.
                          </p>
                        
                        </div>
                      </div>
                    </div>


                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          How much does website design cost?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          The cost of website design can vary depending on the complexity of a project. Bytefaze Web Solution offers a free consultation to discuss your website design needs and provide you with a quote.

                          </p>
                        
                        </div>
                      </div>
                    </div>
     

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                         Do you provide post-launch support and maintenance?
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          Absolutely! Our commitment to client satisfaction extends beyond the launch of the software. We offer comprehensive post-launch support and maintenance services to address any issues, implement updates, and ensure optimal performance over time.
                          </p>
                        
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSeven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                         What services does you provide as best website designing company in Ahmedabad?
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body ">
                          <div  className="top-para mb-2"> 
                          As a <b>best website designing company in ahmedabad</b>,we offers a range of services related to creating and designing websites. This includes website design and development, responsive design, e-commerce solutions, website maintenance, and website redesign.
                          </div>
                        
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                         How long does it take to design and develop a website?
                        </button>
                      </h2>
                      <div
                        id="collapseEight"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingEight"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          The time required to design and develop a website depends on various factors such as the complexity of the project, the number of pages, and the client's requirements. Typically, it can take anywhere from a few weeks to a few months to complete a website.
                          </p>
                        
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingNine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseNine"
                          aria-expanded="false"
                          aria-controls="collapseNine"
                        >
                         Can I have input in the design process?
                        </button>
                      </h2>
                      <div
                        id="collapseNine"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingNine"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          Absolutely! A good website designing company will involve you in the design process and seek your input and feedback. They will work closely with you to understand your vision, brand identity, and target audience to create a website that aligns with your goals.
                          </p>
                        
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTen"
                          aria-expanded="false"
                          aria-controls="collapseTen"
                        >
                         Will my website be mobile-friendly?
                        </button>
                      </h2>
                      <div
                        id="collapseTen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTen"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          Yes, a professional website designing company will ensure that your website is mobile-friendly and responsive. With the increasing use of mobile devices, it is crucial to have a website that provides a seamless user experience across different screen sizes.
                          </p>
                        
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingEleven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEleven"
                          aria-expanded="false"
                          aria-controls="collapseEleven"
                        >
                         What is a web development internship?
                        </button>
                      </h2>
                      <div
                        id="collapseEleven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingEleven"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          A web development internship is a training program that provides individuals with hands-on experience and practical knowledge in web development. It is designed to enhance their skills and prepare them for a career in the field of web development.
                          </p>
                        
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwelve">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwelve"
                          aria-expanded="false"
                          aria-controls="collapseTwelve"
                        >
                         What programming languages are covered in a web development internship?
                        </button>
                      </h2>
                      <div
                        id="collapseTwelve"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwelve"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          The programming languages covered in a web development internship can vary depending on the company and the program. Commonly covered languages include HTML, CSS, JavaScript, PHP, and Python. Some programs may also cover frameworks and libraries such as React, Angular, and Node.js.
                          </p>
                        
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThirteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThirteen"
                          aria-expanded="false"
                          aria-controls="collapseThirteen"
                        >
                         Is prior coding experience required for a web development internship?
                        </button>
                      </h2>
                      <div
                        id="collapseThirteen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThirteen"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          While prior coding experience can be beneficial, it is not always a requirement for a web development internship. Many internship programs are designed to accommodate individuals with varying levels of experience, including beginners. The focus is on providing comprehensive training and guidance to help individuals develop their skills.
                          </p>
                        
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFourteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFourteen"
                          aria-expanded="false"
                          aria-controls="collapseFourteen"
                        >
                         What is SEO?
                        </button>
                      </h2>
                      <div
                        id="collapseFourteen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFourteen"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          SEO stands for Search Engine Optimization. It is the practice of optimizing a website to improve its visibility and ranking on search engine results pages. The goal of SEO is to attract organic (non-paid) traffic to a website and increase its online presence.
                          </p>
                        
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFifteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFifteen"
                          aria-expanded="false"
                          aria-controls="collapseFifteen"
                        >
                         What services does an SEO services provider offer?
                        </button>
                      </h2>
                      <div
                        id="collapseFifteen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFifteen"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          An SEO services provider offers a range of services to improve a website's search engine rankings. This includes keyword research, on-page optimization, off-page optimization, content creation, link building, technical SEO, and performance tracking and analysis.
                          </p>
                        
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSixteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSixteen"
                          aria-expanded="false"
                          aria-controls="collapseSixteen"
                        >
                         How long does it take to see results from SEO?
                        </button>
                      </h2>
                      <div
                        id="collapseSixteen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSixteen"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          The time it takes to see results from SEO can vary depending on various factors such as the competitiveness of the industry, the current state of the website, and the effectiveness of the SEO strategies implemented. Generally, it can take several months to start seeing significant improvements in search engine rankings and organic traffic.
                          </p>
                        
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingSeventeen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeventeen"
                          aria-expanded="false"
                          aria-controls="collapseSeventeen"
                        >
                         Can SEO guarantee top rankings on search engines?
                        </button>
                      </h2>
                      <div
                        id="collapseSeventeen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSeventeen"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                          No, SEO cannot guarantee top rankings on search engines. Search engine algorithms are complex and constantly evolving, making it impossible to guarantee specific rankings. However, a professional SEO services provider will employ proven strategies and best practices to improve a website's visibility and increase its chances of ranking higher on search engine results pages.
                          </p>
                        
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(Accordian);
