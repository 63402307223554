import React from "react";

import Demo from "./Demo";
import QuickLinks from "./QuickLinks";
import Layouts from "../layout/Layouts";
import webdesign from "../../assets/WebpImages/webdesign.webp";
import res2 from "../../assets/WebpImages/res2.webp";
import web3 from "../../assets/WebpImages/web32.webp";
import ecom1 from "../../assets/WebpImages/website-maintenance.webp"
import res from "../../assets/WebpImages/responsive-website.webp";

const WebDesignSanJoes = () => {
  const heroSection = {
    backgroundImage: "https://via.placeholder.com/1600x400",
    heading: "Web Design in San Joes ",
    paragraph:
      "Transform Your Digital Presence with Bytefaze's Premier Web Design & development services in San Joes",
  };
  const contentArray = [
    {
      image: webdesign,
      heading: "Custom Web Design",
      text: "At Bytefaze, we understand that every business has its own identity and requirements. Our custom web design services ensure that your website reflects your brand's unique personality and vision. From innovative layouts to engaging visuals, we create websites that make a lasting impression and help you stand out from the crowd.",
    },
    {
      image: res2,
      heading: "Affordable Website Designing",
      text: "Quality website design doesn't have to break the bank. We offer affordable website designing solutions that provide excellent value for money without compromising on quality. Our cost-effective packages are designed to fit your budget while delivering a professional and functional website that meets your business objectives.",
    },
    {
      image: web3,
      heading: "Responsive Website Design",
      text: "With the majority of internet users accessing websites from mobile devices, having a responsive website is essential. Our responsive web design services ensure that your website looks and functions flawlessly across all devices and screen sizes. We focus on creating seamless user experiences that keep your visitors engaged, no matter how they access your site.",
    },
    {
        image: res,
        heading: "Custom Web Application Development",
        text: "We understand that every business is unique, which is why we offer custom web application development services tailored to your specific requirements. Whether you need a custom CMS, a unique e-commerce solution, or a specialized web application, our team can develop solutions that enhance your business operations and provide a seamless user experience.",
      },
      {
        image: ecom1,
        heading: "Ecommerce Website Design",
        text: "Transform your online retail business with our expert ecommerce website design services. We build user-friendly and secure ecommerce platforms that drive sales and enhance customer satisfaction. From intuitive navigation to streamlined checkout processes, our designs are optimized to provide a superior shopping experience for your customers.",
      },
    
    // more content objects
  ];

  const ctaContent = {
    text: "Ready to elevate your online presence with a stunning website?",
    para : "Let Bytefaze Web Solutions help you create a website that not only looks great but also delivers results. Contact us today to discuss your project and get a free quote. Reach out now and take the first step towards digital success!",
    buttonLabel: "Request A Meeting",
  };

  const ctaIndex = 4;

  const textSections = [
    {
        index: 0,
        heading: "Custom Website Design & Development Services",
        text: "Welcome to Bytefaze Web Solutions, your premier destination for exceptional website designing services in San Jose. In today’s digital landscape, having a professionally designed website is crucial for standing out in the competitive market. Our team of skilled designers and developers is dedicated to crafting bespoke websites that not only captivate your audience but also drive results. Whether you're a startup or an established business, we provide tailored solutions to meet your unique needs and goals.",
      },
    {
      index: 4,
      heading: "Why Choose Us?",
      text: "Choosing Bytefaze Web Solutions means partnering with a team that prioritizes your success. Here’s why you should consider us for your website designing needs: <ul><li><b>Expertise and Experience:</b> Our team has extensive experience in designing and developing high-quality websites that meet industry standards and best practices.</li><li><b>Client-Centric Approach:</b> We take the time to understand your business and goals, ensuring that our solutions are tailored to your specific needs.</li><li><b>Timely Delivery:  </b> We are committed to delivering your project on time without compromising on quality.</li><li><b>ising on quality. </b> Our relationship doesn’t end with the launch of your website. We offer continuous support and maintenance to ensure your site remains up-to-date and performs optimally.</li></ul>",
    },
    
    // Add as many text sections as needed
  ];

  const linksData = [
    {
      text: "Ecommerce Website Development",
      url: "/ecommerce-website",
    },
    { text: "Mobile Application Development", url: "/application-development" },
    { text: "Latest Trends of Website Designing", url: "/blog/riding-the-wave-unveiling-the-latest-trends-in-web-design" },
    { text: "Responsive Website Design ", url: "/responsive-website" },
    
    // Add more links as needed
  ];

  const searchKeywordsData = [
    "Best Website Designer in San Joes",
    "Website Design San Joes",
    "Best Web Design Agency in San Joes","Professional Website Development San Joes","Mobile-Friendly Web Developer in San Joes","Website Redesign Services in San Joes", "Custom Web Application Design Agency in San Joes","Website Maintainace in San Joes"
    // Add more keywords as needed
  ];

  return (

    <Layouts
      title={"Top Website Design Services in San Jose | Best Web Designer"}
      keywords={
        "Best Website Designer in San Joes, Website Design San Joes, Best Web Design Agency, professional website development, mobile-friendly, website redesign, website maintenance services, custom web application"
      }
      description={
        "Bytefaze is expert website design, development service provider company from India, specializing in offering affordable, responsive and custom web design a solutions in san Jose Area"
      }
      canonical={"https://www.bytefaze.com/web-design-sanjoes"}
      ldName={"Website Design & Development in San Joes"}
      serviceType={"Web Designing & Development Service in San Joes"}
    >

    

      <Demo
        contentArray={contentArray}
        textSections={textSections}
        ctaContent={ctaContent}
        ctaIndex={ctaIndex}
        heroSection={heroSection}
        myQuicklinks={<QuickLinks links={linksData} searchKeywords={searchKeywordsData} />}
      />
  </Layouts>


  );
};

export default WebDesignSanJoes;
