import React, { useEffect, useState } from "react";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Navbar from "../common/Navbar.jsx";
import Footer from "../common/Footer.jsx";
import Socialbtns from "../common/Socialbtns.jsx";
import { Helmet } from 'react-helmet'
import logoImg from '../../assets/WebpImages/bytedark.webp'
import ParticlesContainer from "../pages/ParticlesContainer.jsx";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

const Layout = ({ children, title, description, keywords, canonical, ldName, ldImage,url, jsonLdDynamic, serviceType}) => {
  // console.log(title)
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": ldName,
    "url": url ?url:canonical, 
    "description": description,
      "mainEntity": {
      "@type": "Service",
      "name": ldName,
      "serviceType": serviceType,
      "description": description,
      "provider": {
        "@type": "Organization",
        "name": "Bytefaze Web Solutions",
        "url": "https://www.bytefaze.com",
        "logo": "https://www.bytefaze.com/static/media/bytedark.2c56fa28e00899c28bcc.webp",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91-8469717272",
          "contactType": "Customer Service"
        }
      },
      "offers": {
        "@type": "Offer",
        "priceCurrency": "INR",
        "price": "Contact for pricing",
        "url": "https://www.bytefaze.com/contact"
      }
    }
  }
  const AppjsonLd = {
     "@context":"http://www.schema.org",
  "@type":"ProfessionalService",
  "name": ldName,
  "url": url ?url:canonical,
  "logo": "https://www.bytefaze.com/static/media/bytedark.2c56fa28e00899c28bcc.webp",
  "image":ldImage,
  "description": description,
  "priceRange":"INR",
  "aggregateRating":{
  "@type":"AggregateRating",
  "ratingValue":"4.9/5",
  "reviewCount":"144"
  },
  "address":{
  "@type":"PostalAddress",
  "streetAddress":"B-408, 4th floor, Sumel-8, Ajit mill cross road, Rakhiyal, Ahmedabad-380023, Gujarat, India",
  "addressLocality":"Ahmedabad",
  "addressRegion":"Gujarat",
  "postalCode":"380023",
  "addressCountry":"India"},
  "telephone":"+91-846-971-7272",
  "geo":{
  "@type":"GeoCoordinates",
  "latitude":"23.0192141",
  "longitude":"72.63157489999999"
  },
  "openingHours":"Mo, Tu, We, Th, Fr, Sa 10.00:00-19:00",
  "contactPoint":{
  "@type":"ContactPoint",
  "telephone":"+91-846-971-7272",
  "contactType":"customer support",
  "areaServed":["IN"],
  "availableLanguage":["Hindi","Gujarati","English"]
  },
  "sameAs": [
      "https://www.facebook.com/bytefazewebsolutionsahmedabad/",
      "https://x.com/Bytefaze",
      "https://www.instagram.com/bytefaze/",
      "https://www.linkedin.com/company/bytefaze/",
      "https://www.bytefaze.com/"
    ]
  
  }
  const current_theme = sessionStorage.getItem("current_theme");
  const [theme, setTheme] = useState(current_theme ? current_theme : "light");
  const toggleTheme = () => {
    theme === "" ? setTheme("light-theme") : setTheme("");
  };


  // const ParticlesContainer = () => {

  //   return theme === 'light-theme' ? <ParticlesContainer /> : null;
  // };

  useEffect(() => {
    sessionStorage.setItem("current_theme", theme);
    document.body.className = theme;
  }, [theme]);
  //helmet

  return (
    <>
  
      {/* <Navbar theme={theme} toggleTheme={toggleTheme} /> */}
      {/* <ScrollToTop /> */}
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} data-rh="true" />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={canonical} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={logoImg} />
        <meta property="og:site_name" content="Bytefaze - Web Design Company" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={url ?url:canonical} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
     
        {/* <meta http-equiv="Content-Security-Policy" content="script-src 'self' https://www.youtube.com;" /> */}

        <script type="application/ld+json">
         {jsonLdDynamic ? JSON.stringify(jsonLdDynamic) : JSON.stringify(jsonLd)}
        </script>

        <script type="application/ld+json">
          {JSON.stringify(AppjsonLd)}
        </script>


      </Helmet>
      {/* {theme === "light-theme" ? <ParticlesContainer /> : ""} */}

      {children}
      {/* <Socialbtns /> */}
      {/* <Footer /> */}
    </>
  );
};

export default React.memo(Layout)
