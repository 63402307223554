import React, { useState } from "react";
//import { LazyLoadImage } from "react-lazy-load-image-component";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
// import "../../Styles/Hero.css";
const ProjectCounter = () => {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <section className="counter gradient-bg" >
        <div className="container">
          <div className="row text-center pt-4">
            <h2>Numbers that Make Us the Most Preferred IT Company in India</h2>
          <hr
                  style={{
                    borderTop: "3px solid #ffc107",
                    width: "10%",
                    margin: "10px auto",
                    opacity: "1",
                  }}
                />
          </div>
          <div className="row justify-content-center pt-3 pb-4 ">
            <div className="col-lg-3 col-6  counter_item">
              <p className="counter_number fs-2 fs-md-4 fs-sm-5 fw-bold">
                {counterOn && (
                  <CountUp start={0} end={100} duration={2} delay={0} />
                )}
                +
              </p>
              <p className="counter_title fs-5 fw-bold">Happy Clients</p>
            </div>
            <div className="col-lg-3 col-6  counter_item">
              <p className="counter_number fs-2 fs-md-4 fs-sm-5 fw-bold">
                {counterOn && (
                  <CountUp start={0} end={30} duration={2} delay={0} />
                )}
                +
              </p>
              <p className="counter_title fs-5 fw-bold">Running Web Projects</p>
            </div>
            <div className="col-lg-3 col-6  counter_item">
              <p className="counter_number fs-2 fs-md-4 fs-sm-5 fw-bold">
                {counterOn && (
                  <CountUp start={0} end={50} duration={2} delay={0} />
                )}
                +
              </p>
              <p className="counter_title fs-5 fw-bold">Finished Web Projects</p>
            </div>
            <div className="col-lg-3 col-6  counter_item">
              <p className="counter_number fs-2 fs-md-4 fs-sm-5 fw-bold">
                {counterOn && (
                  <CountUp start={0} end={5} duration={2} delay={0} />
                )}
                +
              </p>
              <p className="counter_title fs-5 fw-bold">Years of experience</p>
            </div>
          </div>
        </div>
      </section>
    </ScrollTrigger>
  );
};

export default React.memo(ProjectCounter) ;
