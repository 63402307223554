import React from "react";

import Demo from "./Demo";
import QuickLinks from "./QuickLinks";
import Layouts from "../layout/Layouts";
import webdesign from "../../assets/WebpImages/webdesign.webp";
import res2 from "../../assets/WebpImages/res2.webp";
import web3 from "../../assets/WebpImages/web32.webp";
import ecom1 from "../../assets/WebpImages/website-maintenance.webp"
import res from "../../assets/WebpImages/responsive-website.webp";

const WebDesignBoston = () => {
  const heroSection = {
    backgroundImage: "https://via.placeholder.com/1600x400",
    heading: "Web Design in Boston ",
    paragraph:
      "Transform Your Digital Presence with Bytefaze's Premier Web Design & development services in Boston",
  };
  const contentArray = [
    {
      image: webdesign,
      heading: "Innovative and Functional Design",
      text: "At Bytefaze, we understand the competitive landscape of Boston’s digital market. Our approach combines creativity with functionality to create websites that captivate and engage your target audience while meeting your business objectives. We design websites that are not only visually stunning but also highly functional and user-friendly.",
    },
    {
      image: res2,
      heading: "Responsive Web Design",
      text: "In today’s mobile-driven world, having a responsive website is crucial. Our designs ensure that your site looks and performs flawlessly across all devices, providing a seamless user experience for your Boston audience and beyond.",
    },
    {
      image: web3,
      heading: "Expertise and Excellence",
      text: "With a commitment to excellence, Bytefaze delivers high-quality web design services that stand out in the Boston area. Our focus on delivering top-notch results means you can trust us to provide a website that meets your highest expectations.",
    },
    {
        image: res,
        heading: "Comprehensive Web Design Services",
        text: "Comprehensive Web Design Services From designing new websites to redesigning existing ones, our services cover all aspects of web design. We offer:<ul><li>Custom Website Design and Development : Unique, brand-aligned designs that engage and convert visitors.</li><li>Responsive Design : Mobile-friendly websites that ensure optimal performance on all devices.</li><li>Website Redesign : Modernizing your existing site to enhance functionality and aesthetics.</li><li>Tailored Development Solutions: Web development services customized to fit your specific business needs.</li></ul>",
      },
    
    // more content objects
  ];

  const ctaContent = {
    text: "Take the Next Step Towards Digital Excellence!",
    para : "Transform your digital presence with Bytefaze Web Solutions, the leading web design company serving Boston. Our global expertise and dedication to excellence ensure that your website will not only stand out but also deliver measurable results. Contact us today to discuss how our bespoke web design solutions can help you achieve your online goals in Boston and beyond.",
    buttonLabel: "Request A Meeting",
  };

  const ctaIndex = 3;

  const textSections = [
    {
        index: 0,
        heading: "Custom Solutions Tailored to Boston Businesses",
        text: "Welcome to Bytefaze Web Solutions, your premier choice for exceptional web design services in Boston. While we are headquartered in India, our expertise extends globally, offering businesses in Boston top-tier website design and development that not only enhances your online presence but also drives significant results.Whether you’re a startup aiming to make a splash or an established company looking for a digital transformation, our custom web design solutions are tailored to your specific needs. We ensure that every element of your website reflects your brand identity and resonates with the Boston market.",
      },
    {
      index: 3,
      heading: "Why Choose Us?",
      text: "As the Best Web Design Agency for businesses in Boston, we offer a range of benefits that set us apart: <ul><li><b>Tailored Solutions: </b>We create custom web solutions that cater specifically to the needs of Bostonian businesses.</li><li><b>Experienced Team:</b> Our designers and developers are experts in the latest web technologies and trends.</li><li><b>Mobile-Friendly Designs: </b> We ensure your website delivers a seamless experience across all devices.</li><li><b>Comprehensive Services: </b> From initial design to maintenance and custom applications, we provide a full range of web services.</li><li><b>Client Satisfaction:</b> Your satisfaction is our priority. We work closely with you to ensure your website aligns with your business goals.</li></ul>",
    },
    
    // Add as many text sections as needed
  ];

  const linksData = [
    {
      text: "Ecommerce Website Development",
      url: "/ecommerce-website",
    },
    { text: "Mobile Application Development", url: "/application-development" },
    { text: "Latest Trends of Website Designing", url: "/blog/riding-the-wave-unveiling-the-latest-trends-in-web-design" },
    { text: "Responsive Website Design ", url: "/responsive-website" },
    
    // Add more links as needed
  ];

  const searchKeywordsData = [
    "Best Website Designer in Boston",
    "Website Design Boston",
    "Best Web Design Agency in Boston","Professional Website Development Boston","Mobile-Friendly Web Developer in Boston","Website Redesign Services in Boston", "Custom Web Application Design Agency in Boston","Website Maintainace in Boston"
    // Add more keywords as needed
  ];

  return (

    <Layouts
      title={"Custom Web Design Company in Boston | Affordable & Responsive Services"}
      keywords={
        "Best Website Designer in Boston, Web Design Company, Boston Web design services, Responsive Website Development, Dynemic Website Development, Custom Web Design Services, Website Design Boston, Best Web Design Agency, professional website development, mobile-friendly, website redesign, website maintenance services, custom web application"
      }
      description={
        "Make a responsive websites with Bytefaze Web Solutions, Best website designing company in Boston tailored to your business. Contact us today for affordable & custom website development. "
      }
      canonical={"https://www.bytefaze.com/web-design-boston"}
      ldName={"Website Design & Development in Boston"}
      serviceType={"Web Designing & Development Service in Boston"}
    >

    

      <Demo
        contentArray={contentArray}
        textSections={textSections}
        ctaContent={ctaContent}
        ctaIndex={ctaIndex}
        heroSection={heroSection}
        myQuicklinks={<QuickLinks links={linksData} searchKeywords={searchKeywordsData} />}
      />
  </Layouts>


  );
};

export default WebDesignBoston;
