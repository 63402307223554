import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../layout/Layouts';
//import "../../Styles/styles.css";
import { Link, useNavigate ,useParams } from 'react-router-dom';

const HireUiUx = () => {
  const navigate = useNavigate();
  const lastSegment = useParams();
  const lasturl = lastSegment.technology;
  const techdata = lasturl;

  const navigateToContacts = () => {    
    navigate('/contact-us');
  };

  return (
    <Layout 
    title={"Hire UI/UX Design Developer in Ahmedabad | UI/UX Website Design Services - Bytefaze"} 
    //keywords={"UI/UX designer User interface design User experience design UI design company UX design services UI/UX design agency UI/UX design solutions UI/UX design expertise Experienced UI/UX designers"} 
    description={"Bytefaze offers Custom UI/UX Design web development services. Hire UI/UX Design developers to make your small-scale website or complex application development in Ahmedabad"}
    canonical={"https://www.bytefaze.com/hire-ui-ux-developers/"}
    ldName={"Hire UI/UX Design Developer"}
    ldImage={"https://www.bytefaze.com/static/media/bytedark.2c56fa28e00899c28bcc.webp"}
    serviceType={"UI/UX Design Web Developement"}
  >
  
      <div>
        <div className="col-12 hire_bg">
          <h1 className="display-3 text-center fw-bold">Hire UI/UX Designers</h1>
        </div>

        {/* Section1 starts here */}
        <div style={{backgroundColor: "var(--footer-bg)"}}>
          <div className="container row">
            <div className="row">
              <h1 className="col-md-12 heading text-center col-12 mt-4">
                Hire UI/UX Designers
              </h1>
            </div>
          </div>

          <div className="container w-75 mt-2">
            <div className="row">
              <p className="heading-small col-md-12 text-center mt-2 w-100">
                Hire UI/UX designers from Bytefaze - a leading UI/UX design agency. Our savvy designers have extensive experience delivering highly customized, industry-grade, visually appealing, and intuitive user interfaces, leading your product to success.
              </p>
            </div>
          </div>

          <div className="container font01">
            <div className="row mt-3 mb-5 d-flex justify-content-center text-center gap-3">  
              <div className="col-md-12 col-sm-12 col-lg-3 border paddi parh mb-2">
                <i className="ri-checkbox-circle-line"></i>
                Flexible hiring models
              </div>

              <div className="col-md-12 col-sm-12 col-lg-3 border paddi mb-2 text-al task">
                <i className="ri-checkbox-circle-line"></i>
                100% Experienced
              </div>
              <div className="col-md-12 col-sm-12 col-lg-3 border mb-2 paddi">
                <i className="ri-checkbox-circle-line"></i>
                100% Refund policy*
              </div>
            </div>
          </div>
        </div>
        {/* Section1 ends here */}

        <Container className="my-5 how">
          <h2 className="text-center mb-5" style={{color: "var(--link-color)"}}>How to Hire Designers Through<Link to="/"> Bytefaze</Link></h2>
          <Row className="text-center align-items-center">
            <Col md={4} className="position-relative">
              <div className="mb-3">
                <div className="circle mb-2">1</div>
                <h5>Talk to One of Our Industry Experts</h5>
                <p>A Bytefaze director of design will work with you to understand your goals, design needs, and project dynamics.</p>
              </div>
              <div className="arrow d-none d-md-block"></div>
            </Col>
            <Col md={4} className="position-relative">
              <div className="mb-3">
                <div className="circle mb-2">2</div>
                <h5>Work With Hand-Selected Talent</h5>
                <p>Within days, we'll introduce you to the right designer for your project. Average time to match is under 24 hours.</p>
              </div>
              <div className="arrow d-none d-md-block"></div>
            </Col>
            <Col md={4}>
              <div className="mb-3">
                <div className="circle mb-2">3</div>
                <h5>The Right Fit, Guaranteed</h5>
                <p>Work with your new UI/UX designer for a trial period (pay only if satisfied), ensuring they're the right fit before starting the engagement.</p>
              </div>
            </Col>
          </Row>
        </Container>

        <section className="hire-developer py-4">
          <div className="container w-100 h-100">
            <div className="row">
              <h2 className="col-12 b-heading text-center mt-4">
                Hire UI/UX Designer For Evolving Project Needs
              </h2>
              <h6 className="small-heading-2 col-12 b-heading text-center">
                Our Dedicated team of UI/UX designers has helped businesses achieve their goals By creating high-performing and visually stunning User Interfaces.
              </h6>

              <div className="col-md-6 mt-4">
                <div className="classWithPad border1 padd">
                  <h4 className='mt-1'>User Experience Design</h4>
                  <p className='mt-4'>Craft intuitive and engaging user experiences that delight users and drive business growth.</p>
                </div>
              </div>

              <div className="col-md-6 mt-4">
                <div className="classWithPad border1 padd">
                  <h4 className='mt-1'>User Interface Design</h4>
                  <p className='mt-4'>Design visually appealing and functional user interfaces that captivate users and enhance brand perception.</p>
                </div>
              </div>

              <div className="col-md-6 mt-4">
                <div className="classWithPad border1 padd">
                  <h4 className='mt-1'>Prototyping</h4>
                  <p className='mt-4'>Create interactive prototypes to visualize and test the user experience before development.</p>
                </div>
              </div>

              <div className="col-md-6 mt-4">
                <div className="classWithPad border1 padd">
                  <h4 className='mt-1'>Wireframing</h4>
                 
                  <p className='mt-4'>Develop wireframes to outline the structure and layout of user interfaces, facilitating the design process.</p>
                </div>
              </div>

              <div className="col-md-6 mt-4">
                <div className="classWithPad border1 padd">
                  <h4 className='mt-1'>Visual Design</h4>
                  <p className='mt-4'>Create visually stunning designs that align with brand guidelines and user expectations.</p>
                </div>
              </div>

              <div className="col-md-6 mt-4">
                <div className="classWithPad border1 padd">
                  <h4 className='mt-1'>Usability Testing</h4>
                  <p className='mt-4'>Conduct usability testing to identify areas for improvement and refine the user experience.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hire">
          <div className="container mt-5 text-center">
            <div className="row">
              <div className="col-12">
                <h3 className="hire-title">Looking for Dedicated UI/UX Designers?</h3>
                <p className="hire-description mt-3">Let's discuss your design requirements and create exceptional user experiences that elevate your brand and engage your audience.</p>
                <div className="call-btn" onClick={navigateToContacts}>
                  <button className="btn mb-5" >BOOK A CALL <i className="ri-phone-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="gapes">
          <div className="container py-2">
            <div className="row">
              <div className="col-md-12">
                <h4 className="text-center text-white">Dedicated Hiring Models we Follow</h4>
                <p className="text-center mt-3 text-white">Hire skilled talent from Bytefaze who will work dedicatedly as your extended team to meet your diverse digital product development needs responsibly.</p>
              </div>
            </div>
          </div>

          <div className="container py-2">
            <div className="row d-flex justify-content-between">
              <div className="col-md-6">
                <div className="feature-card">
                  <h3 className="feature-title">Enhance your Current Team</h3>
                  <p className="feature-description">Choose this model when you want to hire specific skill sets from our diverse talent pool to go beyond the traditional hiring approach.</p>
                </div>
              </div>

              <div className="col-md-6 col-sm-12">
                <div className="feature-card">
                  <h3 className="feature-title">Create a Team From Ground Up</h3>
                  <p className="feature-description">Choose this model when you want to build a dedicated team from scratch to address your project requirements effectively.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="middle-heading">
          <div className="container py-3 mt-1">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="Z">
                  <h2 className="b-heading mt-4">Hire Dedicated UI/UX Designers From India in 4 Easy Steps Follow Us</h2>
                </div>
              </div>
              <div className="col-md-12">
                <h6 className="py-3 w-100 text-center">Looking to hire the best-fit talent for your unique, mission-critical project? Look no further as Bytefaze follows a quick and easy 4-step process to get you the best talent meeting your requirements.</h6>
              </div>

              <div className="container-fluid midsec">
                <div className="row d-flex justify-content-between">
                  <div className="col-md-6 col-lg-3 mt-4">
                    <div className="padding fact h-100">
                      <i className="ri-share-circle-line icon"></i>
                      <h5>1. Share Your Requirements</h5>
                      <p>Provide detailed information about your project requirements, including the specific skills you are looking for in the talent you want to hire.</p>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 mt-4">
                    <div className="padding fact h-100">
                      <i className="ri-account-pin-circle-line icon"></i>
                      <h5>2. Shortlist the Right Fit</h5>
                      <p>Once we receive your project requirements, our team will review them thoroughly to identify the right candidates who match your criteria.</p>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 mt-4">
                    <div className="padding fact h-100">
                      <i className="ri-checkbox-circle-fill icon"></i>
                      <h5>3. Interview the Talent</h5>
                      <p>After shortlisting the candidates, you will have the opportunity to interview them to assess their skills, experience, and fit for your project.</p>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 mt-4">
                    <div className="padding fact h-100">
                      <i className="ri-computer-line icon"></i>
                      <h5>4. Onboard & Project</h5>
                      <p>Once you've selected the talent you want to hire, we will assist you with the onboarding process, including setting up contracts and access to project resources.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="who-we-are">
          <div className="container w-100">
            <h3 className="text-center pt-1 mb-4">What We Assure to Provide</h3>
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Guaranteed 160 working hours</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Monthly/yearly hiring options available</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Weekly/monthly reporting via email or preferred communication channel</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Weekly/monthly code delivery to track progress</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Direct communication support with our team members</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Flexible office hours depending on the time zone</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};
export default HireUiUx