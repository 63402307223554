import React from "react";
import Layout from "../../layout/Layouts";
import img1 from "../../../assets/WebpImages/expert-web-services.webp";
import { Link } from "react-router-dom";
import Card from '../Card'
import logo from "../../../assets/WebpImages/bytedark.webp";
//import { LazyLoadImage } from "react-lazy-load-image-component";

const ExpertWebServices = () => {
  const data = {
    tag: "Expert Website Designing Services in Ahmedabad",
    description:
      "Looking for an expert Website Designing Services in Ahmedabad? ByteFaze offers professional web designing & developing solutions to elevate your online presence. Contact us today for top-tier web design in Ahmedabad.",
    keywords:
      "Expert Web Design Services, Website Design Services in Ahmedabad, Web Design Company in Ahmedabad, Website designing services, Professional web design, Affordable website design, Ahmedabad web design, Customized web design solutions, Responsive website design, Expert web designers,  Business growth online, ByteFaze web Solutions, Top web design services",
    img: img1,
    altTag: "Expert Website Designing Services in Ahmedabad", //image Alt tag
    label: "Expert Website Designing Services",
    title: "Expert Website Designing Services in Ahmedabad",
    PageTitle: "Expert Website Designing Services by ByteFaze",
    date: "21 June 2024",
    desc: (<p className="lead fw-bold">
      At ByteFaze, an expert website designing company in Ahmedabad, we specialize in offering customized website designing services. Each project begins with a thorough consultation to understand your unique requirements and business goals. Our team crafts bespoke web designs that reflect your brand’s identity and engage your target audience effectively.      </p>
      
    ),
    img2: img1,
    points: (
      <>
        <div className="container w-75">
          <div className="row  item-align-center col-md-12 text-center">
            <table className="table border">
              <thead>
                <tr>
                  <th style={{ color: "var(--link-color)" }}>
                    Table of Contents
                  </th>
                </tr>
              </thead>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#professional-web-design-solutions">Professional Web Design Solutions in Ahmedabad
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#affordable-website-designing-packages">
                  Affordable Website Designing Packages

                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#innovative-website-designing-services">
                  Innovative Website Designing Services

                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#comprehensive-web-design-services">
                  Comprehensive Web Design Services

                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#website-designing-services-for-all-industries">
                  Website Designing Services for All Industries

                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#high-performance-website-designing-services">
                  High-Performance Website Designing Services


                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#creative-website-designing-services">
                  Creative Website Designing Services



                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#reliable-web-design-services">
                  Reliable Web Design Services
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#local-website-designing-services">
                  Local Website Designing Services

                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
        {/* point1 */}
         <h2 style={{ color: "var(--link-color)" }} id="professional-web-design-solutions">
         Professional Web Design Solutions in Ahmedabad
        </h2>
        <p className="top-para">
        Bytefaze stands out as one of the top website designing companies in Ahmedabad, offering <b>professional web design solutions</b> tailored to meet diverse business needs. Whether you need a simple informational site or a complex e-commerce platform, our <b>expert web designers and developers</b> ensure that your website is both visually stunning and highly functional.
        </p>

        {/* point1 */}
        <h2 style={{ color: "var(--link-color)" }} id="affordable-website-designing-packages">Affordable Website Designing Packages</h2>
        <p className="top-para">In today's digital age, a website is no longer a luxury but a necessity for businesses of all sizes. However, the cost of professional website design can often be a barrier for small and medium-sized enterprises. At Bytefaze, we believe that every business deserves a high-quality online presence, regardless of budget.</p>
        <p className="top-para">Our commitment to affordability doesn't compromise on quality. We offer a range of cost-effective website design packages tailored to meet the specific needs of your business. Whether you're a startup, a small business, or a non-profit organization, we have a solution that fits your budget without sacrificing design or functionality.</p>
        <p className="top-para">Transparency is key. Our pricing is clear and straightforward, with no hidden fees. We believe in building long-term partnerships with our clients, and our affordable pricing is just one way we demonstrate our commitment to your success. Our packages include:</p>
        <ul>
          <li><b>Basic Website Design:</b>Starting at RS 10,000, perfect for startups and small businesses looking for a simple yet professional online presence. Includes up to 5 pages, mobile optimization, and basic SEO setup.</li>
          <li><b>Standard Website Design:</b> Starting at RS 20,000, ideal for growing businesses that need additional features. Includes up to 10 pages, advanced SEO, and a blog setup.</li>
          <li><b>Premium Website Design: </b>Starting at RS 40,000, for businesses seeking a fully customized website with all the bells and whistles. Includes unlimited pages, e-commerce functionality, and ongoing support.</li>
        </ul>
        <p className="top-para">Each of our packages comes with a detailed breakdown of the services included, so you can make an informed decision that best suits your business needs. Our budget website design packages ensure you get maximum value for your investment, with no surprises along the way.</p>
        <p className="top-para">Let's work together to create a website that not only looks great but also drives results. Contact us today for a free consultation and discover how our budget-friendly website design services can help your small business thrive online.</p>
        <p className="top-para">Key services:</p>
        <ul>
          <li>Cost-effective website design packages</li>
          <li>Transparent pricing</li>
          <li>Transparent pricing</li>
          <li>Small business website specialists</li>
        </ul>
        {/* point3 */}
        <h2 style={{ color: "var(--link-color)" }} id="innovative-website-designing-services">
          Innovative Website Designing Services
        </h2>
    
        <ul style={{ color: "var(--link-color)" }}>
          <li>
            <b>Modern and Trendy Website Designs: </b>Stay ahead of the competition with<b> modern and trendy website designs</b> from ByteFaze. Our designers stay updated with the latest industry trends to create contemporary designs that captivate and engage users, enhancing your online presence.
          </li>
          <li>
            <b>Cutting-Edge Web Design Technologies:</b> At ByteFaze, we integrate <b>cutting-edge web design technologies</b> to ensure your site is ahead of the curve. From responsive designs to advanced animations, we use the latest tools and techniques to deliver an outstanding user experience.
          </li>
          <li>
            <b>User-Centric Website Designing Approach:</b> Our <b>user-centric website designing approach </b>ensures that every aspect of your website is optimized for usability. We focus on intuitive navigation, clear calls to action, and engaging content to create a seamless experience for your visitors.
          </li>
          
        </ul>
        {/* point3*/}
        <h3
          style={{ color: "var(--link-color)" }}
          id="comprehensive-web-design-services"
        >
          Comprehensive Web Design Services
        </h3>
       <ul>
        <li><b>Full-Stack Website Design and Development:</b> ByteFaze offers <b>Full Stack website design and development</b>, handling everything from initial concept to final launch. Our comprehensive services include design, development, testing, and ongoing support, ensuring your website is always performing at its best.</li>
        <li><b>E2E Website Designing Solutions:</b> Our E2E website designing solutions cover all aspects of web design. From initial strategy and planning to design, development, and deployment, ByteFaze ensures that your website meets all your business needs and goals.</li>
        <li><b>Holistic Web Design Services:</b> ByteFaze provides holistic web design services that encompass every element of your online presence. Our team ensures that your website is not only visually appealing but also optimized for performance, security, and user engagement.</li>
       </ul>
       <p className="top-para">With numerous successful web design projects in Ahmedabad, our clients appreciate our position as leading web design experts</p>

        <div className="d-flex justify-content-center ">
          <Link to="/blog/embracing-the-future-of-web-design-in-ahmedabad-with-bytefaze-web-solution">
            <button className="button-85 mb-3 col-12 col-lg-12 mt-1">
              Also Read : Future of Web Design In Ahmedabad
            </button>
          </Link>
        </div>
        {/* point4*/}
        <h3
          style={{ color: "var(--link-color)" }}
          id="website-designing-services-for-all-industries"
        >
         Website Designing Services for All Industries
        </h3>
       
        <ul style={{ color: "var(--link-color)" }}>
          <li>
            <b>E-commerce Website Designing Services:</b> Boost your online sales with our e-commerce website designing services. ByteFaze creates user-friendly and secure online stores that provide an exceptional shopping experience, driving customer satisfaction and repeat business.
          </li>
          <li>
            <b>Corporate Web Design Solutions:</b> Our corporate web design solutions are designed to establish and enhance your brand’s online presence. ByteFaze focuses on creating professional, sleek, and functional websites that reflect your corporate identity and values.
          </li>
          <li>
            <b>Portfolio Website Designing Services:</b> Showcase your work with our portfolio website designing services. ByteFaze designs elegant and intuitive portfolio websites that highlight your projects and achievements, making a strong impression on potential clients and employers.
          </li>
          
        </ul>
        <h4
          style={{ color: "var(--link-color)" }}
          id="high-performance-website-designing-services"
        >
          High-Performance Website Designing Services
        </h4>
       <ul>
        <li><b>SEO-Optimized Web Design:</b> At ByteFaze, we understand the importance of SEO. Our SEO-optimized web design services ensure that your site ranks higher in search engine results, increasing visibility and driving more organic traffic to your business.</li>
        <li><b>Mobile-Responsive Website Designing:</b> With more users accessing websites via mobile devices, ByteFaze emphasizes mobile-responsive website designing. Our designs are optimized for all screen sizes, ensuring a seamless and engaging user experience on any device.</li>
        <li><b>Fast-Loading Web Design Services:</b> ByteFaze provides fast-loading web design services to enhance user experience and reduce bounce rates. We use advanced techniques to optimize your site’s performance, ensuring quick load times and smooth navigation.</li>
       </ul>
        {/* point5*/}
        <h4
          style={{ color: "var(--link-color)" }}
          id="creative-website-designing-services"
        >
          Creative Website Designing Services
        </h4>
        <ul>
            <li><b>Unique and Engaging Website Designs:</b> Stand out from the crowd with unique and engaging website designs from ByteFaze. Our creative team designs websites that capture attention and leave a lasting impression, helping your brand to distinguish itself in the digital marketplace.</li>
            <li><b>Custom Graphics and Visual Elements:</b> ByteFaze incorporates custom graphics and visual elements into your website to enhance its aesthetic appeal. Our designers create high-quality visuals that align with your brand identity, making your site more attractive and memorable.</li>
            <li><b>Interactive Website Designing Features:</b> Engage your audience with interactive website designing features from ByteFaze. We integrate interactive elements such as animations, forms, and multimedia content to make your website more dynamic and user-friendly.</li>
        </ul>

        <div className="d-flex justify-content-center ">
          <Link to="/blog/web-designing-ahmedabad">
            <button className="button-85 mb-3 col-12 col-lg-12 mt-1">
              Also Read : Web Designing Ahmedabad
            </button>
          </Link>
        </div>
        <h4
          style={{ color: "var(--link-color)" }}
          id="reliable-web-design-services"
        >
         Reliable Web Design Services
        </h4>
        <ul>
            <li><b>Trusted Website Design Company:</b> ByteFaze is a trusted website design company in Ahmedabad, known for delivering exceptional results. Our commitment to quality and customer satisfaction ensures that your website not only meets but exceeds your expectations.</li>
            <li><b>Experienced Web Designers:</b> Our team of experienced web designers at ByteFaze brings a wealth of knowledge and creativity to every project. We combine technical expertise with innovative design thinking to create websites that drive results.</li>
            <li><b>Client-Focused Website Designing Process:</b> At ByteFaze, our client-focused website designing process ensures that your needs and goals are at the forefront of everything we do. We work closely with you throughout the design process, providing regular updates and incorporating your feedback to deliver a website that truly represents your brand.</li>
        </ul>
        <h4
          style={{ color: "var(--link-color)" }}
          id="local-website-designing-services"
        >
        Local Website Designing Services
        </h4>
        <ul>
            <li><b>Website Designing Services in Ahmedabad:</b> ByteFaze is proud to offer top-tier website designing services in Ahmedabad. Our local expertise and understanding of the market enable us to deliver designs that resonate with your target audience and drive local engagement.</li>
            <li><b>Local Business Web Design Solutions:</b> We provide specialized web design solutions for local businesses in Ahmedabad. ByteFaze understands the unique challenges and opportunities of the local market, and we tailor our services to help your business thrive online.</li>
            <li><b>Personalized Website Designing for Ahmedabad Businesses:</b> ByteFaze offers personalized website designing services for Ahmedabad businesses, ensuring that each project reflects the distinct character and needs of our clients. Our customized approach guarantees a website that stands out in the local market and drives business growth.</li>
        </ul>
      </>
    ),
    lastp:
      "Ready to transform your online presence? Contact Bytefaze today who is best web design agency in Ahmedabad and hire us as your web designer partner, we can make a difference",

    // "In today's digital world, having a strong online presence is essential for any local business. A well-designed website acts as your digital storefront, attracting new customers and keeping existing ones engaged. Here's a breakdown of website design services specifically tailored for local businesses:"

    Fieldtags: (
      <>
        <div className="container mt-1">
          <div className="row">
            <div className="col">
              <div className="d-flex flex-wrap">
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Expert Website Designer in Ahmedabad
                </Link>
                <Link
                  to="/website-designing"
                  className="btn btn-outline-secondary m-1"
                >
                  Affordable Website Design in Ahmedabad
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                Professional Web Design Solutions

                </Link>
                <Link
                  to="/responsive-website"
                  className="btn btn-outline-secondary m-1"
                >
                  Innovative Website Designing Company

                </Link>
                <Link
                  to="/digital-marketing"
                  className="btn btn-outline-secondary m-1"
                >
                  Website Designing Services for All Industries

                </Link>
                <Link
                  to="/website-designing"
                  className="btn btn-outline-secondary m-1"
                >
                  High-Performance Website Designing Services

                </Link>
                <Link
                  to="/ecommerce-website"
                  className="btn btn-outline-secondary m-1"
                >
                 Creative Website Designing Services in Ahmedabad

                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                Reliable Web Design Services

                </Link>
                <Link
                  to="/website-designing"
                  className="btn btn-outline-secondary m-1"
                >
                  Local Website Designing Services
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Best Website Development in Ahmedabad
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Top Web Design Company in Ahmedabad
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  };

  return (
    <Layout
      title={data?.title}
      keywords={data?.keywords}
      description={data?.description}
      canonical={"https://www.bytefaze.com/website-designing"}
      url={"https://www.bytefaze.com/blog/embracing-the-future-of-web-design-in-ahmedabad-with-bytefaze-web-solution/"}
      ldName={"Future of Web Design With Bytefaze Ahmedabad"}
    ldImage={img1}
    >
      {/* <!-- Featured 1 - Bootstrap Brain Component --> */}
      <section className="py-3 py-md-5 py-xl-8 ">
        <div className="container">
          <div
            className="row gy-3 gy-lg-0 align-items-lg-center justify-content-lg-between"
            style={{ color: "var(--link-color)" }}
          >
            <div className="col-12 col-lg-7 order-1 order-lg-0">
              <div className="entry-header mb-3">
                <ul className="entry-meta list-unstyled d-flex mb-3">
                  <li>
                    <a
                      className="d-inline-flex px-2 py-1 link-accent text-accent-emphasis bg-accent-subtle border border-accent-subtle rounded-2 text-decoration-none fs-7"
                      href="#"
                    >
                      {data?.label}
                    </a>
                  </li>
                </ul>
                <h1 className="display-3 fw-bold mb-4">{data?.PageTitle}</h1>
              </div>
              <div className="entry-footer">
                <ul className="entry-meta list-unstyled d-flex align-items-center m-0">
                  <li>
                    <a
                      className="fs-7  text-decoration-none d-flex align-items-center"
                      href="#"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fillRule="currentColor"
                        className="bi bi-calendar3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                        <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                      </svg>
                      <span
                        className="ms-2 fs-7"
                        style={{ color: "var(--link-color)" }}
                      >
                        {data?.date}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <a
                className="d-block bsb-hover-image overflow-hidden rounded"
                href="#"
              >
                <img 
                  className="img-fluid bsb-scale bsb-hover-scale-up"
                  src={img1}
                  alt="Expert Website Designing Company"
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Main --> */}
      <main id="main">
        {/* <!-- Entry Content Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10">
          <div className="container">
            <div className="row justify-content-md-center gy-3 gy-xl-4">
              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
                {data?.desc}
                {/* <p className="lead fw-bold">{data?.desc} <a href="#">emotional support</a> in a way that is healthy for both you and your partner.</p> */}
              </div>

              <div className="col-12 text-center">
                <img 
                  className="img-fluid my-4 rounded"
                  src={img1}
                  alt="Expert Web Designer in Ahmedabad"
                />
              </div>

              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
                {data?.points}
                <p>{data?.points2}</p>
                {/* <h2 style={{color:"var(--link-color)"}}>Offer Physical Affection</h2> */}
                {/* <img  className="img-fluid mt-3 mb-5 rounded" src={img1} alt=""/> */}
                <p>{data?.lastp}</p>
                {/* <p>Different people like different levels of physical affection at different times. If you are feeling unsure, communicate with your partner so you can learn what they are open to receiving and can provide it accordingly.</p> */}
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Entry Meta --> */}
        <div className="pb-3 pb-md-5 pb-xl-10">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
                <span
                  className="me-1 fw-bold"
                  style={{ color: "var(--link-color)" }}
                >
                  {" "}
                  Tags:
                </span>
                <ul className="entry-meta list-unstyled d-inline-flex align-items-center justify-content-center mb-3">
                  {data.Fieldtags}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Post Pagination 2 - Bootstrap Brain Component --> */}
        <section className="bsb-post-pagination-2  py-0 py-md-0 py-xl-0 bsb-section-py-xxl-1X">
          <div className="container overflow-hidden">
            <div className="row justify-content-lg-center">
              <div className="col-12 col-lg-10 col-xl-8 col-xxl-7"></div>
            </div>
          </div>
        </section>

        {/* <!-- Author 2 - Bootstrap Brain Component --> */}
        <section className="bsb-author-2 py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1X">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-8 col-xxl-7">
                <div className=" border-light-subtle p-4 border">
                  <div className="row gy-3 align-items-center">
                    <div className="col-md-4">
                      <a
                        href="#"
                        className="bsb-hover-image d-block rounded overflow-hidden"
                      >
                        <img 
                          className="img-fluid author-avatar bsb-scale bsb-hover-scale-up"
                          src={logo}
                          alt="Best Web Designing Ahmedabad"
                        />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h6
                          className="card-subtitle mb-2  "
                          style={{ color: "var(--link-color)" }}
                        >
                          Article written by
                        </h6>
                        <h3 className="card-title mb-2">
                          <a
                            className="card-link  text-decoration-none"
                            href="#"
                          >
                            Bytefaze
                          </a>
                        </h3>
                        <p className="card-text mb-3">
                          We are skilled in all aspects of web design, from
                          planning and development to design and testing. I also
                          have experience in web development technologies.
                        </p>
                        <ul className="bsb-social-media nav m-0">
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              to="https://www.facebook.com/bytefazewebsolutionsahmedabad/"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-facebook"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                              </svg>
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                  <a className="nav-link " href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fillRule="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                    </svg>
                  </a>
                </li> */}
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              to="https://x.com/Bytefaze"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-twitter"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                              </svg>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              href="https://www.linkedin.com/company/bytefaze/"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-linkedin"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                              </svg>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Blog 5 - Bootstrap Brain Component --> */}
        <section className="bsb-blog-5 py-3 py-md-5 py-xl-8 bsb-section-py-xxl-1X ">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12">
                <h2
                  className="h4 mb-4 mb-md-5"
                  style={{ color: "var(--link-color)" }}
                >
                  Related Articles
                </h2>
              </div>
            </div>
          </div>

          {/* <div className="container overflow-hidden px-4"> */}
          {/* <div className="row gy-4 gy-md-5 gx-xl-6 gy-xl-6 gx-xxl-9 gy-xxl-9"> */}
          <Card />
          {/* </div> */}
          {/* </div> */}
        </section>

        {/* <!-- Newsletter 1 - Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1 bg-accent">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-9 col-lg-8 col-xl-8 col-xxl-7">
                <h2
                  className="display-4 fw-bold mb-4 mb-md-5 mb-xxl-6 text-center text-accent-emphasis"
                  style={{ color: "var(--link-color)" }}
                >
                  Sign up for our newsletter and never miss a thing.
                </h2>
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
                <form className="row gy-3 gy-lg-0 gx-lg-2 justify-content-center">
                  <div className="col-12 col-lg-8">
                    <label
                      htmlFor="email-newsletter-component"
                      className="visually-hidden"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control bsb-form-control-3xl"
                      id="email-newsletter-component"
                      placeholder="Email Address"
                      aria-label="email-newsletter-component"
                      aria-describedby="email-newsletter-help"
                      required
                    />
                    <div
                      id="email-newsletter-help"
                      className="form-text text-center text-lg-start"
                    >
                      We'll never share your email with anyone else.
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 text-center text-lg-start">
                    <button type="submit" className="btn border bsb-btn-3xl">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default ExpertWebServices;
