import React from "react";
// //import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { Link, useNavigate } from "react-router-dom";
// import "../../Styles/About.css";
// import "../../Styles/styles.css"
import Layout from "../layout/Layouts";
// import Card from "../homecomponents/Card";
import aboutimg from "../../assets/WebpImages/aboutpage.webp";
// import aboutbanner from "../../assets/WebpImages/cloud-devops-banner.webp";
import AboutImg1 from "../../assets/WebpImages/about-img1.webp";
import AboutImg2 from "../../assets/WebpImages/about-img2.webp";
import AboutImg3 from "../../assets/WebpImages/about-img3.webp";
import AboutImg4 from "../../assets/WebpImages/about-img4.webp";
import Marquee from "./Marquee";
//import { LazyLoadImage } from "react-lazy-load-image-component";
// import Slider from "../homecomponents/Slider";
// import { Navigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  const navigateToContacts = () => {
    navigate("/contact-us");
  };
  return (
    <>
      <Layout
        title={ "Bytefaze : Best Web Designing, Website Development Company in Ahmedabad, Gujarat, India"
        }
        keywords={
          "Website Designing Company, Internship Company in Ahmedabad, It Internship Company, IT Training Company in Ahmedabad"
        }
        description={
          "Looking for the Best Web Designing Company in Ahmedabad ? Bytefaze can help you to make impactful website design, web software products, mobile application platforms in Ahmedabad, Gujarat, India"
        }
        canonical={"https://www.bytefaze.com/who-we-are"}
        ldName={"Web Design & Development"}
        serviceType={"Web Development & Designing Services"}
        ldImage={"https://bytefaze.com/static/media/best-web-designer.76aaa934964c89846462.webp"}
        
      >
        <div className="col-12 mb-4 about_bg">
          <h1 className="display-3 fw-bold">Who we are?</h1>
        </div>
        
        {/* <Card /> */}
        {/* OurProjects */}

        <section className="py-3 py-md-5">
          <div className="container mt-4">
            <div className="row gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
              <div className="col-12 col-lg-6 col-xl-5">
              <img 
                // width={600} height={400}
                  className="img-fluid rounded"
                  style={{ border: "2px solid var(--link-color)" }}
                  src={aboutimg}
                  alt="Learn PHP, Phython, React JS"
                />
                
              </div>
              <div className="col-12 col-lg-6 col-xl-7">
                <div className="row justify-content-xl-center">
                  <div className="col-12 col-xl-11">
                    <h2
                      className="mb-3"
                      style={{ color: "var(--primary-color)" }}
                    >
                      Bytefaze Web Solutions
                    </h2>
                    <p className=" top-para" style={{ color: "var(--link-color)" }}>
                    Bytefaze is a leading Ahmedabad-based <b>website development company</b> specializing in crafting exceptional digital experiences. With a deep-rooted passion for technology and innovation, we deliver cutting-edge web solutions that drive business growth.                    </p>  
                    <p  className="mb-4 top-para">Our team of <b>skilled web developers and designers</b> is committed to understanding our clients' unique needs and transforming their visions into reality. As <b><Link to='/'>expert web designer</Link></b>, we are dedicated to crafting robust, user-centric websites that not only captivate audiences but also deliver measurable results. By leveraging advanced web development technologies like React JS and Node JS, <b>we create dynamic and scalable web applications</b> that exceed expectations.</p>                                
                    <div className="row gy-4 gy-md-0 gx-xxl-5X">
                      <div className="col-12 col-md-6">
                        <div className="d-flex">
                          <div className="me-4 text-primary ">
                            <i className="ri-settings-2-line h2"></i>
                          </div>
                          <div>
                            <h2
                              className="h2 mb-3"
                              style={{ color: "var(--primary-color)" }}
                            >
                              Our Mission
                            </h2>
                            <p  className="top-para"
                              style={{ color: "var(--link-color)" }}
                            >
                             Our aim to revolutionize digital experiences by crafting innovative, high-performance websites that propel businesses to new heights of success.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="d-flex">
                          <div className="me-4 text-primary">
                            <i className="ri-fire-line h2"></i>
                          </div>
                          <div>
                            <h2
                              className="h2 mb-3"
                              style={{ color: "var(--primary-color)" }}
                            >
                             Our Vision
                            </h2>
                            <p
                              className="top-para"
                              style={{ color: "var(--link-color)" }}
                            >
                             To be a global leader in web development, delivering innovative digital solutions that empower businesses worldwide to achieve extraordinary growth and success.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="mt-2 top-para" style={{ color: "var(--link-color)" }}>
                    Our focus is on delivering <b>custom website development solutions</b> tailored to your specific business requirements. Whether you need a simple website or a complex e-commerce platform, we have the expertise to bring your ideas to life. Our commitment to SEO ensures that your website is optimized for search engines, driving organic traffic and improving your online visibility.
                    </p>  
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div style={{ background: "var(--footer-bg)" }} >
            <div className="container mt-5 about_section">
              <div className="row justify-content-md-center align-items-center">
                <div className="col-12 col-md-11 col-lg-9 col-xl-8 col-xxl-7">
                  <h1
                    className="h1 text-center fw-bold mb-2 slider-heading"
                    style={{ color: "var(--primary-color)" }}
                  >
                    Let's build the next big thing!
                  </h1>
                  <p className="lead text-center mb-4 d-flex justify-content-sm-center mt-4">
                    <span
                      className="col-12 col-sm-10 col-md-12 col-xxl-12"
                      style={{ color: "var(--link-color)" }}
                    >
                      Share your ideas and vision with us, and we can help you
                      turn them into impactful software products, platforms and
                      businesses.
                    </span>
                  </p>
                  <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mt-5">
                    <button
                      type="button"
                      className="btn btn-rounded border py-3 px-5"
                      onClick={navigateToContacts}
                    >
                      Get in touch with us &#8594;
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
           <div style={{ background: "var(--footer-bg)" }} className="mt-3" >
          <section className="py-3 py-md-5 py-xl-8">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div
                  className="col-lg-12 d-flex justify-content-center fw-bold text-center"
                  style={{ color: "var(--primary-color)" }}
                >
                  <h1> Let's Work Together For Build Your Project</h1>
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{ color: "blueviolet" }}
                >
                  <p className="text-center w-75">
                    {" "}
                    Share your ideas and vision with us, and we can help you
                    turn them into impactful <Link style={{color:'blue'}} to="/website-designing"><b>website design</b></Link>, web software products, mobile application platforms for promote businesses.!
                  </p>
                </div>
                <div
                  className="col-lg-12 d-flex justify-content-center"
                  onClick={navigateToContacts}
                >
                  <button
                    type="button"
                    className="btn btn-rounded border py-3 px-5"
                    onClick={navigateToContacts}
                  >
                    Get in touch with us &#8594;
                  </button>
                </div>
              </div>
            </div>
          </section>
          </div>
        </section>
        <section className="page-section" id="about">
          <div className="container">
            <div className="text-center mb-5">
              <h2
                className="section-heading text-uppercase"
                style={{ color: "var(--primary-color)" }}
              >
                Milestones
              </h2>
            </div>
            <ul className="timeline">
              <li>
                <div className="timeline-image">
                  <img 
                    className="rounded-circle img-fluid"
                    src={AboutImg1}
                    alt="Website Designing Ahmedabad"
                  />
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4>2021</h4>
                    <h4 className="subheading">Our Humble Beginnings</h4>
                  </div>
                  <div className="timeline-body">
                    <p>
                      Our journey began with a vision and a handful of talented
                      individuals. With dedication and passion, ByteFaze Web
                      Solutions emerged, laying the foundation for what would
                      become a beacon of innovation in the digital realm.
                    </p>
                  </div>
                </div>
              </li>
              <li className="timeline-inverted">
                <div className="timeline-image">
                  <img 
                    className="rounded-circle img-fluid"
                    src={AboutImg2}
                    alt="Website Development Internship in Ahmedabad"
                  />
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4>March 2021</h4>
                    <h4 className="subheading">An Agency is Born</h4>
                  </div>
                  <div className="timeline-body">
                    <p>
                      From humble beginnings, a <Link style={{color:'blue'}} to="/"><b>web designing agency</b></Link> was born, driven by a
                      relentless pursuit of excellence. We forged partnerships,
                      honed our craft, and embraced challenges, laying the
                      groundwork for our ascent in the digital landscape.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="timeline-image">
                  <img 
                    className="rounded-circle img-fluid"
                    src={AboutImg3}
                    alt="Paid Internship Company in Ahmedabad"
                  />
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4>December 2021</h4>
                    <h4 className="subheading">Transition to Full Service</h4>
                  </div>
                  <div className="timeline-body">
                    <p>
                      Evolving from a niche provider to a full-service
                      powerhouse, ByteFaze Web Solutions expanded its
                      repertoire. We diversified our offerings, fostered client
                      relationships, and solidified our position as a trusted
                      ally in navigating the complexities of the digital world.
                    </p>
                  </div>
                </div>
              </li>
              <li className="timeline-inverted">
                <div className="timeline-image">
                  <img 
                    className="rounded-circle img-fluid"
                    src={AboutImg4}
                    alt="Internship For IT Students"
                  />
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    <h4>2022</h4>
                    <h4 className="subheading">Phase Two Expansion</h4>
                  </div>
                  <div className="timeline-body">
                    <p>
                      Phase Two marked a pivotal juncture in our journey. With
                      strategic foresight and unwavering determination, we
                      embarked on a path of expansion as web development company. New markets were
                      conquered, capabilities of web designing enhanced, and our footprint in the
                      industry amplified, propelling us towards greater heights.
                    </p>
                  </div>
                </div>
              </li>
              <li className="timeline-inverted">
                <div className="timeline-image">
                  <h4>
                    Be Part
                    <br />
                    Of Our
                    <br />
                    Story!
                  </h4>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default About;
