import React from "react";
import Layouts from "../layout/Layouts";
import { Link } from "react-router-dom";



const Demo = ({ contentArray, ctaContent, ctaIndex, textSections, heroSection,myQuicklinks }) => {
  return (
    <>
      <div>
        {/* Hero Section with Background Image */}
        {heroSection && (
          <div
        
            className="text-center d-flex justify-content-center align-items-center demo_bg"
          >
            <div className="container">
              <div className="hero-content ">
                <h1 className="hero-heading  text-white text-center">{heroSection.heading}</h1>
                <p className="hero-paragraph text-white text-center">{heroSection.paragraph}</p>
              </div>
            </div>
          </div>
        )}

        {/* Main Content Section */}
        <div className="container py-4">
          {contentArray && contentArray?.map((content, index) => (
            <div key={index}>
               {/* Text-only section based on user-defined indices */}
    {textSections?.map((section, sectionIndex) => (
                <div key={`text-section-${sectionIndex}`}>
                  {index === section.index && (
                    <div className="row align-items-center mb-4">
                      <div className="col-12 text-center">
                        <h3 className="top-para text-center">{section.heading}</h3>
                      </div>
                      <p dangerouslySetInnerHTML={{ __html: section.text }} />
                    </div>
                  )}
                </div>
              ))}
              {/* Normal content with image and text */}
              <div
                className={`row align-items-center mb-4 ${
                  index % 2 === 0 ? "" : "flex-row-reverse"
                }`}
              >
                <div className="col-md-6">
                  <img
                    src={content?.image}
                    alt={`Content ${index + 1}`}
                    className="img-fluid rounded"
                  />
                </div>
                <div className="col-md-6">
                  <h3 className="top-para">{content?.heading}</h3>
                  <p dangerouslySetInnerHTML={{ __html: content?.text }} />
                  <h4 className="top-para">{content?.heading2}</h4>
                  <p dangerouslySetInnerHTML={{ __html: content?.text2 }} />
                </div>
              </div>
   
              {/* Dynamic Call to Action based on user-defined index */}
              {index === ctaIndex && ctaContent && (
                <div
                  style={{ background: "var(--footer-bg)", padding: "50px 0" }}
                  className="cta-section mb-4"
                >
                  <div className="container text-center">
                    <h3
                      className="top-para text-center"
                      dangerouslySetInnerHTML={{ __html: ctaContent?.text }}
                    />
                      <p dangerouslySetInnerHTML={{ __html: ctaContent?.para }} />
                    <div className="d-flex justify-content-center mb-2">
                      <Link to="/contact-us">
                        <button className="button-85 mt-2">
                          {ctaContent.buttonLabel}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              )}

          
            </div>
          ))}
        </div>
      </div>
       {myQuicklinks}
    </>
  );
};

export default Demo;
