
import React from 'react';

import { Container, Row, Col, Card } from 'react-bootstrap';


import appleVisionPro from '../../assets/WebpImages/apple-vision-pro.webp';
import metaQuest2 from '../../assets/WebpImages/metaquest2.webp';
import metaQuest3 from '../../assets/WebpImages/metaquest3.webp';
import metaQuestPro from '../../assets/WebpImages/metaquestpro.webp';
import hpreverb from '../../assets/WebpImages/hp-reverb.webp';
import oculus from '../../assets/WebpImages/oculus-rift.webp';


const devices = [
  { image: appleVisionPro, name: 'Apple Vision Pro' },
  { image: metaQuest2, name: 'Meta Quest 2' },
  { image: metaQuest3, name: 'Meta Quest 3' },
  { image: metaQuestPro, name: 'Meta Quest Pro' },
  { image: hpreverb, name: 'hpreverb' },
  { image: oculus, name: 'Oculus Rift S' },
];

const DevicesComponent = () => {
  return (
    <div style={{backgroundColor:"var(--footer-bg)"}}>
    <Container className="text-center pb-3">
      <h2 className='top-para text-center pt-4'>
        Virtual Reality Devices We Support
      </h2>
      <Row>
        {devices.map((device, index) => (



          <Col key={index} xs={12} sm={6} md={4} lg={3} className="my-3">
            <Card className="shadow-sm border-0 ">
              <Card.Img variant="top" src={device.image} alt={device.name} style={{objectFit:"contain", height:"150px"  }} />
              <Card.Body className=" text-center">
                <Card.Title>{device.name}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
    </div>
  );
};

export default DevicesComponent;
