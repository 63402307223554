import React from "react";
import Layout from "../../layout/Layouts";
import img1 from "../../../assets/WebpImages/boost-website-traffic.webp";
import { Link } from "react-router-dom";
import Card from '../Card'
import logo from "../../../assets/WebpImages/bytedark.webp";
//import { LazyLoadImage } from "react-lazy-load-image-component";

const BoostAhmedabadWebsite = () => {
  const data = {
    tag: "Boost Ahmedabad Website Traffic",
    description:
      "Struggling with low website traffic in Ahmedabad? ByteFaze Web Solutions can help! Our expert web design & SEO services increase traffic, leads & conversions. Get a free quote today!",
    keywords:
      "Increase website traffic Ahmedabad, SEO for Ahmedabad businesses, Local SEO Ahmedabad, Ahmedabad website traffic strategies, Content marketing for Ahmedabad businesses, Digital marketing Ahmedabad, Ahmedabad web design company, Ahmedabad SEO company",
    img: img1,
    altTag: "Boost Ahmedabad Website Traffic", //image Alt tag
    label: "Boost Ahmedabad Website Traffic",
    title:
      "Boost Ahmedabad Website Traffic with ByteFaze - Web Designing Company",
    PageTitle: "Stop Traffic Woes! Boost Your Ahmedabad Website with ByteFaze",
    date: "14 May 2024",
    desc: ( <p className="lead fw-bold">
        Conquer low website traffic with our award-winning responsive web design
        and development. We prioritize user experience, optimize for mobile, and
        craft strategic SEO plans to get your Ahmedabad business seen online.
      </p>
    ),
    img2: img1,
    points: (
      <>
        <div className="container w-75">
          <div className="row  item-align-center col-md-12 text-center">
            <table className="table border">
              <thead>
                <tr>
                  <th style={{ color: "var(--link-color)" }}>
                    Table of Contents
                  </th>
                </tr>
              </thead>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#why-website-traffic-is-low">
                    Why Website Traffic is Low ?
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#increase-website-traffic-ahmedabad">
                    Skyrocket Your Website Traffic in Ahmedabad
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#bytefaze-website-design-advantages">
                    The ByteFaze Advantage
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#unique-website-design-services">
                    Experience the Difference: Unique Website Design Solutions
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
        {/* point1 */}
        <div className="top-para pt-1">

   
        <p>
          Let ByteFaze create high-quality content and implement ongoing
          maintenance to keep your website thriving. Contact us today and{" "}
          <Link style={{ color: "blue" }} to="/contact-us">
            boost your Ahmedabad website traffic!
          </Link>
        </p>
        </div>  
        <p className="top-para">
          In the vibrant digital hub of Ahmedabad, your website is your
          storefront, portfolio, and lead generation engine – all rolled into
          one. But what happens when your website isn't attracting the visitors
          you deserve? Low website traffic can be incredibly frustrating,
          especially in a competitive city like Ahmedabad. Here at ByteFaze Web
          Solutions, a{" "}
          <b>best Ahmedabad-based <Link to="/website-designing/">web design and development company</Link></b>, we
          understand your struggle.
        </p>
        <h2
          style={{ color: "var(--link-color)" }}
          id="why-website-traffic-is-low"
        >
          Why is My Website Traffic Low in Ahmedabad?
        </h2>
        <p className="top-para">
          Several culprits can contribute to low website traffic. Here are some
          common offenders:
        </p>

        {/* point1 */}
        <ul style={{ color: "var(--link-color)" }}>
          <li>
            <b>Outdated Web Design:</b> Does your website resemble the early
            2000s? An antiquated design not only reflects poorly on your brand
            but also discourages visitors and harms search engine ranking.
          </li>
          <li className="pt-1">
            <b>Lack of Mobile-Friendliness: </b> Today, most web searches occur
            on mobile devices. If your website isn't optimized for mobile
            viewing, you're missing a significant portion of potential
            customers.
          </li>
          <li className="pt-1">
            <b>Poor User Experience (UX):</b> Is your website difficult to
            navigate, slow to load, or riddled with broken links? A frustrating
            UX drives visitors away and ensures they don't return.
          </li>
          <li className="pt-1">
            <b>Inadequate SEO (Search Engine Optimization):</b> Search engines
            are the primary gateway for online discovery. If your website lacks
            relevant keywords and technical SEO best practices, potential
            customers will struggle to find you.
          </li>
          <li className="pt-1">
            <b>Thin Content:</b> Content reigns supreme in the digital world.
            Thin, irrelevant, or outdated content fails to engage visitors or
            convince them to choose you.
          </li>
        </ul>
        {/* point3 */}
        <h2
          style={{ color: "var(--link-color)" }}
          id="increase-website-traffic-ahmedabad"
        >
          How ByteFaze Web Solutions Can Skyrocket Your Website Traffic in
          Ahmedabad
        </h2>
        <p className="top-para">
          At ByteFaze Web Solutions, we specialize in empowering Ahmedabad
          businesses to overcome these challenges and achieve their online
          aspirations. Here's how we can propel your website traffic and fuel
          your business growth:
        </p>
        <ol style={{ color: "var(--link-color)" }}>
          <li>
            <b> Award-Winning Responsive Website Design & Development</b>
            <ul>
              <li className="mt-1">
                <b>Modern & Responsive Design:</b> Our skilled designers craft
                stunning and user-friendly websites that adapt seamlessly across
                all devices (desktop, mobile, tablet).
              </li>
              <li className="mt-1">
                <b>Prioritizing User Experience (UX):</b> We prioritize
                intuitive navigation, clear calls to action, and rapid loading
                speeds to ensure a positive visitor experience on your website.
              </li>
              <li className="mt-1">
                <b>Custom Website Development: </b> From simple brochure
                websites to intricate e-commerce platforms, our expert
                developers bring your vision to life using cutting-edge
                technologies like React JS.
              </li>
            </ul>
          </li>
          <li className="mt-2">
            <b>Strategic SEO Optimization:</b>
            <ul>
              <li className="mt-1">
                <b>Keyword Research & Integration:</b> We conduct in-depth
                keyword research to identify the terms your target audience in
                Ahmedabad searches for and strategically integrate them into
                your website content.
              </li>
              <li className="mt-1">
                <b>Technical SEO Optimization:</b> We ensure your website is
                technically sound for search engines by optimizing page speed,
                website structure, and implementing other best practices.
              </li>
              <li className="mt-1">
                <b>Technical SEO Optimization:</b> As a local Ahmedabad company,
                we understand the importance of local SEO. We optimize your
                Google My Business (GMB) profile and leverage other local SEO
                strategies to increase your visibility to potential customers
                searching for services in Ahmedabad.
              </li>
            </ul>
          </li>
          <li className="mt-2">
            <b>Content Marketing Strategy Tailored for Ahmedabad:</b>
            <ul>
              <li className="mt-1">
                <b>High-Quality Content Creation: </b> We create engaging and
                informative blog posts, landing pages, and website content that
                attracts visitors, educates them about your offerings, and
                establishes you as a leader in your industry within Ahmedabad.
              </li>
              <li className="mt-1">
                <b>Content Optimization for Local SEO:</b> We optimize your
                content with relevant keywords and meta descriptions to improve
                its ranking in local search results.
              </li>
            </ul>
          </li>
          <li className="mt-2">
            <b>Ongoing Website Maintenance & Support: </b>
            <ul>
              <li className="mt-1">
                <b>Website Security: </b> We offer ongoing website maintenance
                services to ensure your website remains secure from cyberattacks
                and functions smoothly at all times.
              </li>
              <li className="mt-1">
                <b>Regular Updates:</b> We will keep your website updated with
                the latest plugins, themes, and security patches to maintain
                optimal performance
              </li>
              <li className="mt-1">
                <b>Performance Monitoring & Reporting:</b> We'll keep your
                website updated with the latest plugins, themes, and security
                patches to maintain optimal performance
              </li>
            </ul>
          </li>
        </ol>
        {/* point3*/}
        <h3
          style={{ color: "var(--link-color)" }}
          id="bytefaze-website-design-advantages"
        >
          The ByteFaze Advantage:
        </h3>
        <p className="top-para">
          At ByteFaze Web Solutions, we're not just about crafting websites;
          we're passionate about empowering Ahmedabad businesses to achieve
          online success. We take a data-driven approach to web design and
          development, focusing on measurable results like increased website
          traffic, lead generation, and improved conversion rates.
        </p>

        <div className="d-flex justify-content-center ">
          <Link to="/blog/web-designing-ahmedabad">
            <button className="button-85 mb-3 col-12 col-lg-12 mt-1">
              Also Read : Website Designing Ahmedabad
            </button>
          </Link>
        </div>
        {/* point4*/}
        <h3
          style={{ color: "var(--link-color)" }}
          id="unique-website-design-services"
        >
          Experience the Difference: Unique Website Design Solutions
        </h3>

        <ul style={{ color: "var(--link-color)" }}>
          <li className="pt-1">
            <b>Local Expertise:</b> We understand the unique needs of Ahmedabad
            businesses and have a proven track record of success in the
            Ahmedabad city.
          </li>
          <li className="pt-1">
            <b>Transparent Communication: </b> We believe in clear and open
            communication throughout the entire project. You'll always be kept
            in the loop about the progress of your website.
          </li>
          <li className="pt-1">
            <b>Dedicated Account Manager: </b> You'll have a dedicated account
            manager who understands your business goals and works
            collaboratively with you to achieve them.
          </li>
        </ul>
      </>
    ),
    lastp:
      "**Ready to transform your website from a ghost town to a thriving hub for potential customers in Ahmedabad? Contact ByteFaze Web Solutions today for a free",

    // "In today's digital world, having a strong online presence is essential for any local business. A well-designed website acts as your digital storefront, attracting new customers and keeping existing ones engaged. Here's a breakdown of website design services specifically tailored for local businesses:"

    Fieldtags: (
      <>
        <div className="container mt-1">
          <div className="row">
            <div className="col">
              <div className="d-flex flex-wrap">
                <Link to="/" className="btn btn-outline-secondary m-1">
                  Boost Website Traffic in Ahmedabad
                </Link>
                <Link
                  to="/website-designing"
                  className="btn btn-outline-secondary m-1"
                >
                  Increase Website Traffic
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Web Design and Development Company
                </Link>
                <Link
                  to="/responsive-website"
                  className="btn btn-outline-secondary m-1"
                >
                  Responsive Website Development
                </Link>
                <Link
                  to="/digital-marketing"
                  className="btn btn-outline-secondary m-1"
                >
                  Low Website Traffic Solutions
                </Link>
                <Link
                  to="/website-designing"
                  className="btn btn-outline-secondary m-1"
                >
                  Content Optimization Services
                </Link>
                <Link
                  to="/ecommerce-website"
                  className="btn btn-outline-secondary m-1"
                >
                  SEO (Search Engine Optimization)
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Award Winning Website Designer
                </Link>
                <Link
                  to="/website-designing"
                  className="btn btn-outline-secondary m-1"
                >
                  Strategic SEO Optimization Services
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Content Marketing Ahmedabad
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Website Maintenance & Support Services Ahmedabad
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  };

  return (
    <Layout
      title={data?.title}
      keywords={data?.keywords}
      description={data?.description}
      canonical={"https://www.bytefaze.com/blog/boost-website-traffic-ahmedabad/"}
      ldName={"Boost Ahmedabad Website Traffic"}
    ldImage={img1}
    serviceType={"SEO Services"}
    >
      {/* <!-- Featured 1 - Bootstrap Brain Component --> */}
      <section className="py-3 py-md-5 py-xl-8 ">
        <div className="container">
          <div
            className="row gy-3 gy-lg-0 align-items-lg-center justify-content-lg-between"
            style={{ color: "var(--link-color)" }}
          >
            <div className="col-12 col-lg-7 order-1 order-lg-0">
              <div className="entry-header mb-3">
                <ul className="entry-meta list-unstyled d-flex mb-3">
                  <li>
                    <a
                      className="d-inline-flex px-2 py-1 link-accent text-accent-emphasis bg-accent-subtle border border-accent-subtle rounded-2 text-decoration-none fs-7"
                      href="#"
                    >
                      {data?.label}
                    </a>
                  </li>
                </ul>
                <h1 className="display-3 fw-bold mb-4">{data?.PageTitle}</h1>
              </div>
              <div className="entry-footer">
                <ul className="entry-meta list-unstyled d-flex align-items-center m-0">
                  <li>
                    <a
                      className="fs-7  text-decoration-none d-flex align-items-center"
                      href="#"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fillRule="currentColor"
                        className="bi bi-calendar3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                        <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                      </svg>
                      <span
                        className="ms-2 fs-7"
                        style={{ color: "var(--link-color)" }}
                      >
                        {data?.date}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <a
                className="d-block bsb-hover-image overflow-hidden rounded"
                href="#"
              >
                <img 
                  className="img-fluid bsb-scale bsb-hover-scale-up"
                  src={img1}
                  alt="Boost Website Traffic with top Website Designing Company in Ahmedabad"
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Main --> */}
      <main id="main">
        {/* <!-- Entry Content Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10">
          <div className="container">
            <div className="row justify-content-md-center gy-3 gy-xl-4">
              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
                {data?.desc}
                {/* <p className="lead fw-bold">{data?.desc} <a href="#">emotional support</a> in a way that is healthy for both you and your partner.</p> */}
              </div>

              <div className="col-12 text-center">
                <img 
                  className="img-fluid my-4 rounded"
                  src={img1}
                  alt="SEO Company in Ahmedabad"
                />
              </div>

              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
                {data?.points}
                <p>{data?.points2}</p>
                {/* <h2 style={{color:"var(--link-color)"}}>Offer Physical Affection</h2> */}
                {/* <img  className="img-fluid mt-3 mb-5 rounded" src={img1} alt=""/> */}
                <p>{data?.lastp}</p>
                {/* <p>Different people like different levels of physical affection at different times. If you are feeling unsure, communicate with your partner so you can learn what they are open to receiving and can provide it accordingly.</p> */}
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Entry Meta --> */}
        <div className="pb-3 pb-md-5 pb-xl-10">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
                <span
                  className="me-1 fw-bold"
                  style={{ color: "var(--link-color)" }}
                >
                  {" "}
                  Tags:
                </span>
                <ul className="entry-meta list-unstyled d-inline-flex align-items-center justify-content-center mb-3">
                  {data.Fieldtags}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Post Pagination 2 - Bootstrap Brain Component --> */}
        <section className="bsb-post-pagination-2  py-0 py-md-0 py-xl-0 bsb-section-py-xxl-1X">
          <div className="container overflow-hidden">
            <div className="row justify-content-lg-center">
              <div className="col-12 col-lg-10 col-xl-8 col-xxl-7"></div>
            </div>
          </div>
        </section>

        {/* <!-- Author 2 - Bootstrap Brain Component --> */}
        <section className="bsb-author-2 py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1X">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-8 col-xxl-7">
                <div className=" border-light-subtle p-4 border">
                  <div className="row gy-3 align-items-center">
                    <div className="col-md-4">
                      <a
                        href="#"
                        className="bsb-hover-image d-block rounded overflow-hidden"
                      >
                        <img 
                          className="img-fluid author-avatar bsb-scale bsb-hover-scale-up"
                          src={logo}
                          alt="Website Designing Company"
                        />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h6
                          className="card-subtitle mb-2  "
                          style={{ color: "var(--link-color)" }}
                        >
                          Article written by
                        </h6>
                        <h3 className="card-title mb-2">
                          <a
                            className="card-link  text-decoration-none"
                            href="#"
                          >
                            Bytefaze
                          </a>
                        </h3>
                        <p className="card-text mb-3">
                          We are skilled in all aspects of web design, from
                          planning and development to design and testing. I also
                          have experience in web development technologies.
                        </p>
                        <ul className="bsb-social-media nav m-0">
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              to="https://www.facebook.com/bytefazewebsolutionsahmedabad/"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-facebook"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                              </svg>
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                  <a className="nav-link " href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fillRule="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                    </svg>
                  </a>
                </li> */}
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              to="https://x.com/Bytefaze"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-twitter"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                              </svg>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              href="https://www.linkedin.com/company/bytefaze/"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-linkedin"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                              </svg>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Blog 5 - Bootstrap Brain Component --> */}
        <section className="bsb-blog-5 py-3 py-md-5 py-xl-8 bsb-section-py-xxl-1X ">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12">
                <h2
                  className="h4 mb-4 mb-md-5"
                  style={{ color: "var(--link-color)" }}
                >
                  Related Articles
                </h2>
              </div>
            </div>
          </div>

          {/* <div className="container overflow-hidden px-4"> */}
          {/* <div className="row gy-4 gy-md-5 gx-xl-6 gy-xl-6 gx-xxl-9 gy-xxl-9"> */}
          <Card />
          {/* </div> */}
          {/* </div> */}
        </section>

        {/* <!-- Newsletter 1 - Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1 bg-accent">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-9 col-lg-8 col-xl-8 col-xxl-7">
                <h2
                  className="display-4 fw-bold mb-4 mb-md-5 mb-xxl-6 text-center text-accent-emphasis"
                  style={{ color: "var(--link-color)" }}
                >
                  Sign up for our newsletter and never miss a thing.
                </h2>
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
                <form className="row gy-3 gy-lg-0 gx-lg-2 justify-content-center">
                  <div className="col-12 col-lg-8">
                    <label
                      htmlFor="email-newsletter-component"
                      className="visually-hidden"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control bsb-form-control-3xl"
                      id="email-newsletter-component"
                      placeholder="Email Address"
                      aria-label="email-newsletter-component"
                      aria-describedby="email-newsletter-help"
                      required
                    />
                    <div
                      id="email-newsletter-help"
                      className="form-text text-center text-lg-start"
                    >
                      We'll never share your email with anyone else.
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 text-center text-lg-start">
                    <button type="submit" className="btn border bsb-btn-3xl">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default BoostAhmedabadWebsite;
