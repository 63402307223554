import React from "react";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import { BsCheckCircle } from "react-icons/bs";
import arvrcontact from "../../assets/Images/arvrcontact.json";
import { MdArrowForwardIos } from "react-icons/md";

const ArvrContact = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: arvrcontact,
  };
  return (
    <div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 order-2 order-lg-1 mt-2">
            <div>
              <h1 className=""style={{color:"var(--link-color)"}}>
                Let's start a conversation about your Business Goals!
              </h1>
              <div className="d-flex align-items-center mb-2 top-para">
                <BsCheckCircle className="me-2" />
                <span>Streamlined workflows</span>
              </div>
              <div className="d-flex align-items-center mb-2 top-para">
                <BsCheckCircle className="me-2" />
                <span>Robust cybersecurity measures</span>
              </div>
              <div className="d-flex align-items-center top-para">
                <BsCheckCircle className="me-2" />
                <span>Future-proof technology solutions</span>
              </div>
              <div className="mt-4 text-center d-flex flex-column flex-lg-row align-items-center">
                <Link
                  to="/contact-us"
                  className="button-33 mb-3 mb-lg-0 col-12 col-lg-4"
                >
                  Contact Our Experts <MdArrowForwardIos />


                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center">
            <div className="lottie-container ">
              <Lottie options={defaultOptions} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArvrContact;
