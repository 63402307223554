import React, { useState } from "react";
import logobytefaze from "../../assets/WebpImages/bytedark.webp";
import axios from "axios";
import { useParams } from "react-router-dom";
//import { LazyLoadImage } from "react-lazy-load-image-component";
const Newpass = () => {
  const [password, setPassword] = useState("");
  const params = useParams();
  console.log(params)
    const param = JSON.stringify(params?.token)
        console.log(param)

  const handelsubmit = async (e) => {
    e.preventDefault();
    try {
        const param = JSON.stringify(params?.token)
      const res = await axios.put(
        `https://bytefaze-backend.onrender.com/api/v1/user/reset-password/${param}`,
        { password },
        {
          "accept": "application/json",
        }
      );

      console.log(res, "dmfvmefjm");
    } catch (error) {
      console.log(error, "erro");
    }
  };
  return (
    <div>
      <section className="bg-light py-3 py-md-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
              <div className="card border border-light-subtle rounded-3 shadow-sm">
                <div className="card-body p-3 p-md-4 p-xl-5">
                  <div className="text-center mb-3">
                    <a href="/#">
                      <img 
                        src={logobytefaze}
                        alt="BootstrapBrain Logo"
                        width="200"
                        height="140"
                      />
                    </a>
                  </div>
                  <h2 className="fs-6 fw-normal text-center text-secondary mb-4">
                    Reset Password
                  </h2>
                  <form action="/#">
                    <div className="row gy-2 overflow-hidden">
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            id="password"
                            placeholder="Enter New Password"
                            value={password || ""}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          <label for="password" className="form-label">
                            Enter New Password
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-grid my-3">
                          <button
                            type="button "
                            className="btn border"
                            onClick={handelsubmit}
                          >
                            Reset Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Newpass;
