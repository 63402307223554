
import React from "react";

import Demo from "./Demo";
import QuickLinks from "./QuickLinks";
import Layouts from "../layout/Layouts";
import webdesign from "../../assets/WebpImages/webdesign.webp";
import res2 from "../../assets/WebpImages/res2.webp";
import web3 from "../../assets/WebpImages/web32.webp";
import ecom1 from "../../assets/WebpImages/ecom1.webp";
import res from "../../assets/WebpImages/responsive-website.webp";

const WebDesignHouston = () => {
  const heroSection = {
    backgroundImage: "https://via.placeholder.com/1600x400",
    heading: "Web Design in Houston ",
    paragraph:
      "Transform Your Digital Presence with Bytefaze's Premier Web Design & development services in Houston",
  };
  const contentArray = [
    {
      image: webdesign,
      heading: "Custom Website Design",
      text: "Beyond mere aesthetics, our custom website design services are crafted to captivate your audience and drive meaningful engagement. We meticulously analyze your brand's essence, target market, and business objectives to create a website that is not only visually stunning but also strategically aligned with your goals. By incorporating intuitive navigation, compelling storytelling, and optimized user flows, we ensure that your website becomes a powerful tool for generating leads, building brand awareness, and driving conversions.",
    },
    {
        image: res2,
        heading: "Website Redesign",
        text: "A website redesign is more than just a cosmetic overhaul. It's an opportunity to breathe new life into your online presence and elevate your brand. Our expert team will meticulously analyze your existing website's performance, identify areas for improvement, and create a redesigned version that is not only visually appealing but also optimized for search engines and user experience. We'll focus on enhancing site speed, improving navigation, and incorporating modern design elements to ensure your website stands out in today's competitive digital landscape.",
      },
      {
        image: web3,
        heading: "Mobile Application Development",
        text: "In today's mobile-centric world, a well-designed mobile app can be a game-changer for your business. Our mobile app development services focus on creating native apps that offer exceptional performance, intuitive user interfaces, and seamless integration with your existing systems. Whether you need an app for internal use or to engage with your customers, we'll leverage the latest technologies and industry best practices to deliver a solution that meets your specific needs and exceeds your expectations.",
      },
      {
          image: res,
          heading: "Web App Development ",
          text: "Web applications are essential for businesses looking to streamline processes, improve efficiency, and enhance collaboration. Our web app development services involve creating custom solutions tailored to your unique requirements. We'll work closely with you to understand your business goals and challenges, and then design and develop web apps that are scalable, secure, and easy to use. From CRM systems to project management tools, our web apps can help you optimize your operations and gain a competitive edge.",
        },
        {
          image: ecom1,
          heading: "Ecommerce Web Design",
          text: "In the world of online retail, your ecommerce website is your storefront. Our ecommerce web design services are designed to create a seamless and engaging shopping experience for your customers. We'll work closely with you to understand your unique business requirements, integrate essential features like product catalogs, shopping carts, and secure payment gateways, and optimize your website for mobile devices to cater to the growing trend of m-commerce. By providing a user-friendly and visually appealing shopping environment, we'll help you maximize conversions and drive sales.",
        },
    
    // more content objects
  ];

  const ctaContent = {
    text: "Ready to take your Houston business to the next level?",
    para : "Contact Bytefaze Web Solutions today to discuss your project and explore how we can help you achieve your online goals.",
    buttonLabel: "Request A Meeting",
  };

  const ctaIndex = 0;

  const textSections = [
    {
        index: 0,
        heading: "Website Designing Comapny in Houston",
        text: "Looking for hire a best website designer in Houston, Texas. We are a leading web design company from India specializing in creating stunning, user-friendly websites that drive results. Our team of experts understands the city's unique culture and business landscape, delivering tailored solutions to help you stand out in the competitive Houston market. We combine creative website design with technical expertise to ensure your website is visually appealing, easy to navigate, and optimized for search engines. Our basic web designing packages are affordable to every one included high quality support and website maintenance.  Whether you're a small business or a large corporation, we have the skills to help you achieve your online goals. ",
      },
    {
      index: 0,
      heading: "Why Choose Us?",
      text: "<ul><li><b>Affordable Packages:</b>Our competitive pricing ensures that you get the most value for your investment.</li><li><b>Expert Team: </b> Expert Team: </li><li><b>Customized Solutions: </b> Customized Solutions: </li><li><b>Customized Solutions: </b> Customized Solutions: </li><li><b>Excellent Customer Support: </b> Our dedicated support team is always available to assist you.</li></ul>",
    },
    
    // Add as many text sections as needed
  ];

  const linksData = [
    {
      text: "Website Redesign Company in Boston",
      url: "/web-design-boston",
    },
    { text: "Custom Web Design Services in San Francisco", url: "/website-design-in-san-francisco" },
    { text: "SEO-Freindly Website Designing Agency in London", url: "/web-design-development-in-london" },
    { text: "Mobile Application Development in Paris", url: "/web-design-paris" },
    { text: "Affordable Web Designing Solutions in Newyork ", url: "/website-design-development-in-newyork" },
    
    // Add more links as needed
  ];

  const searchKeywordsData = [
    "Best Website Designer in Houston",
    "Web Development Company in Asia",
    "Best Web Designing Services in Houston","Professional Website Development Houston","Creative Website Developer in Houston","SEO-friendly Website Design in Houston", "SEO-friendly Website Design Agency in Houston","Website Maintainace in Houston"
    // Add more keywords as needed
  ];

  return (

    <Layouts
      title={"Web Design Company in Houston, Texas, Affordable & Creative Websites"}
      keywords={
        "Web Design in Huston, Website Designer in Texas, Affordable Web Design Services, Creative Website Designer, Web Development Company in India, Website Experts"
      }
      description={
        "Want to hire best website designer in Houston, TX, Bytefaze is best web design agency from India offer a complete web design & development services at affordable charges"
      }
      canonical={"https://www.bytefaze.com/web-design-houston"}
      ldName={"Website Design & Development in Houston"}
      serviceType={"Web Designing & Development Service in Houston"}
    >

    

      <Demo
        contentArray={contentArray}
        textSections={textSections}
        ctaContent={ctaContent}
        ctaIndex={ctaIndex}
        heroSection={heroSection}
        myQuicklinks={<QuickLinks links={linksData} searchKeywords={searchKeywordsData} />}
      />
  </Layouts>


  );
};

export default WebDesignHouston;
