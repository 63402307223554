import React, { useState } from "react";
import Layouts from "../layout/Layouts";
import { useNavigate } from "react-router-dom";
import NewSideNav from "./NewSideNav";
import webcalc from "../../assets/pricing/webcalculator.svg"

const PriceCalculator = () => {
    const [calculation,setCalculation]=useState({
        value1:"",
        value2:"",
        value3:"",
        value4:"",
        value5:"",
    })
    // const [price, setPrice] = useState(0);
    const navigate = useNavigate();


    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Check if any required fields are empty
      if (!calculation.value1 || !calculation.value2 || !calculation.value3 || !calculation.value4 || !calculation.value5) {
          alert('Please fill in all required fields.');
          return; // Stop further execution if validation fails
      }
  
      let newPrice = 0; // Initialize a variable to store the calculated price
  
      if (calculation.value1 === "Yes, Looking for fresh design") {
          newPrice += 8500;
      } else if (calculation.value1 === "Yes, Looking for fresh design with more features") {
          newPrice += 9500;
      } else if (calculation.value1 === "No, I don't have an existing website") {
          newPrice += 11000;
      }
  
      if (calculation.value2 === "Static Website") {
          newPrice += 0;
      } else if (calculation.value2 === "Static Website with more than 10 pages") {
          newPrice += 2000;
      } else if (calculation.value2 === "Dynamic Website with 10-15 Static pages") {
          newPrice += 15000;
      } else if (calculation.value2 === "Ecommerce Website") {
          newPrice += 30000;
      }
  
      // Assuming no additional cost based on value3 and value4
      // You can adjust the logic if there's actually a cost associated with these values
      if (calculation.value3 === "Yes") {
          newPrice += 0;
      } else if (calculation.value3 === "No") {
          newPrice += 0;
      }
  
      if (calculation.value4 === "Yes") {
          newPrice += 0;
      } else if (calculation.value4 === "No") {
          newPrice += 0;
      }
  
      if (calculation.value5 === "Yes, Approximately 5 pages") {
          newPrice += 2000;
      } else if (calculation.value5 === "No need for content writing services") {
          newPrice += 0;
      }
  
      // Ensure the minimum price is ₹10,000
      if (newPrice < 10000) {
          newPrice = 10000;
      }
  
      navigate("/price-calculator-result", { state: { price: newPrice } });
      // setPrice(newPrice); // Set the final calculated price once
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCalculation((prevState) => ({
      ...prevState,
      [name]: value, // Update the specific value by its name
    }));
  };

    
  return (
    <Layouts
    
    title={"Free Online Web Design Cost Calculator | Get Instant Website Designing Price"}
      keywords={
        "Website Design Cost Calculator, Free Website Design Estimate, Estimated Web Design Price, Calculate Web Design Cost in Ahmedabad, "
      }
      description={
        "Check the estimate of your specific website project using our free online web design cost calculator. Know the website design & developement price instantly."
      }
      canonical={"https://www.bytefaze.com/website-design-cost-calculator"}
      ldName={"Website Design Cost Calculator"}
      ldImage={webcalc}
      serviceType={"Web Design & Development Pricing"}
    >
      <div className="col-12 mb-4 bg-light p-4 text-center about_bg">
        <h1 className=" fw-bold">Website Design Cost Calculator</h1>
      </div>

      <section className="container" >
        <div className="row justify-content-around">
          {/* Left Column */}
          <div className="col-12 col-lg-6 mb-4" style={{ background: "var(--footer-bg)" }}>
            <div className="p-4 shadow-sm">
              <p>
                At ByteFaze, we are always asked for general ballpark figures for web design quotes. "How much does a website cost in India?" is a question our customer support team answers on a daily basis. Websites come in all shapes and sizes, and levels of complexity, hence the pricing may vary from website to website. Our website price calculator will give you an estimate of the cost of your website.
              </p>

              <form action="submit">
                <div className="mb-3">
                  <label className="form-label">Do you have an existing Website? <span className="text-danger">*</span></label>
                  <select className="form-select no-blur" name="value1" value={calculation.value1} onChange={handleInputChange}>
                  <option value="">-- Select Website Type --</option>

                    <option value={'Yes, Looking for fresh design'}>Yes, Looking for fresh design</option>
                    <option value={'Yes, Looking for fresh design woth more features'}>Yes, Looking for fresh design woth more features</option>
                    <option value={`No, I don't have an existing website`}>No, I don't have an existing website</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label className="form-label">Website Type? <span className="text-danger">*</span></label>
                                   <select className="form-select no-blur" name="value2" value={calculation.value2} onChange={handleInputChange}>
                                   <option value="">-- Select --</option>

                    <option value={'Static Website'}>Static Website</option>
                    <option value={'Static Website with more than 10 pages'}>Static Website with more than 10 pages</option>
                    <option value={'Dynamic Website with 10-15 Static pages'}>Dynamic Website with 10-15 Static pages</option>
                    <option value={'Ecommerce Website'}>Ecommerce Website</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label className="form-label">Do you have a domain? <span className="text-danger">*</span></label>
                                   <select className="form-select no-blur" name="value3" value={calculation.value3} onChange={handleInputChange}>
                                   <option value="">-- Select --</option>

                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label className="form-label">Do you have web hosting? <span className="text-danger">*</span></label>
                                   <select className="form-select no-blur" name="value4" value={calculation.value4} onChange={handleInputChange}>
                                   <option value="">-- Select --</option>

                    <option value={'Yes'}>Yes</option>
                    <option value={'No'}>No</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label className="form-label">Do you need content writing services? <span className="text-danger">*</span></label>
                                   <select className="form-select no-blur" name="value5" value={calculation.value5} onChange={handleInputChange}>
                                   <option value="">-- Select --</option>
                    <option value={'Yes, Approximately 5 pages'}>Yes, Approximately 5 pages</option>
                    <option value={'No need for content writing services'}>No need for content writing services</option>
                  </select>
                </div>

                {/* <div className="mb-3">
                  <label className="form-label">How many email addresses do you want? *</label>
                                   <select className="form-select no-blur" value={calculation.value1} onChange={handleInputChange}>

                    <option>Less than 10</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label className="form-label">Enter Security Code *</label>
                  <input
                    type="text"
                    className="form-control no-blur"
                    placeholder="B2OZYT"
                  />
                </div>

                <div className="mb-3">
                  <label className="form-label">Enter Captcha *</label>
                  <input
                    type="text"
                    className="form-control no-blur"
                    placeholder="5LAQE7"
                  />
                </div> */}

                <button type="submit" onClick={handleSubmit}  className="button-33 px-5">Submit</button>
              </form>

              <h3 className="mt-4 top-para">How much does a website actually cost?</h3>
              <p>
                One of the most common questions I get from clients is "How much does a website cost?" It's a great question and one that can be answered in many ways. A website cost estimate really depends on what you're looking for and what you want to do with it. For example, if you're looking for a site that is simple and straightforward, then a static website will be great and would cost less. If you're looking for a more sophisticated site with complex features like a shopping cart, membership area, or blog, then you'll need to use a dynamic website and these sites are more expensive, but they are also much more powerful.
              </p>
              <p>
                The cost of a website really depends on what you want to do with it. The simplest sites are very affordable and are a great way to get started. More sophisticated sites require more expertise and require a higher level of investment. You can use our website cost calculator to get an idea about the cost of your website.
              </p>

              <h3 className="mt-4 top-para">How much do we charge to build a website?</h3>
              <p>
                Do you want to create a website? We offer to build your website for you. Our website designers are experts and can create a website for any business, organization, or individual. Our team of web designers will create a site that will be responsive to all mobile devices, including tablets and smartphones. You can also upload images and videos and make your site interactive. At just a fraction of the cost, you can have a professionally designed website that will attract new customers and keep them coming back. Use our website development cost calculator to know about the cost of a website.
              </p>

              <h3 className="mt-4 top-para">What you get with our website building services:</h3>
              <ul className="top-para"> 
                <li>Responsive Design</li>
                <li>Website Builder</li>
                <li>Video Integration</li>
                <li>Image Integration</li>
                <li>Social Media Integration</li>
                <li>Lead Capture</li>
                <li>Customer Management</li>
                <li>Email Marketing</li>
                <li>SMS Marketing</li>
                <li>Custom Design</li>
                <li>Content Management System</li>
                <li>SEO Optimization</li>
                <li>Dynamic Pages</li>
                <li>Blog</li>
                <li>Blog Manager</li>
                <li>Mailchimp Integration</li>
                <li>A/B Testing</li>
                <li>Onsite Chat</li>
                <li>Mobile Friendly</li>
                <li>Backup and Restore</li>
                <li>Webmaster Tools</li>
                <li>Website Management</li>
                <li>Real Time Stats</li>
                <li>Site Map</li>
              </ul>

              <h3 className="mt-4 top-para">How much does it cost to make a website for a small business?</h3>
              <p>
                For small businesses with limited budgets, a great option is to use a website building service from ByteFaze. We give our clients affordable options. With us, you can easily get a professional website for your business. We have in-depth knowledge of each industry and we’ll give you a website made uniquely for your business.
              </p>
              <p>
                Wondering how much it costs to make a website for a small business? The costs for a website vary depending on the size and complexity of the project.
              </p>

              <h3 className="mt-4 top-para">What is the average cost for a website?</h3>
              <p>
                If you're looking for a reliable and cost-effective website, look no further than ByteFaze. We are the best at what we do, and our work speaks for itself. With over 12 years of experience, we have helped countless businesses and organizations across the globe with their website needs. With a team of skilled and talented professionals, we have created some of the most well-known and successful websites in the world. We're so confident in our work that we offer a full-service guarantee to back up our work. With ByteFaze, you can get a professional website that's both beautiful and effective for a fraction of the cost.
              </p>

              <h3 className="mt-4 top-para">What is a fair price to build a website?</h3>
              <p>
                A fair price to build a website is hard to say as it depends on many factors. For example, how many pages do you want on your website? How many different pages of content do you want? How many pictures? How many videos?
              </p>
              <p>
                Since a website is a collection of pages on the internet that contain information about a company, organization, or person. Websites are created by companies and organizations to market their products and services to customers and potential customers. The company website may have one or more sections: an about us section, a products and services section, a contact us section, and a section for the company's history.
              </p>
              <p>
                Website designers are in charge of making the website aesthetically pleasing and functional. Designers usually have experience in graphic design, computer programming, and web design. A website designer is not just responsible for the look of the website but also for the content. The content is written by the website's webmaster, who is usually a computer programmer or web designer. The webmaster researches the topic and creates content that is informative and appealing to readers.
              </p>
              <p>
                Thus we can only give a fair price to build a website after going through all the factors and resources we would need to build it.
              </p>

              <h3 className="mt-4 top-para">Website Development Cost Calculator</h3>
              <p>
                As a leading website design company in India, ByteFaze is very transparent when it comes to providing quotations to potential website seekers. We always believe that our clients should understand the costing factors and take an educated decision while choosing a website design company. There are many website price calculator scripts available on the internet; however, none of them provides you the flexibility where you can make an aware choice. We have designed this website price calculator by keeping small and medium scale industry in mind and used our decade-long experience of costing analysis. This tool gives you a ballpark figure for budget planning. You can talk to our sales experts about the extra requirements, breakup about the cost, and added services like SEO, Digital marketing, and Social media promotions. For more information, you can contact us anytime.
              </p>
            </div>
          </div>

          {/* Right Column (Website Calculator) */}
          <div className="col-12 col-lg-5 mb-4" >
            <NewSideNav />
          </div>

        </div>
      </section>
    </Layouts>
  );
};

export default PriceCalculator;
