import React from "react";
import Layout from "../../layout/Layouts";
import img1 from "../../../assets/WebpImages/add-me-to-search.webp";
import { Link } from "react-router-dom";
import Card from '../Card'
import logo from "../../../assets/WebpImages/bytedark.webp";
//import { LazyLoadImage } from "react-lazy-load-image-component";

const Addmetogooglesearch = () => {
  const data = {
    tag: "Add Me to Search",
    description:
      "Unlock the power of Google People Cards to boost your personal brand and SEO. Learn how to create and optimize your People Card profile for maximum visibility and engagement. Discover tips and strategies to stand out in search results and connect with your audience.",
    keywords:
      "Google People Cards, SEO, personal branding, online visibility, social media, networking, digital marketing, personal branding strategy, professional profile, online reputation management",
    img: img1,
    altTag: "Add Me to Search Feature or Google People Cards for SEO", //image Alt tag
    label: "Add Me to Search",
    title: "Add Me to Search : Leveraging Google People Cards for SEO",
    PageTitle: "Add Me to Search: Leveraging Google People Cards for SEO",
    date: "21 AUG 2024",
    desc: (
      <p className="lead fw-bold">
        In today’s digital age, having a strong online presence is essential for professionals, freelancers, and businesses alike. Google, recognizing this need, introduced a feature known as <b>"Add Me to Search"</b> or Google People Cards. This tool allows individuals to create a personalized digital business card that appears directly in search results, making it easier for others to find relevant information about them. For anyone looking to enhance their online visibility and SEO strategy, Google People Cards offer a unique opportunity to stand out in a crowded digital space.
      </p>
    ),
    img2: img1,
    points: (
      <>
        <div className="container w-75">
          <div className="row  item-align-center col-md-12 text-center">
            <table className="table border">
              <thead>
                <tr>
                  <th style={{ color: "var(--link-color)" }}>
                    Table of Contents
                  </th>
                </tr>
              </thead>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#what-are-google-people-cards">
                  What Are Google People Cards?
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#why-people-cards-matter-for-seo">
                  Why Google People Cards Matter for SEO
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#how-to-create-google-people-card">
                  How to Create and Optimize Your Google People Card
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#google-people-cards-local-seo">
                  Leveraging Google People Cards for Local SEO
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#role-eeat-in-google-people-cards">
                  The Role of EEAT in Google People Cards

                  </a>
                </td>
              </tr>
              
            </table>
          </div>
        </div>
        {/* point1 */}
        <div className="top-para">
        <h2
          style={{ color: "var(--link-color)" }}
          id="what-are-google-people-cards"
        >
         What Are Google People Cards?
        </h2>
        <p>
        Google People Cards are essentially virtual business cards that appear in Google search results when someone searches for your name. These cards allow you to showcase key information such as your name, occupation, location, and links to your social media profiles or website. The feature was initially launched in India in 2020 and has since been rolled out to other regions, offering users a simple yet effective way to manage their online presence.
        </p>
        <p>For professionals and businesses, Google People Cards can be particularly valuable. They provide a way to control the narrative around your online identity by ensuring that accurate and up-to-date information appears in search results. This can be especially beneficial for individuals with common names or those who are trying to establish themselves in competitive industries.</p>

        {/* point2 */}

             <h2
          style={{ color: "var(--link-color)" }}
          id="why-people-cards-matter-for-seo"
        >
          Why Google People Cards Matter for SEO
        </h2>
        <p>
        SEO, or Search Engine Optimization, is all about improving your website’s visibility in search engine results. By optimizing your content and online presence, you can rank higher for relevant searches, driving more traffic to your website or social media profiles. Google People Cards can play a crucial role in this process by helping you dominate the search results for your name or brand.
        </p>
        {/* point3*/}
        <h4
          style={{ color: "var(--link-color)" }}
        >
Enhancing Personal Branding        </h4>
        <p>
        One of the primary benefits of Google People Cards is the ability to enhance your personal branding. Personal branding is the practice of marketing yourself and your career as a brand. By creating a People Card, you can ensure that when someone searches for your name, they find a professional and polished representation of who you are.
        </p>
        <p>To maximize the SEO benefits of your People Card, it’s important to carefully select the information you include. For example, if you are a web designer in Ahmedabad, including this keyword in your People Card can help you rank higher in local searches related to web design. Additionally, linking to your website or portfolio can drive traffic to these platforms, further boosting your online visibility.</p>
        {/* point4*/}
        <h4
          style={{ color: "var(--link-color)" }}
          
        >
Dominating Branded Search Results        </h4>
        <p className="top-para">
        Branded search results refer to the results that appear when someone searches for a specific brand or individual’s name. For those with common names or those who share their name with others in the same industry, standing out in branded search results can be challenging. Google People Cards provide a solution to this problem by allowing you to take control of your search presence.
        </p>
       <p>By optimizing your People Card with relevant keywords and information, you can ensure that your card appears at the top of the search results for your name. This not only increases your visibility but also helps to establish your authority in your field.</p>
        {/* point5*/}
        <h2
          style={{ color: "var(--link-color)" }}
          id="how-to-create-google-people-card"
        >
How to Create and Optimize Your Google People Card        </h2>
        <p>
        Creating a Google People Card is a straightforward process. Here’s a step-by-step guide to help you get started:
        </p>
        <ol>
          <li>
            <b>Search for Yourself</b> Begin by searching for your name on Google. If you’re eligible to create a People Card, you’ll see an option to “Add yourself to Google Search.”
          </li>
          <li>
            <b>Fill Out Your Information:</b> Once you’ve clicked the link, you’ll be prompted to fill out a form with your personal information. This includes your name, occupation, location, and a brief bio. You can also add links to your website, social media profiles, and other relevant online platforms.
          </li>
          <li>
            <b>Add a Profile Picture:</b> Your profile picture is an important part of your People Card, as it helps to personalize your card and make it more visually appealing. Choose a professional-looking image that represents you well.
          </li>
          <li>
            <b>Optimize for SEO:</b> To maximize the SEO benefits of your People Card, be sure to include relevant keywords in your bio and other sections. For example, if you’re a digital marketing consultant, include this term in your bio to increase the likelihood of appearing in related searches.
          </li>
          <li><b>Review and Publish:</b> Once you’ve filled out all the required information, take a moment to review your card and make any necessary edits. When you’re satisfied with your card, click “Save” to publish it</li>
        </ol>
        {/* point6*/}
        <h2 style={{ color: "var(--link-color)" }} id="google-people-cards-local-seo">
        Leveraging Google People Cards for Local SEO
        </h2>
        <p className="top-para">
        Local SEO is the practice of optimizing your online presence to attract more business from relevant local searches. For individuals and businesses that operate in specific geographic areas, local SEO can be a powerful tool for driving traffic and generating leads. Google People Cards can play a key role in your local SEO strategy by helping you rank higher in local search results.
        </p>
        <h4>Include Location Information</h4>
        <p>When creating your Google People Card, be sure to include your location in the relevant section. This is especially important for professionals and businesses that rely on local clients or customers. By including your location, you increase the chances of appearing in local search results when someone in your area searches for services or expertise related to your field.</p>
        <h4>Optimize for Local Keywords</h4>
        <p>
        In addition to including your location, it’s also important to optimize your People Card with local keywords. For example, if you’re a photographer in Sydney, including the keyword “Sydney photographer” in your bio can help you rank higher in local searches related to photography services in Sydney.
        </p>
        <h4>Encourage Reviews and Testimonials</h4>
        <p>Reviews and testimonials play a significant role in local SEO, as they help to establish trust and credibility with potential clients or customers. If applicable, consider including links to platforms where you have received positive reviews, such as Google My Business or Yelp, in your People Card. This can further enhance your online reputation and increase your chances of being found in local searches.</p>

        <h2 id="role-eeat-in-google-people-cards">The Role of EEAT in Google People Cards</h2>
        <p>Google’s search algorithm places a strong emphasis on the concept of EEAT, which stands for Experience, Expertise, Authoritativeness, and Trustworthiness. By incorporating these elements into your Google People Card, you can increase the likelihood of your card being favored by Google’s algorithm and appearing higher in search results.</p>
        <h4>Experience</h4>
        <p>Experience refers to your practical knowledge and skills in your field. In your Google People Card, highlight your years of experience, key projects, and notable achievements. This helps to establish your credibility and authority in your industry.</p>
        <h4>Expertise</h4>
        <p>Expertise goes hand in hand with experience and involves demonstrating your specialized knowledge in a particular area. Include any relevant qualifications, certifications, or awards that showcase your expertise. For example, if you’re an SEO expert, mention any certifications from Google, HubSpot, or other recognized institutions.</p>
        <h4>Authoritativeness</h4>
        <p>Authoritativeness is about establishing yourself as a thought leader in your field. This can be achieved by linking to published articles, blog posts, or guest posts on reputable websites. Additionally, if you’ve been quoted in industry publications or have spoken at conferences, include these in your People Card to further enhance your authority.</p>
        <h4>Trustworthiness</h4>
        <p>Trustworthiness is built by providing accurate and reliable information. Ensure that all the details in your People Card are up to date and verifiable. Including links to your official website and social media profiles can also help to establish trust with potential clients or employers.</p>
        </div>
      </>
    ),
    lastp:
      "Google People Cards are a valuable tool for anyone looking to enhance their online presence and SEO strategy. By creating and optimizing your People Card, you can increase your visibility in search results, establish your authority in your field, and drive more traffic to your website or social media profiles. Whether you’re a freelancer, entrepreneur, or professional, leveraging Google People Cards can help you stand out in the crowded digital landscape and achieve your online marketing goals.",

    Fieldtags: (
      <>
        <div className="container mt-1">
          <div className="row">
            <div className="col">
              <div className="d-flex flex-wrap">
                <Link to="/" className="btn btn-outline-secondary m-1">
                Create Google Search profile
                </Link>
                <Link
                  to="/website-designing"
                  className="btn btn-outline-secondary m-1"
                >
                  How to get on Google search
                </Link>
                <Link
                  to="/responsive-website"
                  className="btn btn-outline-secondary m-1"
                >
                  Add your name to Google Search
                </Link>
                <Link
                  to="/digital-marketing"
                  className="btn btn-outline-secondary m-1"
                >
                  Boost Google Search presence
                </Link>
                <Link to="/" className="btn btn-outline-secondary m-1">
                Optimize Google profile

                </Link>
                <Link to="/" className="btn btn-outline-secondary m-1">
                Set up Google Search card
                </Link>
                <Link to="/" className="btn btn-outline-secondary m-1">
                Add Me To Search
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  };

  return (
    <Layout
      title={data?.title}
      keywords={data?.keywords}
      description={data?.description}
      canonical={"https://www.bytefaze.com/blog/add-me-to-search-google-people-card-for-seo/"}
      ldName={"Website Designing For SEO"}
      ldImage={img1}
      serviceType={"Website Designing For SEO"}
    >
      {/* <!-- Featured 1 - Bootstrap Brain Component --> */}
      <section className="py-3 py-md-5 py-xl-8 ">
        <div className="container">
          <div
            className="row gy-3 gy-lg-0 align-items-lg-center justify-content-lg-between"
            style={{ color: "var(--link-color)" }}
          >
            <div className="col-12 col-lg-7 order-1 order-lg-0">
              <div className="entry-header mb-3">
                <ul className="entry-meta list-unstyled d-flex mb-3">
                  <li>
                    <a
                      className="d-inline-flex px-2 py-1 link-accent text-accent-emphasis bg-accent-subtle border border-accent-subtle rounded-2 text-decoration-none fs-7"
                      href="#"
                    >
                      {data?.label}
                    </a>
                  </li>
                </ul>
                <h1 className="display-3 fw-bold mb-4">{data?.PageTitle}</h1>
              </div>
              <div className="entry-footer">
                <ul className="entry-meta list-unstyled d-flex align-items-center m-0">
                  <li>
                    <a
                      className="fs-7  text-decoration-none d-flex align-items-center"
                      href="#"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fillRule="currentColor"
                        className="bi bi-calendar3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                        <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                      </svg>
                      <span
                        className="ms-2 fs-7"
                        style={{ color: "var(--link-color)" }}
                      >
                        {data?.date}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <a
                className="d-block bsb-hover-image overflow-hidden rounded"
                href="#"
              >
                <img 
                  className="img-fluid bsb-scale bsb-hover-scale-up"
                  src={img1}
                  alt="Add Me to Search Feature or Google People Cards for SEO"
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Main --> */}
      <main id="main">
        {/* <!-- Entry Content Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10">
          <div className="container">
            <div className="row justify-content-md-center gy-3 gy-xl-4">
              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
                {data?.desc}
                {/* <p className="lead fw-bold">{data?.desc} <a href="#">emotional support</a> in a way that is healthy for both you and your partner.</p> */}
              </div>

              <div className="col-12 text-center">
                <img 
                  className="img-fluid my-4 rounded"
                  src={img1}
                  alt="Add me to Google Search"
                />
              </div>

              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
                {data?.points}
                <p>{data?.points2}</p>
                {/* <h2 style={{color:"var(--link-color)"}}>Offer Physical Affection</h2> */}
                {/* <img  className="img-fluid mt-3 mb-5 rounded" src={img1} alt=""/> */}
                <p>{data?.lastp}</p>
                {/* <p>Different people like different levels of physical affection at different times. If you are feeling unsure, communicate with your partner so you can learn what they are open to receiving and can provide it accordingly.</p> */}
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Entry Meta --> */}
        <div className="pb-3 pb-md-5 pb-xl-10">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
                <span
                  className="me-1 fw-bold"
                  style={{ color: "var(--link-color)" }}
                >
                  {" "}
                  Tags:
                </span>
                <ul className="entry-meta list-unstyled d-inline-flex align-items-center justify-content-center mb-3">
                  {data.Fieldtags}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Post Pagination 2 - Bootstrap Brain Component --> */}
        <section className="bsb-post-pagination-2  py-0 py-md-0 py-xl-0 bsb-section-py-xxl-1X">
          <div className="container overflow-hidden">
            <div className="row justify-content-lg-center">
              <div className="col-12 col-lg-10 col-xl-8 col-xxl-7"></div>
            </div>
          </div>
        </section>

        {/* <!-- Author 2 - Bootstrap Brain Component --> */}
        <section className="bsb-author-2 py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1X">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-8 col-xxl-7">
                <div className=" border-light-subtle p-4 border">
                  <div className="row gy-3 align-items-center">
                    <div className="col-md-4">
                      <a
                        href="#"
                        className="bsb-hover-image d-block rounded overflow-hidden"
                      >
                        <img 
                          className="img-fluid author-avatar bsb-scale bsb-hover-scale-up"
                          src={logo}
                          alt="Website Designing Company"
                        />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h6
                          className="card-subtitle mb-2  "
                          style={{ color: "var(--link-color)" }}
                        >
                          Article written by
                        </h6>
                        <h3 className="card-title mb-2">
                          <a
                            className="card-link  text-decoration-none"
                            href="#"
                          >
                            Bytefaze
                          </a>
                        </h3>
                        <p className="card-text mb-3">
                          We are skilled in all aspects of web design, from
                          planning and development to design and testing. I also
                          have experience in web development technologies.
                        </p>
                        <ul className="bsb-social-media nav m-0">
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              to="https://www.facebook.com/bytefazewebsolutionsahmedabad/"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-facebook"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                              </svg>
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                  <a className="nav-link " href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fillRule="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                    </svg>
                  </a>
                </li> */}
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              to="https://x.com/Bytefaze"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-twitter"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                              </svg>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              href="https://www.linkedin.com/company/bytefaze/"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-linkedin"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                              </svg>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Blog 5 - Bootstrap Brain Component --> */}
        <section className="bsb-blog-5 py-3 py-md-5 py-xl-8 bsb-section-py-xxl-1X ">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12">
                <h2
                  className="h4 mb-4 mb-md-5"
                  style={{ color: "var(--link-color)" }}
                >
                  Related Articles
                </h2>
              </div>
            </div>
          </div>

          {/* <div className="container overflow-hidden px-4"> */}
          {/* <div className="row gy-4 gy-md-5 gx-xl-6 gy-xl-6 gx-xxl-9 gy-xxl-9"> */}
          <Card />
          {/* </div> */}
          {/* </div> */}
        </section>

        {/* <!-- Newsletter 1 - Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1 bg-accent">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-9 col-lg-8 col-xl-8 col-xxl-7">
                <h2
                  className="display-4 fw-bold mb-4 mb-md-5 mb-xxl-6 text-center text-accent-emphasis"
                  style={{ color: "var(--link-color)" }}
                >
                  Sign up for our newsletter and never miss a thing.
                </h2>
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
                <form className="row gy-3 gy-lg-0 gx-lg-2 justify-content-center">
                  <div className="col-12 col-lg-8">
                    <label
                      htmlFor="email-newsletter-component"
                      className="visually-hidden"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control bsb-form-control-3xl"
                      id="email-newsletter-component"
                      placeholder="Email Address"
                      aria-label="email-newsletter-component"
                      aria-describedby="email-newsletter-help"
                      required
                    />
                    <div
                      id="email-newsletter-help"
                      className="form-text text-center text-lg-start"
                    >
                      We'll never share your email with anyone else.
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 text-center text-lg-start">
                    <button type="submit" className="btn border bsb-btn-3xl">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Addmetogooglesearch;
