import React from "react";
import Layout from "../../layout/Layouts";
import img1 from "../../../assets/WebpImages/live.webp";
import { Link } from "react-router-dom";
import Card from '../Card'
import logo from "../../../assets/WebpImages/bytedark.webp";
//import { img } from "react-lazy-load-image-component";
import angular from "../../../assets/WebpImages/Angular.webp";
import php from "../../../assets/WebpImages/php.webp";
import reactImg from "../../../assets/WebpImages/reactImg.webp";
import nodejsImg from "../../../assets/WebpImages/nodejs.webp";
import python from "../../../assets/WebpImages/python.webp";
import laravel from "../../../assets/WebpImages/laravel.webp";
import uiuxImg from "../../../assets/WebpImages/uiux-web-design.webp";
import iosImg from "../../../assets/WebpImages/ios.webp";

const LiveProjectTraining = () => {
  const data = {
    tag: "Live Project Training in Ahmedabad",
    description:
      "Bytefaze offer Live Project Training in Ahmedabad, Free demo Internship for MCA, BCA, BE, B Tech, MSC IT, BSC IT, MBA students with 100% Job placement - Call for Free Demo",
    keywords:
      "Live Project Training, IT Training, Final Year Project Training In Ahmedabad, Free Live Project Training In Ahmedabad, Free Demo Internship",
    img: img1,
    altTag: "Live Project Training in Ahmedabad", //image Alt tag
    label: "Live Project Training in Ahmedabad",
    title:
      "Live Project Training in Ahmedabad | Final Year Project Training & Internship",
    PageTitle: "Live Project Training in Ahmedabad",
    date: "6 June 2024",
    desc: (
      <p className="lead fw-bold">
        Empower yourself with the practical skills and live project training
        experience to become a sought-after web developer in Ahmedabad. ByteFaze
        Web Solutions offers comprehensive Live Project Training programs
        designed to equip you with the latest technologies used in the industry.
      </p>
    ),
    img2: img1,
    points: (
      <>
        <div className="container w-75">
          <div className="row  item-align-center col-md-12 text-center">
            <table className="table border">
              <thead>
                <tr>
                  <th style={{ color: "var(--link-color)" }}>
                    Table of Contents
                  </th>
                </tr>
              </thead>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#internship-in-web-technologies">
                    Master the Technologies Shaping the Web
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#targeted-training-program-ahmedabad">
                    Targeted Training to Master In-Demand Skills
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#free-live-project-training-internship-ahmedabad">
                    Free Live Project Training & Internship
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#live-project-training-with-jobs-ahmedabad">
                    Live Project Training with Job Placement Assistance
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#final-year-project-training-ahmedabad">
                    Final Year Project Training in Ahmedabad
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#benifits-of-live-project">
                    Benefits of Live Project Training with ByteFaze Web
                    Solutions
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
        {/* point1 */}

        <h2
          style={{ color: "var(--link-color)" }}
          id="internship-in-web-technologies"
          className="mt-2"
        >
          Master the Technologies Shaping the Web:
        </h2>
        <div className="top-para">

   
        <p>
          Our{" "}
          <Link to="/internships">
            <b>web development project training programs</b>{" "}
          </Link>
          focus on in-demand frameworks and languages like:
        </p>
        </div>

        {/* point1 */}
        <div className="row d-flex justify-content-around">
          <div className="flip-card mb-5 col-md-3">
            <div className="flip-card-inner">
              <div className="flip-card-front ">
                <img               
                  src={php}
                  alt="Internship in PHP"
                  className="img-fluid flip-img px-2"
                  style={{ width: "300px", height: "300px" }}
                />
                <h3>Php</h3>
              </div>
              <div className="flip-card-back px-3">
                <span>
                  <Link to="/php-internship">
                    PHP Live Project Training & Internship
                  </Link>
                </span>
              </div>
            </div>
          </div>

          <div className="flip-card mb-5 col-md-3">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img 
                  src={angular}
                  alt="Internship in Angular JS"
                  className="img-fluid flip-img"
                  style={{ width: "300px", height: "300px" }}
                />
                <h3>Angular Js</h3>
              </div>
              <div className="flip-card-back px-3">
                <p>
                  <Link to="/angular-internship">
                    Angular JS Live Project Training & Internship
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="flip-card mb-5 col-md-3">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img 
                  src={reactImg}
                  alt="Internship in React JS"
                  className="img-fluid flip-img"
                  style={{ width: "300px", height: "300px" }}
                />
                <h3>React Js</h3>
              </div>
              <div className="flip-card-back px-3">
                <p>
                  <Link to="/react-internship">
                    {" "}
                    React JS Live Project Training & Internship
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="flip-card mb-5 col-md-3">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img 
                  src={python}
                  alt="Internship in Phyton"
                  className="img-fluid flip-img"
                  style={{ width: "300px", height: "300px" }}
                />
                <h3>Python</h3>
              </div>
              <div className="flip-card-back px-3">
                <p>
                  <Link to="/python-internship">
                    Python Live Project Training & Internship
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="flip-card mb-5 col-md-3">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img 
                  src={nodejsImg}
                  alt="Internship in Node JS"
                  className="img-fluid flip-img px-3"
                  style={{ width: "300px", height: "300px" }}
                />
                <h3>Node Js</h3>
              </div>
              <div className="flip-card-back px-3">
                <p>
                  <Link to="/node-internship">
                    Node JS Live Project Training & Internship
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="flip-card mb-5 col-md-3">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img 
                  src={iosImg}
                  alt="Internship in IOS"
                  className="img-fluid flip-img px-1"
                  style={{ width: "300px", height: "300px" }}
                />
                <h3>IOS</h3>
              </div>
              <div className="flip-card-back px-3">
                <p>
                  <Link to="/ios-internship">
                    IOS Live Project Training & Internship
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="flip-card mb-5 col-md-3">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img 
                  src={laravel}
                  alt="Internship in Laravel"
                  className="img-fluid flip-img px-3"
                  style={{ width: "300px", height: "300px" }}
                />
                <h3>Laravel</h3>
              </div>
              <div className="flip-card-back px-3">
                <p>
                  <Link to="/laravel-internship">
                    Laravel Live Project Training & Internship
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="flip-card mb-5 col-md-3">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img 
                  src={uiuxImg}
                  alt="Internship in UI/UX"
                  className="img-fluid flip-img px-3"
                  style={{ width: "300px", height: "300px" }}
                />
                <h3>UI/UX Design</h3>
              </div>
              <div className="flip-card-back px-3">
                <p className="text-justify">
                  <Link to="/uiux-internship">
                    UI/UX Live Project Training & Internship
                  </Link>{" "}
                </p>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        {/* point3 */}
        <h3
          style={{ color: "var(--link-color)" }}
          id="targeted-training-program-ahmedabad"
        >
          Targeted Training to Master In-Demand Skills:
        </h3>
        <p className="top-para">
          We offer focused Live Project Training programs in web development and
          other in-demand fields. Learn the specific skills employers are
          looking for. Dive deep into a specific technology like{" "}
          <b>React JS or Python</b> to become an expert. Master both front-end
          (user interface) and back-end (server-side) development with a
          practical knowledge. We equip you with the precise skillset employers
          actively seek, making you a strong candidate in today's competitive
          job market.
        </p>

        {/* point3*/}

        <h3
          className="pt-2"
          style={{ color: "var(--link-color)" }}
          id="free-live-project-training-internship-ahmedabad"
        >
          Free Live Project Training & Internship
        </h3>
        <p className="top-para">
          Get hands-on experience working alongside seasoned professionals on
          real-world projects through our{" "}
          <b>Free Live Project Training & Internship program.</b> This not only
          equips you with practical skills but also allows you to build a strong
          portfolio of completed projects to showcase your abilities to
          potential employers.
        </p>

        <h3
          className="pt-2"
          style={{ color: "var(--link-color)" }}
          id="live-project-training-with-jobs-ahmedabad"
        >
          Live Project Training with Job Placement Assistance
        </h3>
        <p className="top-para">
          At Bytefaze, we know technical skills are just one piece of the
          puzzle. That's why our <b>Live Project Training with Job Placement</b>{" "}
          Assistance goes beyond the classroom. You'll gain hands-on experience
          working on real-world projects alongside our award-winning team. But
          we don't stop there. We'll equip you with the soft skills you need to
          land your dream job. Our program provides valuable career guidance,
          hones your resume writing abilities, and offers mock interview
          opportunities to boost your confidence and interview skills. So, take
          your IT career aspirations to the next level with Bytefaze's Live
          Project Training with 100% Job Placement Guarantee.
        </p>
        <h3
          className="pt-2"
          style={{ color: "var(--link-color)" }}
          id="final-year-project-training-ahmedabad"
        >
          Final Year Project Training in Ahmedabad
        </h3>
        <p className="top-para">
          At Bytefaze, we understand the importance of a strong final year
          project for IT students in Ahmedabad. That's why we offer{" "}
          <b>Final Year Project Training </b>designed to empower you and shape
          your career journey.
        </p>
        <p className="top-para">
          Our program goes beyond just technical guidance. We provide expert
          mentorship throughout the project development process, ensuring you
          not only meet academic standards but also gain practical knowledge
          that sets you apart. Work alongside our industry-experienced team to
          gain real-world insights and tackle problems faced in professional
          settings.
        </p>
        <p className="top-para">
          Whether you're an ultimate semester student seeking that final push or
          a fresher looking to solidify your foundation, our{" "}
          <b>Final Year Live Project Training </b>equips you with the skills and
          confidence to impress potential employers. Refine your technical
          expertise, develop problem-solving abilities, and gain valuable
          project management experience – all under the guidance of our expert
          mentors. Take control of your final year and shape your IT career with
          Bytefaze's <b>Live Project Training for Final Year students.</b>
        </p>
        <h4
          className="pt-2"
          style={{ color: "var(--link-color)" }}
          id="benifits-of-live-project"
        >
          Benefits of <b>Live Project Training</b> with ByteFaze Web Solutions:
        </h4>
        <ul style={{ color: "var(--link-color)" }}>
          <li>
            <b>Learn by Doing</b>
          </li>
          <li>
            <b>Develop In-Demand Skills</b>
          </li>
          <li>
            <b>Build a Strong Portfolio</b>
          </li>
          <li>
            <b>Gain Industry Insights from Experts</b>
          </li>
          <li>
            <b>Increase Your Employability</b>
          </li>
        </ul>

        {/* point4*/}
        <h3
          className="pt-2"
          style={{ color: "var(--link-color)" }}
          id="importance-of-web-design"
        >
          Ready to take the next step?
        </h3>
        <p className="top-para">
          Contact ByteFaze Web Solutions today to learn more about our Live
          Project Training programs and how they can help you achieve your web
          development career goals.
        </p>
        <div className="d-flex justify-content-center ">
          <Link to="/contact-us">
            <button className="button-85 mb-3 col-12 col-lg-12 mt-2">
              Book for Free Demo Internship
            </button>
          </Link>
        </div>

        {/* point5*/}
      </>
    ),
    lastp:
      "Want to know web designing and web developer in Ahmedabad, Please contact us by email or phone. We will be delighted to assist you with your Web Development Requiremnt inquiry.",

    // "In today's digital world, having a strong online presence is essential for any local business. A well-designed website acts as your digital storefront, attracting new customers and keeping existing ones engaged. Here's a breakdown of website design services specifically tailored for local businesses:"

    Fieldtags: (
      <>
        <div className="container mt-1">
          <div className="row">
            <div className="col">
              <div className="d-flex flex-wrap">
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Live Project Training In Ahmedabad
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Final Year Project Training In Ahmedabad{" "}
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Free Live Project Training
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Final Year Internship on Live Projects
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Free Project Training Ahmedabad
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Live Project Training for BTech Students
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  SEO (Search Engine Optimization)
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  IT Internship Ahmedabad
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  15 Days Summer Internship in Ahmedabad
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Website Development Internship Ahmedabad
                </Link>
                <Link to="/#" className="btn btn-outline-secondary m-1">
                  Learn Web Designing
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  };

  return (
    <Layout
      title={data?.title}
      keywords={data?.keywords}
      description={data?.description}
      canonical={"https://www.bytefaze.com/blog/live-project-training-ahmedabad/"}
      ldName={"Live Project Training In Ahmedabad"}
      ldImage={img1}
    >
      {/* <!-- Featured 1 - Bootstrap Brain Component --> */}
      <section className="py-3 py-md-5 py-xl-8 ">
        <div className="container">
          <div
            className="row gy-3 gy-lg-0 align-items-lg-center justify-content-lg-between"
            style={{ color: "var(--link-color)" }}
          >
            <div className="col-12 col-lg-7 order-1 order-lg-0">
              <div className="entry-header mb-3">
                <ul className="entry-meta list-unstyled d-flex mb-3">
                  <li>
                    <a
                      className="d-inline-flex px-2 py-1 link-accent text-accent-emphasis bg-accent-subtle border border-accent-subtle rounded-2 text-decoration-none fs-7"
                      href="#"
                    >
                      {data?.label}
                    </a>
                  </li>
                </ul>
                <h1 className="display-3 fw-bold mb-4">{data?.PageTitle}</h1>
              </div>
              <div className="entry-footer">
                <ul className="entry-meta list-unstyled d-flex align-items-center m-0">
                  <li>
                    <a
                      className="fs-7  text-decoration-none d-flex align-items-center"
                      href="#"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fillRule="currentColor"
                        className="bi bi-calendar3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                        <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                      </svg>
                      <span
                        className="ms-2 fs-7"
                        style={{ color: "var(--link-color)" }}
                      >
                        {data?.date}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <a
                className="d-block bsb-hover-image overflow-hidden rounded"
                href="#"
              >
                <img 
                  className="img-fluid bsb-scale bsb-hover-scale-up"
                  src={img1}
                  alt="Live Project Training & Internship Ahmedabad at Best IT Company"
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Main --> */}
      <main id="main">
        {/* <!-- Entry Content Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10">
          <div className="container">
            <div className="row justify-content-md-center gy-3 gy-xl-4">
              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
                {data?.desc}
                {/* <p className="lead fw-bold">{data?.desc} <a href="#">emotional support</a> in a way that is healthy for both you and your partner.</p> */}
              </div>

              <div className="col-12 text-center">
                <img 
                  className="img-fluid my-4 rounded"
                  src={img1}
                  alt="Live Project Internship in Ahmedabad"
                />
              </div>

              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
                {data?.points}
                <p>{data?.points2}</p>
                {/* <h2 style={{color:"var(--link-color)"}}>Offer Physical Affection</h2> */}
                {/* <img  className="img-fluid mt-3 mb-5 rounded" src={img1} alt=""/> */}
                <p>{data?.lastp}</p>
                {/* <p>Different people like different levels of physical affection at different times. If you are feeling unsure, communicate with your partner so you can learn what they are open to receiving and can provide it accordingly.</p> */}
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Entry Meta --> */}
        <div className="pb-3 pb-md-5 pb-xl-10">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
                <span
                  className="me-1 fw-bold"
                  style={{ color: "var(--link-color)" }}
                >
                  {" "}
                  Tags:
                </span>
                <ul className="entry-meta list-unstyled d-inline-flex align-items-center justify-content-center mb-3">
                  {data.Fieldtags}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Post Pagination 2 - Bootstrap Brain Component --> */}
        <section className="bsb-post-pagination-2  py-0 py-md-0 py-xl-0 bsb-section-py-xxl-1X">
          <div className="container overflow-hidden">
            <div className="row justify-content-lg-center">
              <div className="col-12 col-lg-10 col-xl-8 col-xxl-7"></div>
            </div>
          </div>
        </section>

        {/* <!-- Author 2 - Bootstrap Brain Component --> */}
        <section className="bsb-author-2 py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1X">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-8 col-xxl-7">
                <div className=" border-light-subtle p-4 border">
                  <div className="row gy-3 align-items-center">
                    <div className="col-md-4">
                      <a
                        href="#"
                        className="bsb-hover-image d-block rounded overflow-hidden"
                      >
                        <img 
                          className="img-fluid author-avatar bsb-scale bsb-hover-scale-up"
                          src={logo}
                          alt="Best Web Designing Ahmedabad"
                        />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h6
                          className="card-subtitle mb-2  "
                          style={{ color: "var(--link-color)" }}
                        >
                          Article written by
                        </h6>
                        <h3 className="card-title mb-2">
                          <a
                            className="card-link  text-decoration-none"
                            href="#"
                          >
                            Bytefaze
                          </a>
                        </h3>
                        <p className="card-text mb-3">
                          We are skilled in all aspects of web design, from
                          planning and development to design and testing. I also
                          have experience in web development technologies.
                        </p>
                        <ul className="bsb-social-media nav m-0">
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              to="https://www.facebook.com/bytefazewebsolutionsahmedabad/"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-facebook"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                              </svg>
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                  <a className="nav-link " href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fillRule="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                    </svg>
                  </a>
                </li> */}
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              to="https://x.com/Bytefaze"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-twitter"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                              </svg>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              href="https://www.linkedin.com/company/bytefaze/"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-linkedin"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                              </svg>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Blog 5 - Bootstrap Brain Component --> */}
        <section className="bsb-blog-5 py-3 py-md-5 py-xl-8 bsb-section-py-xxl-1X ">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12">
                <h2
                  className="h4 mb-4 mb-md-5"
                  style={{ color: "var(--link-color)" }}
                >
                  Related Articles
                </h2>
              </div>
            </div>
          </div>

          {/* <div className="container overflow-hidden px-4"> */}
          {/* <div className="row gy-4 gy-md-5 gx-xl-6 gy-xl-6 gx-xxl-9 gy-xxl-9"> */}
          <Card />
          {/* </div> */}
          {/* </div> */}
        </section>

        {/* <!-- Newsletter 1 - Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1 bg-accent">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-9 col-lg-8 col-xl-8 col-xxl-7">
                <h2
                  className="display-4 fw-bold mb-4 mb-md-5 mb-xxl-6 text-center text-accent-emphasis"
                  style={{ color: "var(--link-color)" }}
                >
                  Sign up for our newsletter and never miss a thing.
                </h2>
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
                <form className="row gy-3 gy-lg-0 gx-lg-2 justify-content-center">
                  <div className="col-12 col-lg-8">
                    <label
                      htmlFor="email-newsletter-component"
                      className="visually-hidden"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control bsb-form-control-3xl"
                      id="email-newsletter-component"
                      placeholder="Email Address"
                      aria-label="email-newsletter-component"
                      aria-describedby="email-newsletter-help"
                      required
                    />
                    <div
                      id="email-newsletter-help"
                      className="form-text text-center text-lg-start"
                    >
                      We'll never share your email with anyone else.
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 text-center text-lg-start">
                    <button type="submit" className="btn border bsb-btn-3xl">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default LiveProjectTraining;
