import React from 'react'
//import "../../Styles/styles.css";
import Layout from "../layout/Layouts";
import rimg from '../../assets/WebpImages/react1.webp'
import mit from '../../assets/WebpImages/freact.webp'
import team from '../../assets/WebpImages/download1.webp'
import mitting from '../../assets/WebpImages/teach.webp'
// import 'react-lazy-load-image-component/src/effects/blur.css';




const Ract = () => {
  return (
    <div>
        <Layout>
          
        <>
        <div className="container w-50 ">
          <div className="row">
            <h1 className="col-md-12 heading  text-center main-heading  mt-5  ">
              We are Hiring React devlopers
            </h1>
          </div>
        </div>

        <div className="container w-75 mt-2 ">
          <div className="row ">
            <p className=" heading-small col-md-12 text-center  mt-2 w-100 ">
            In the fast-paced world of software development, finding and hiring
             skilled React developers can be a challenging task. React, a JavaScript
              library for building user interfaces, has gained immense popularity due
               to its efficiency, flexibility, and robust ecosystem. With its widespread
                adoption by companies of all sizes, the demand for proficient React developers 
                continues to soar. Whether 
            </p>
          </div>
        </div>

        <div className="container   font01">
          <div className=" row  mt-3 mb-5 d-flex justify-content-center text-center gap-3 ">

            
            <div className="col-md-12 col-sm-12 col-lg-3 border padd  mb-2 ">
              <i className=" ri-checkbox-circle-line"></i>
              Competitive salary

            </div>

            <div className="col-md-12  col-sm-12 col-lg-3  border padd mb-2 text-al task ">

              <i className="   ri-checkbox-circle-line"></i>
              Flexible work hours
            </div>
            <div className="col-md-12 col-sm-12 col-lg-3 border mb-2 padd ">
   

              <i className="   ri-checkbox-circle-line"></i>
              Team outings or events
              
            </div>
          </div>
        </div>

        <section className="hire-developer">
          <div className="container w-75">
            <div className="row  py-5">
              <h2 className=" col-12 b-heading text-center mt-4">
               Bytefaze company , projects, or benefits to attract top talent.

              </h2>
              <h6 className=" small-heading-2 col-12 b-heading text-center ">
                {" "}
                Participating in code reviews, providing feedback, and 
                contributing to the continuous improvement of our development processes.
              </h6>

              <div className="col-md-6 mt-4  " >
                <div className="classWithPad height border1 padd bg-white" >
                  <h4> React App Development    </h4>
                  <p className=''>
                  Developing responsive and intuitive user 
                  interfaces using React.js and other front-end technologies.
                  </p>
                </div>
              </div>

              <div className="col-md-6 mt-4 ">
                <div className="classWithPad height border1 padd bg-white">
                  <h4> React Web Development </h4>
                  <p>
                  Collaborating with designers and other developers to translate wireframes and mockups  high-quality code.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-4   ">
                <div className="classWithPad border1   padd bg-white">
                  <h4> To succeed in this role, you'll need:</h4>
                  <p>
                    {" "}
                    Building reusable components and libraries that
                     streamline development and promote code maintainability.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-4 ">
                <div className="classWithPad border1 padd bg-white">
                  <h4>React desktop App Development </h4>
                  <p>
                  Optimizing front-end performance to ensure fast load times and smooth user interactions.
                   front-end 
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-4 ">
                <div className="classWithPad border1 padd  bg-white">
                  <h4>React App Widget customization </h4>
                  <p>
                  Participating in code reviews, providing feedback,
                   and contributing to the continuous improvement of our desk
                  </p>
                </div>
              </div>

              <div className="col-md-6 mt-4">
                <div className="classWithPad border1   padd  bg-white ">
                  <h4>React App migration support </h4>
                  <p>
                  Staying up-to-date with the latest trends and 
                   practices in front-end development, and sharing knowled
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
   <section className="hire">
        <div className="container mt-5 text-center">
          <div className="row">
            <div className="col-12">
              <h3 className="">
                {" "}
                Looking for a Good React developers ?
              </h3>
              <p>
                {" "}
                come together to build groundbreaking solutions. We're currently
                 seeking skilled React developers to join our team and contribute
                  to our exciting projects. If you're passionate about front-end 
                  development and eager to work in a dynamic and collaborative
                   environment, we 
              </p>
              <button className="btn btn-primary   mb-5">BOOK A CALL</button>
            </div>
          </div>
        </div>
        </section>

        <div className="gapes">
          <div className="container w-75 py-3  ">
            <div className="row ">
              <div className="col-md-12  ">
                <h4 className="text-center mt-3">
                  Dedicated hiring Models we follow
                </h4>
                <p className="text-center mt-3">
                Strong proficiency in JavaScript, with a solid understanding of the language fundamentals and modern ES6+ syntax.
                Extensive experience with React.js and its core concepts, including state management, hooks, and functional components.
                </p>
              </div>
            </div>
          </div>

          <div className="container py-2">
            <div className="row d-flex justify-content-between">
              <div className="col-md-6 ">
                <div className="classWithPad blue mb-4 ">
                  <h3 className="text-dark ">Emerging your skills with us </h3>
                  <p>
                  Excellent problem-solving skills and attention to detail,
                   with a passion for writing clean, maintainable code.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-sm-12  ">
                <div className="classWithPad orange mb-4  ">
                  <h3 className="text-dark">
                    Try to be a good developer.
                  </h3>
                  <p>
                  Familiarity with Agile methodologies, promoting iterative development and adaptability.
                  Experience with us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="middle-heading ">
          <div className="container w-75 py-3 mt-5">
            <div className="row ">
              <div className="col-md-12   text-center">
                <div className="set">
                  <h2 className=" d-flex justify-content-center align-items-center b-heading mt-4">
                    {" "}
                     We're on the lookout for talented React developers to join
                     our team and be part of something extraordinary.
                  </h2>
                </div>
              </div>
              <div className="col-md-12">
                <h6 className=" py-3 w-100 text-center">
                Effective communication and teamwork skills, with the ability to work collaboratively in a fast-paced environment.
                A bachelor's degree in Computer Science, Engineering, or a related field is preferred but not required.
                </h6>
              </div>

              <div className="container-fluid  ">
                <div className="row d-flex justify-content-between  ">
                  <div className="col-md-6 col-sm-12   col-lg-3  mist01  mt-4">
                    <div className="classWithPad padding border fact ">
                    <i className="ri-share-circle-line"></i>
                     
                      <h5 >1.better journey of growth </h5>
                      <p>
                        {" "}
                        Integrating front-end components with backend services and APIs, ensuring seamless data flow  functionality.
                        create impactful .                 
                        

                       
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12   col-lg-3 mist01  mt-4">
                    <div className="classWithPad  padding border fact">
                    <i className="ri-account-pin-circle-line"></i>
                      
                      <h5>2.Work restful with us   </h5>
                      <p className="">
                        {" "}
                        Experience working with RESTful APIs and asynchronous programming techniques.
                      Familiarity with version control systems .
                      
                      
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12   col-lg-3 mist01 mt-4">
                    <div className="classWithPad padding border fact ">
                      <i className="ri-checkbox-circle-fill"></i>
                     
                      <h5> 3. Excellent of problem solving</h5>
                      <p className="">
                        {" "}
                        Understanding of web accessibility standards and best practices.
                        Previous experience working in Agile/Scrum .
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12   col-lg-3 mist01 mt-4   ">
                    <div className="classWithPad border  padding align-items-center  fact">
                    <i className="ri-computer-line"></i>
                     
                      <h5> 4.Onboard & begin the  </h5>
                      <p className="">
                        {" "}
                        front-end development and eager to work on exciting projects in a dynamic and collaborative
                         environment, then you've 
                      

                        
                      </p>
              
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="who-we-are ">
          <div className="container  w-100 ">
            <div className="row ">
              <h3 className="text-center mb-4 mt-2">What we assure to provide</h3>
              <div className="col-md-3 hover">
                <p className="border2  text-center">
                <i className="   ri-checkbox-circle-line "></i>
                  {" "}
                  Focus on your career
                </p>
              </div>
              <div className="col-md-3 hover ">
                <p className="border2  text-center">
                <i className="  tick ri-checkbox-circle-line "></i>
                  {" "}
                  Acheive your goal
                </p>
              </div>
              <div className="col-md-3 hover">
                <p className="border2  text-center">
                <i className="  tick ri-checkbox-circle-line "></i>
                  {" "}
               weekly Reporting via e-mail {" "}
                  <i className="  tick ri-checkbox-circle-line "></i>
                </p>
              </div>
              <div className="col-md-3 hover">
                <p className="border2  text-center">
                <i className="   ri-checkbox-circle-line "></i>
                  {" "}
                  A bachelor degree in Computer {" "}
                </p>
              </div>           
           

               
              <div className="col-md-4 mt-2 hover">
                <p className="border2  text-center d-flex justify-content-evenly">
                <i className="   ri-checkbox-circle-line ">
                  {" "}
                  development environments (s)</i>
                </p>  
              </div>
              <div className="col-md-6   mt-2 hover">
                <p className=" border2   text-center  d-flex justify-content-evenly">
           
                  {" "}
                  <i className="   ri-checkbox-circle-line ">
                     Work peacfully with your team.</i>
                </p>
              </div>
             
             
             
             
              </div>
          </div>
        </section>

 

        <section className="About">
          <div className="container">
            <div className="row">
              <h2 className="text-center  display-4 mt-5">About Us</h2>
              <p className="text-center ">
                Crafting cutting-edge digital solution with creative mind
              </p>

             


               <div className="img-wrap">

                <div className="container">
                  <div className="row  d-flex align-items-center justify-content-center justify-content-between">
                  <div className="col-md-3">
                <h6>who we are</h6>
               <p className="who"> React developers should be adept at solving complex problems 
               and implementing solutions efficiently. Assess their ability to troubleshoot issues,
                optimize performance, and work with third-party libraries or tools as needed.
               </p>
              </div>
                    
                     
                     <div className="box-3 col-md-4 d-flex align-items-center justify-content-center justify-content-between ">
                     <h1 className=""> 250+</h1>
                      <div className="">
                    <img   src={rimg} alt='rimg' className="img-fluid"/>
                     </div>
                     
                     </div>
                     <div className="box-2 col-md-4 d-flex align-items-center justify-content-center justify-content-between">
                     <h1 className=""> 13+ </h1>
                     {/* <h1 className="image-text "></h1> */}

                      <div className="">
                    <img   src={mit} alt='mit' className="img-fluid"/>
                     </div>

                
                     
                     </div>
                     <div className="box-21 col-md-4 d-flex align-items-center justify-content-center justify-content-between mt-4 mb-3">
                     <h1 className=""> 143+</h1>
                      <div className="">
                    <img   src={team} alt='team' className="img-fluid"/>
                     </div>
                     
                     
                     </div>
                     <div className="box-22 col-md-4 d-flex align-items-center justify-content-center justify-content-between mt-4 mb-3">
                     <h1 className=""> 13+</h1>
                      <div className="">
                    <img   src={mitting} alt='metting' className="img-fluid"/>
                     </div>
                     
                     </div>
                     
                     <div className="col-md-3">
                <h6 className="">what drives us</h6>
               <p className="who"> Technical assessments, coding challenges, or live coding sessions can 
               provide valuable insights into a candidate's problem-solving skills, coding abilities, and
                familiarity with React best practices
               </p>
              </div>

                     
                    
                

                  </div>
                </div>
               </div>
            </div>
          
          </div>
        </section>
      </>
            
        </Layout>
      

    </div>
  )
}

export default Ract