import React from "react";
import arvr from "../../assets/Images/Arvr.json";
import Lottie from "react-lottie";

import { Link } from "react-router-dom";
import Layouts from "../layout/Layouts";
import { MdArrowForwardIos } from "react-icons/md";

import {
  FaCube,
  FaGraduationCap,
  FaTools,
  FaUserShield,
  FaLaptopCode,
  FaGamepad,
} from "react-icons/fa";
import {
  FaChalkboardTeacher,
  FaUsers,
  FaRegLightbulb,
  FaVrCardboard,
} from "react-icons/fa";
import IndustriesComponent from "./IndustriesComponent";
import DevicesComponent from "./DevicesComponent";
import ArvrContact from "./ArvrContact";

const ArVrpage = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: arvr,
    // rendererSettings: {
    //   preserveAspectRatio: "xMidYMid slice"
    // }
  };

  return (
    <Layouts title={"Virtual Reality App Development Company in Ahmedabad"}
    keywords={
      "Ar VR App Development Company, Virtual Reality Developer in Ahmedabad, Virtual Reality Services, Custom VR App Developer, VR Development"
    }
    description={
      "Bytefaze is Ahmedabad located Virtual Reality App Developement Company, delivering custom AR VR development Services at affordable prices"
    }
    canonical={"https://www.bytefaze.com/virtual-reality"}
    ldName={"Virtual Reality App Developer"}
    serviceType={"Virtual Reality App Development Service"}>
      <div>
        {/* <div>
        
        <h1>Image</h1>
        <div className='lottie-container'>  
        <Lottie options={defaultOptions}  />
        </div>

      </div> */}

        <div className="arvr-section">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 order-2 order-lg-1 mt-2">
                <div>
                  <h1 className="arvr-heading"style={{color:"var(--link-color)"}}>
                    Virtual Reality App Development Company
                  </h1>
                  <p className="top-para mt-4">
                    At Bytefaze, we are at the forefront of AR/VR development services,
                    dedicated to delivering unparalleled virtual reality
                    experiences. Our commitment is to create immersive and
                    captivating visual environments that facilitate seamless
                    human interaction through cutting-edge technology. We
                    specialize in crafting sophisticated virtual spaces designed
                    to elevate engagement and redefine user experiences with
                    precision and excellence.
                  </p>
                  <div className="mt-4 text-center d-flex flex-column flex-lg-row align-items-center">
                    <Link
                      to="/contact-us"
                      className="button-33 mb-3 mb-lg-0 col-12 col-lg-4"
                    >
                      Consult Our Experts <MdArrowForwardIos />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center">
                <div className="lottie-container">
                  <Lottie options={defaultOptions} />
                </div>
              </div>
            </div>
          </div>

          <div className="container text-center my-5 arvr-service top-para">
  <h2 className="mb-4">Our Virtual Reality Development Services</h2>
  <p className="mb-5">
    At Bytefaze, we specialize in crafting immersive virtual reality experiences tailored for a range of applications including entertainment, business enhancement, and training simulations. Our VR solutions are engineered to deliver seamless and impactful interactions, leveraging cutting-edge technology to create engaging and effective virtual environments.
  </p>
  <div className="row">
    <div className="col-md-4 mb-4">
      <div className="service-box-arvr p-4">
        <FaCube className="service-icon-arvr arvr-icon mb-3" />
        <h4 className="service-title-arvr">VR Product Demonstration</h4>
        <p className="service-description-arvr">
          Enhance your brand's marketing strategy with immersive VR product demonstrations. Our solutions provide a 360° virtual environment that allows users to interact with and experience your products in a dynamic and engaging way, fostering a deeper understanding and connection with your offerings.
        </p>
      </div>
    </div>
    <div className="col-md-4 mb-4">
      <div className="service-box-arvr p-4">
        <FaGraduationCap className="service-icon-arvr arvr-icon mb-3" />
        <h4 className="service-title-arvr">VR Training & Simulation</h4>
        <p className="service-description-arvr">
          Transform training and educational programs with our VR solutions. Designed for universities, corporations, and schools, our VR training systems provide a safe and immersive environment for learning and skill development, making complex or high-risk procedures accessible and manageable.
        </p>
      </div>
    </div>
    <div className="col-md-4 mb-4">
      <div className="service-box-arvr p-4">
        <FaTools className="service-icon-arvr arvr-icon mb-3" />
        <h4 className="service-title-arvr">VR Orientation Programs</h4>
        <p className="service-description-arvr">
          Streamline orientation processes with our VR solutions. By investing in a one-time VR development, organizations and universities can offer a highly engaging and interactive orientation experience, reducing the need for repetitive in-person sessions and enhancing overall participant engagement.
        </p>
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-md-4 mb-4">
      <div className="service-box-arvr p-4">
        <FaUserShield className="service-icon-arvr arvr-icon mb-3" />
        <h4 className="service-title-arvr">VR Safety Training</h4>
        <p className="service-description-arvr">
          Ensure a safe working environment with our VR safety training solutions. Bytefaze provides comprehensive VR applications that simulate hazardous situations, allowing users to experience and practice safety protocols in a controlled and risk-free setting.
        </p>
      </div>
    </div>
    <div className="col-md-4 mb-4">
      <div className="service-box-arvr p-4">
        <FaLaptopCode className="service-icon-arvr arvr-icon mb-3" />
        <h4 className="service-title-arvr">LMS VR</h4>
        <p className="service-description-arvr">
          Our LMS VR solutions offer an innovative approach to monitoring and evaluating performance. Bytefaze's VR applications facilitate real-time assessments and feedback, enabling users to track their progress and development in a fully immersive environment.
        </p>
      </div>
    </div>
    <div className="col-md-4 mb-4">
      <div className="service-box-arvr p-4">
        <FaGamepad className="service-icon-arvr arvr-icon mb-3" />
        <h4 className="service-title-arvr">VR for Gaming & Entertainment</h4>
        <p className="service-description-arvr">
          Dive into the world of gaming and entertainment with our cutting-edge VR development services. Bytefaze delivers immersive VR experiences that captivate and engage users, offering unparalleled levels of interaction and enjoyment for gaming enthusiasts and entertainment seekers alike.
        </p>
      </div>
    </div>
  </div>
</div>


<div className="container business-content text-center top-para py-5">
  <h2 className="mb-4">How to Utilize Virtual Reality for Business</h2>
  <div className="row">
    {/* Training Programs */}
    <div className="col-md-3 mb-4 d-flex">
      <div className="business-card text-white p-3 w-100">
        <div className="mb-3">
          <FaChalkboardTeacher size={30} className="arvr-icon" />
        </div>
        <h5 className="card-title-arvr">Enhanced Training Solutions</h5>
        <p className="card-text-arvr">
          Leverage Virtual Reality to transform training programs into immersive, engaging experiences. Our VR solutions enhance learning outcomes by providing interactive and realistic simulations that improve skill acquisition and retention.
        </p>
      </div>
    </div>

    {/* Virtual Events */}
    <div className="col-md-3 mb-4 d-flex">
      <div className="business-card text-white p-3 w-100">
        <div className="mb-3">
          <FaUsers size={30} className="arvr-icon" />
        </div>
        <h5 className="card-title-arvr">Immersive Virtual Events</h5>
        <p className="card-text-arvr">
          Elevate your events with Virtual Reality by creating fully immersive and interactive environments. Our VR solutions allow participants to engage with events in innovative ways, enhancing their overall experience and participation.
        </p>
      </div>
    </div>

    {/* Immersive Learnings */}
    <div className="col-md-3 mb-4 d-flex">
      <div className="business-card text-white p-3 w-100">
        <div className="mb-3">
          <FaRegLightbulb size={30} className="arvr-icon" />
        </div>
        <h5 className="card-title-arvr">Interactive Learning Environments</h5>
        <p className="card-text-arvr">
          Transform traditional learning methods with immersive Virtual Reality experiences. Our solutions offer interactive and engaging learning environments that enhance comprehension and retention through realistic and hands-on experiences.
        </p>
      </div>
    </div>

    {/* Interactive Engagements */}
    <div className="col-md-3 mb-4 d-flex">
      <div className="business-card text-white p-3 w-100">
        <div className="mb-3">
          <FaVrCardboard size={30} className="arvr-icon" />
        </div>
        <h5 className="card-title-arvr">Dynamic Interactive Experiences</h5>
        <p className="card-text-arvr">
          Experience unparalleled interaction with our VR development solutions. Bytefaze offers VR applications that immerse users in interactive and dynamic virtual worlds, creating memorable and engaging experiences that stand out.
        </p>
      </div>
    </div>
  </div>
</div>


          <section className="py-3 py-md-5 py-xl-8 d-flex align-items-center justify-content-center  arvrbg">
            <div className="container mt-5">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-6">
                  <div className="internparal">
                    <h2 className="top-heading text-center">
                    Develop your Virtual Reality Application with the help of Our VR Experts
                    </h2>
                    <hr
                      style={{
                        borderTop: "3px solid #ffc107",
                        width: "100px",
                        margin: "20px auto",
                        opacity: "1",
                      }}
                    />
                    {/* <p className="top-para">
                       Qualcomm Technologies is uniquely positioned to deliver superior VR experiences
                      by designing for the extreme requirements of fully
                      immersive mobile VR. By taking a system approach and
                      custom designing specialized engines across the
                      system-on-chip (SoC), Qualcomm® Snapdragon™ XR1 Platforms
                      are engineered to provide an efficient heterogeneous
                      computing solution that is optimized end-to-end for VR.
                      Our solutions power many leading mobile VR solutions
                      today, and we are working with the VR ecosystem to make
                      our vision of truly immersive mobile VR a reality.
                    </p> */}

                    <div className="d-flex justify-content-center">
                      <Link to="/internship-program" className="btn-100">
                        Contact Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <IndustriesComponent />
          <DevicesComponent />
          <ArvrContact/>  
        </div>
      </div>
    </Layouts>
  );
};

export default ArVrpage;
