import React from 'react';
import { CiLocationArrow1 } from "react-icons/ci";
const QuickLinks = ({ links, searchKeywords }) => {
  return (
    <div style={{backgroundColor: "var(--footer-bg)", padding: "50px 0"}} className="cta-section mb-4">
    <div className="row container " >
      <div className="col-md-6">
        <h4>Other Links :</h4>
        <ul className="list-unstyled">
          {links?.map((link, index) => (
            <li key={index} className="mb-2">
              <a href={link?.url} target="_blank" rel="noopener noreferrer" className="text-decoration-none top-para">
                <span className="me-2 top-para" ><CiLocationArrow1 /></span> {link?.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="col-md-6">
        <h4>People Search us for :</h4>
        <p>
          {searchKeywords.map((keyword, index) => (
            <span key={index}>
              {keyword}{index < searchKeywords?.length - 1 ? ', ' : ''}
            </span>
          ))}
        </p>
      </div>
    </div>
    |</div>
  );
};

export default QuickLinks;
