import React from "react";

import Demo from "./Demo";
import QuickLinks from "./QuickLinks";
import Layouts from "../layout/Layouts";
import webdesign from "../../assets/WebpImages/webdesign.webp";
import res2 from "../../assets/WebpImages/res2.webp";
import web3 from "../../assets/WebpImages/web32.webp";
import ecom1 from "../../assets/WebpImages/website-maintenance.webp"
import res from "../../assets/WebpImages/responsive-website.webp";

const WebDesignParis = () => {
  const heroSection = {
    backgroundImage: "https://via.placeholder.com/1600x400",
    heading: "Web Design in Paris ",
    paragraph:
      "Transform Your Digital Presence with Bytefaze's Premier Web Design & development services in Paris",
  };
  const contentArray = [
    {
      image: webdesign,
      heading: "Best Website Designer in Paris",
      text: "In the vibrant Parisian market, standing out online is crucial. As the Best Website Designer in Paris, we create tailored website designs that perfectly reflect your brand identity. Whether you’re a startup or an established business, our designs ensure your website captures the essence of your brand and engages your target audience effectively.",
    },
    {
      image: res2,
      heading: "Professional Website Development",
      text: "Our professional website development services are built on a foundation of innovation and quality. We use the latest technologies and industry best practices to create websites that are both beautiful and robust. From simple brochure sites to complex web applications, we have the expertise to develop solutions that meet your specific needs.",
    },
    {
      image: web3,
      heading: "Mobile-Friendly and Responsive Designs",
      text: "In today’s digital landscape, having a mobile-friendly website is essential. We design responsive websites that provide an optimal viewing experience across all devices, ensuring your website looks great and functions flawlessly on desktops, tablets, and smartphones.",
    },
    {
        image: res,
        heading: "Custom Web Application Development",
        text: "We understand that every business is unique, which is why we offer custom web application development services tailored to your specific requirements. Whether you need a custom CMS, a unique e-commerce solution, or a specialized web application, our team can develop solutions that enhance your business operations and provide a seamless user experience.",
      },
      {
        image: ecom1,
        heading: "Website Redesign & Maintenance",
        text: "Is your current website outdated or not performing as well as you’d like? Our website redesign services will give your site a fresh, modern look while enhancing its functionality. We focus on improving user experience and ensuring that your website aligns with your current business goals. Keeping your website secure, up-to-date, and running smoothly is essential for maintaining a strong online presence. Our comprehensive website maintenance services provide ongoing support, including regular updates and monitoring, to ensure your site performs at its best.",
      },
    
    // more content objects
  ];

  const ctaContent = {
    text: "Take the Next Step Towards Digital Excellence!",
    para : "Elevate your online presence with our expert Website Design in Paris. Partner with us, the Best Web Design Agency for Parisian businesses, and experience the difference that professional, custom-designed websites can make.",
    buttonLabel: "Request A Meeting",
  };

  const ctaIndex = 4;

  const textSections = [
    {
        index: 0,
        heading: "Custom Website Design & Development Services",
        text: "As a top web design company based in India, we specialize in delivering creative, professional, and mobile-friendly websites that cater to the unique needs of businesses in Paris. Our goal is to help you establish a strong online presence that resonates with your audience and drives tangible results.",
      },
    {
      index: 4,
      heading: "Why Choose Us?",
      text: "As the Best Web Design Agency for businesses in Paris, we offer a range of benefits that set us apart: <ul><li><b>Tailored Solutions: </b>We create custom web solutions that cater specifically to the needs of Parisian businesses.</li><li><b>Experienced Team:</b> Our designers and developers are experts in the latest web technologies and trends.</li><li><b>Mobile-Friendly Designs: </b> We ensure your website delivers a seamless experience across all devices.</li><li><b>Comprehensive Services: </b> From initial design to maintenance and custom applications, we provide a full range of web services.</li><li><b>Client Satisfaction:</b> Your satisfaction is our priority. We work closely with you to ensure your website aligns with your business goals.</li></ul>",
    },
    
    // Add as many text sections as needed
  ];

  const linksData = [
    {
      text: "Custom Website Designing Company in New York",
      url: "/website-design-development-in-newyork",
    },
    { text: "Professional Web Development Services Tokyo", url: "/web-design-development-in-tokyo" },
    { text: "Website Redesign Services London", url: "/web-design-development-in-london" },
    { text: "Affordable Web Design in Los Angeles ", url: "/web-design-development-in-losangeles" },
    { text: "Mobile Friendly Web Application in San Joes", url:"/web-design-sanjoes"}
    
    // Add more links as needed
  ];

  const searchKeywordsData = [
    "Best Website Designer in Paris",
    "Website Design Paris",
    "Best Web Design Agency in Paris","Professional Website Development Paris","Mobile-Friendly Web Developer in Paris","Website Redesign Services in Paris", "Custom Web Application Design Agency in Paris","Website Maintainace in Paris"
    // Add more keywords as needed
  ];

  return (

    <Layouts
      title={"Custom & Affordable Web Design & Development in Paris, France"}
      keywords={
        "Best Website Designer in Paris, Website Design Paris, Best Web Design Agency, professional website development, mobile-friendly, website redesign, website maintenance services, custom web application"
      }
      description={
        "Bytefaze is india’s best website designer in Paris, offers professional web design and development, website redesign services. Our USP is customization and affordability."
      }
      canonical={"https://www.bytefaze.com/web-design-paris"}
      ldName={"Website Design & Development in Paris"}
      serviceType={"Web Designing & Development Service in Paris"}
    >

    

      <Demo
        contentArray={contentArray}
        textSections={textSections}
        ctaContent={ctaContent}
        ctaIndex={ctaIndex}
        heroSection={heroSection}
        myQuicklinks={<QuickLinks links={linksData} searchKeywords={searchKeywordsData} />}
      />
  </Layouts>


  );
};

export default WebDesignParis;
