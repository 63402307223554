import React, { useEffect, useState } from 'react'
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import { Outlet, useLocation } from 'react-router-dom'
import ParticlesContainer from '../pages/ParticlesContainer'
import Socialbtns from '../common/Socialbtns'

const OutletLayout = () => {
   
    const ScrollToTop = () => {
        // Extracts pathname property(key) from an object
        const { pathname } = useLocation();
      
        // Automatically scrolls to top whenever pathname changes 
        useEffect(() => {
          window.scrollTo(0, 0);
        }, [pathname]);
      }
      
      
    const current_theme = sessionStorage.getItem("current_theme");
    const [theme, setTheme] = useState(current_theme ? current_theme : "light");
    const toggleTheme = () => {
      theme === "" ? setTheme("light-theme") : setTheme("");
    };
    useEffect(() => {
        sessionStorage.setItem("current_theme", theme);
        document.body.className = theme;
      }, [theme]);
  return (
    <>
   <Navbar theme={theme} toggleTheme={toggleTheme} />
   <ScrollToTop />
   {theme === "light-theme" ? <ParticlesContainer /> : ""}
   <Outlet />
   <Socialbtns />
   <Footer />
   </>
  )
}

export default OutletLayout