
import React from "react";

import Demo from "./Demo";
import QuickLinks from "./QuickLinks";
import Layouts from "../layout/Layouts";
import webdesign from "../../assets/WebpImages/webdesign.webp";
import res2 from "../../assets/WebpImages/res2.webp";
import web3 from "../../assets/WebpImages/web32.webp";
import ecom1 from "../../assets/WebpImages/ecom1.webp";
import res from "../../assets/WebpImages/responsive-website.webp";

const WebDesignSingapore = () => {
  const heroSection = {
    backgroundImage: "https://via.placeholder.com/1600x400",
    heading: "Web Design in Singapore ",
    paragraph:
      "Transform Your Digital Presence with Bytefaze's Premier Web Design & development services in Singapore",
  };
  const contentArray = [
    {
      image: webdesign,
      heading: "Custom Website Design",
      text: "Beyond mere aesthetics, our custom website design services are crafted to captivate your audience and drive meaningful engagement. We meticulously analyze your brand's essence, target market, and business objectives to create a website that is not only visually stunning but also strategically aligned with your goals. By incorporating intuitive navigation, compelling storytelling, and optimized user flows, we ensure that your website becomes a powerful tool for generating leads, building brand awareness, and driving conversions.",
    },
    {
        image: res2,
        heading: "Website Redesign",
        text: "A website redesign is more than just a cosmetic overhaul. It's an opportunity to breathe new life into your online presence and elevate your brand. Our expert team will meticulously analyze your existing website's performance, identify areas for improvement, and create a redesigned version that is not only visually appealing but also optimized for search engines and user experience. We'll focus on enhancing site speed, improving navigation, and incorporating modern design elements to ensure your website stands out in today's competitive digital landscape.",
      },
      {
        image: web3,
        heading: "Mobile Application Development",
        text: "In today's mobile-centric world, a well-designed mobile app can be a game-changer for your business. Our mobile app development services focus on creating native apps that offer exceptional performance, intuitive user interfaces, and seamless integration with your existing systems. Whether you need an app for internal use or to engage with your customers, we'll leverage the latest technologies and industry best practices to deliver a solution that meets your specific needs and exceeds your expectations.",
      },
      {
          image: res,
          heading: "Web App Development ",
          text: "Web applications are essential for businesses looking to streamline processes, improve efficiency, and enhance collaboration. Our web app development services involve creating custom solutions tailored to your unique requirements. We'll work closely with you to understand your business goals and challenges, and then design and develop web apps that are scalable, secure, and easy to use. From CRM systems to project management tools, our web apps can help you optimize your operations and gain a competitive edge.",
        },
        {
          image: ecom1,
          heading: "Ecommerce Web Design",
          text: "In the world of online retail, your ecommerce website is your storefront. Our ecommerce web design services are designed to create a seamless and engaging shopping experience for your customers. We'll work closely with you to understand your unique business requirements, integrate essential features like product catalogs, shopping carts, and secure payment gateways, and optimize your website for mobile devices to cater to the growing trend of m-commerce. By providing a user-friendly and visually appealing shopping environment, we'll help you maximize conversions and drive sales.",
        },
    
    // more content objects
  ];

  const ctaContent = {
    text: "Ready to take your Singapore business to the next level?",
    para : "Contact Bytefaze Web Solutions today to discuss your project and explore how we can help you achieve your online goals.",
    buttonLabel: "Request A Meeting",
  };

  const ctaIndex = 0;

  const textSections = [
    {
        index: 0,
        heading: "Website Designing Comapny in Singapore",
        text: "Bytefaze Web Solutions is a leading India-based web design company specializing in delivering innovative and high-performance digital solutions in Singapore. With a proven track record of success and a deep understanding of the unique needs of Singaporean businesses, we are committed to helping you achieve your online goals. Our team of skilled website designers and developers has years of experience crafting custom and responsive websites that not only look great but also drive results. We work closely with our clients to understand their specific needs and objectives, and we strive to exceed their expectations. Our commitment to excellence is reflected in our attention to detail, our use of cutting-edge technology, and our dedication to customer satisfaction.   ",
      },
    {
      index: 0,
      heading: "Why Choose Us?",
      text: "<ul><li><b>Affordable Packages:</b>Our competitive pricing ensures that you get the most value for your investment.</li><li><b>Expert Team: </b> Expert Team: </li><li><b>Customized Solutions: </b> Customized Solutions: </li><li><b>Customized Solutions: </b> Customized Solutions: </li><li><b>Excellent Customer Support: </b> Our dedicated support team is always available to assist you.</li></ul>",
    },
    
    // Add as many text sections as needed
  ];

  const linksData = [
    {
      text: "Website Redesign Company in Houston",
      url: "/website-design-houston-texas",
    },
    { text: "Custom Web Design Services in San Francisco", url: "/website-design-in-san-francisco" },
    { text: "SEO-Freindly Website Designing Agency in London", url: "/web-design-development-in-london" },
    { text: "Mobile Application Development in New York ", url: "/website-design-development-in-newyork" },
    { text: "Affordable Web Designing Solutions in Tokyo ", url: "" },
    
    // Add more links as needed
  ];

  const searchKeywordsData = [
    "Best Website Designer in Singapore",
    "Web Development Company in Asia",
    "Best Web Designing Services in Singapore","Professional Website Development Singapore","Creative Website Developer in Singapore","SEO-friendly Website Design in Singapore", "SEO-friendly Website Design Agency in Singapore","Website Maintainace in Singapore"
    // Add more keywords as needed
  ];

  return (

    <Layouts
      title={"Web Design Singapore | Custom Website Development Services"}
      keywords={
        "Website Designer Singapore, Affordable Web Design Services in Singapore, Custom Web Application Development, Responsive E-commerce website Designing, seo-friendly website design, website redesign"
      }
      description={
        "Explore custom and affordable website redesigning, web application development, digital marketing services  in Singapore offers by Best website Designer from India."
      }
      canonical={"https://www.bytefaze.com/web-design-singapore"}
      ldName={"Website Design & Development in Singapore"}
      serviceType={"Web Designing & Development Service in Singapore"}
    >

    

      <Demo
        contentArray={contentArray}
        textSections={textSections}
        ctaContent={ctaContent}
        ctaIndex={ctaIndex}
        heroSection={heroSection}
        myQuicklinks={<QuickLinks links={linksData} searchKeywords={searchKeywordsData} />}
      />
  </Layouts>


  );
};

export default WebDesignSingapore;
