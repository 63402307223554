import React, { useEffect, useState } from "react";
import Layout from "../../Admin/layout/Layout";
import axios from "axios";
import { useParams } from "react-router-dom";
import Dashloader from "../../Admin/Dashloader";
//import { LazyLoadImage } from "react-lazy-load-image-component";


const Interndetails = () => {
  const [userdetails, setUserdetails] = useState({});
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const token = sessionStorage.getItem("token");

  const getInternDetail = async () => {
  
    try {
      const res = await axios.get(
        `https://bytefaze-backend.onrender.com/api/v1/formUser/${params?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserdetails(res.data);
    } catch (error) {
      console.log(error, "error isssss ");
    }
  };

  useEffect(() => {
    getInternDetail();
  }, []);


  return (
    <Layout>
  

     
      <div className="container mt-5">
      <h2 className="text-center mb-4">Students Details</h2> 
        <div className="card">
          <div className="card-header h-50 d-flex justify-content-between">
            <h3>Details</h3>
           
          </div>
          {loader ? (
            <div className="admin-load">
              <div>
                <Dashloader/>
              </div>
              ...Loading
              <div>
                <Dashloader />
              </div>
            </div>
          ) : (
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <strong>
                    First Name: &nbsp;&nbsp;{userdetails.firstName}
                  </strong>
                </div>
                <div className="col-md-6 mt-md-0 mt-3" >
                  <strong>
                    Last Name: &nbsp;&nbsp;{userdetails.lastName}
                  </strong>
                </div>
                
              </div>

              <div className="row">
                <div className="col-md-6 mt-3">
                  <strong>Email:&nbsp;&nbsp;{userdetails.email}</strong>
                </div>
                <div className="col-md-6 mt-3">
                  <strong>Phone Number:&nbsp;&nbsp;{userdetails.phoneNumber}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <strong>Age:&nbsp;&nbsp;{userdetails.age}</strong>
                </div>
              
              </div>
              <div className="row">
                <div className="col-md-12 mt-3">
                  <strong>Address:&nbsp;&nbsp;{userdetails.address}</strong>
                </div>
              
              </div>
          

              <div className="row mb-3">
                <div className="col-md-12 mt-3">
                  <strong>Social Media Links:&nbsp;&nbsp;{userdetails?.socialMediaLinks}</strong>
                </div>
            
              </div>

              <div className="row mb-3">
                <div className="col-md-12 mt-3">
                  <strong>Profile:&nbsp;&nbsp;
                    <img  src={userdetails?.profile} alt="" width={50} />
                  </strong>
                </div>
            
              </div>
              <div className="row mb-3">
                <div className="col-md-12 mt-3">
                  <strong>IdProof:&nbsp;&nbsp;
                    <img  src={userdetails?.idProof} alt="" width={50} />
                  </strong>
                </div>
            
              </div>


            </div>
          )}
        </div>
      </div>


       
        

  
    
    </Layout>
  );
};

export default Interndetails;
