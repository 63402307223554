import React from "react";
import PricingSection from "./PricingSection";
import { Link } from "react-router-dom";
//import "../../Styles/styles.css";
import Layouts from "../layout/Layouts";
import basic from "../../assets/pricing/basic.webp";
import medium from "../../assets/pricing/medium.webp";
import premium from "../../assets/pricing/premium.webp";
import main from "../../assets/pricing/main.webp";
//import { LazyLoadImage } from "react-lazy-load-image-component";

const PricingPlan = () => {
  return (
    <Layouts
      title={"Best Website Development Packages India | Web Design Plans & Pricing"}
      keywords={
        "Website Development Packages, Web Design Plant, Website Designing Pricing, Website Design Cost, Website Design Packages For Small Business"
      }
      description={
        "Looking for affordable website development packages ? Our basic web design plans offer customized and responsive websites are perfect for startups, small businesses at very low cost. Contact Now"
      }
      canonical={"https://www.bytefaze.com/web-development-packages-pricing"}
      ldName={"Web Development Packages & Plans"}
      ldImage={main}
      serviceType={"Website Design & Development Pricing"}
    >
      <div className="pricing-plan">



        <div className="hero-section">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 order-2 order-lg-1 mt-2">
                <div>
                  <h1 className="hero-heading">
                    Transparent and Competitive Website Development Pricing
                  </h1>
                  
                  <p className="hero-para mt-4">
                    Discover our clear and affordable pricing plans for website
                    development that cater to businesses of all sizes. Whether
                    you're launching a new site or revamping an existing one,
                    our pricing is designed to deliver the best value without
                    compromising on quality. Get a detailed breakdown of costs,
                    from design to deployment, ensuring you know exactly what
                    you're paying for. Start your project with confidence,
                    knowing that you're investing in a high-quality website that
                    meets your business needs.
                  </p>

                  <div className="hero-buttons mt-4 text-center d-flex flex-column flex-lg-row align-items-center">
                    <a
                      href="#pack"
                      className="button-33  mb-lg-0 col-12 col-lg-3"
                    >
                      View Package
                    </a>
                    <Link
                      to="/portfolio"
                      className="button-33 mx-lg-3 text-center col-12 col-lg-4"
                    >
                      View Portfolio
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center">
                <img
                  src={main}
                  alt="Best Website Development Packages to suit every types of business needs like small business, startups and organizations"
                  className="img-fluid hero-img "
                  width={550}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Pricing Packages Section */}
        <div className="container my-5">
          <h2 className="section-heading text-center mb-4">
            Website Development Packages in India
          </h2>
          <hr
                style={{
                  borderTop: "3px solid #ffc107",
                  width: "100px",
                  margin: "20px auto",
                  opacity: "1",
                }}
              />
          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="card package-card h-100">
                <div className="card-body text-center">
                  <i className="bi bi-palette mb-3 package-icon"></i>
                  <h5 className="card-title">Basic Web Design</h5>
                  <hr
                style={{
                  borderTop: "3px solid #ffc107",
                  width: "10%",
                  margin: "20px auto",
                  opacity: "1",
                }}
              />
                  <p className="card-price">8,000 INR / 120 USD</p>
                  <p className="card-text">
                    <small>
                      Ideal for individuals and startups looking to establish an
                      online presence.
                    </small>
                  </p>
                  <a href="#basic-web-design" className="btn btn-dark">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card package-card h-100">
                <div className="card-body text-center">
                  <i className="bi bi-pencil mb-3 package-icon"></i>
                  <h5 className="card-title">Professional Web Design</h5>
                  <hr
                style={{
                  borderTop: "3px solid #ffc107",
                  width: "10%",
                  margin: "20px auto",
                  opacity: "1",
                }}
              />
                  <p className="card-price">18,000 INR / 220 USD</p>
                  <p className="card-text">
                    <small>
                      Perfect for growing businesses aiming for a more polished,
                      professional look.
                    </small>
                  </p>
                  <a href="#professional-web-design" className="btn btn-dark">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card package-card h-100">
                <div className="card-body text-center">
                  <i className="bi bi-pc-display mb-3 package-icon"></i>
                  <h5 className="card-title">Premium Web Design</h5>
                  <hr
                style={{
                  borderTop: "3px solid #ffc107",
                  width: "10%",
                  margin: "20px auto",
                  opacity: "1",
                }}
              />
                  <p className="card-price">30,000 INR / 370 USD</p>
                  <p className="card-text">
                    <small>
                      Best suited for large enterprises and eCommerce
                      businesses.
                    </small>
                  </p>
                  <a href="#premium-web-design" className="btn btn-dark">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ background: "var(--footer-bg)", padding: "50px 0" }}
          className="cta-section mb-4"
        >
          <div className="container text-center">
            <h3
              className="top-para text-center">
              Want to Know How Much Should a Website Cost?
              </h3>
              <p>Get instant pricing of your business website or ecommerce website with our free online web design cost calculator tool</p>
              <p><b>Get Online estimate Now ! (In INR and USD)</b></p>
            <div className="d-flex justify-content-center mb-2">
              <Link to="/website-design-cost-calculator">
                <button className="button-33 mt-3">
                Website Design Cost Calculator 

                </button>
              </Link>
            </div>
          </div>
        </div>
        {/* Detailed Sections for Each Package */}
        <div id="basic-web-design" className="container my-5">
          <div className="row align-items-center text-center">
            <h2 className="section-heading mb-4">Affordable Website Design and Development Packages for Your Business Success</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src={basic} alt="Basic Web Design & Development Packages" className="img-fluid" />
            </div>
            <div className="col-md-6">
              <h3 className="section-subheading mb-4 top-para">
                Basic Web Design
              </h3>
              <hr
                style={{
                  borderTop: "3px solid #ffc107",
                  width: "10%",
                  margin: "20px",
                  opacity: "1",
                }}
              />
              <p className="section-text">
                Our Basic Web Design package is perfect for individuals and
                startups looking to establish an online presence without
                breaking the bank. This package includes a simple yet elegant
                design, basic SEO optimization, and mobile responsiveness.
              </p>
              <p className="section-text">
                Whether you're a small business owner or an individual wanting
                to showcase your portfolio, our Basic Web Design package
                provides all the essential features to get you started. You'll
                receive a user-friendly website that reflects your brand's
                identity while ensuring accessibility across different devices.
              </p>
              <p className="section-text">
                Additionally, this package offers a quick turnaround time,
                making it ideal for those who need to go live with their website
                quickly. We ensure that your site is optimized for search
                engines, giving you a head start in building an online presence
                that attracts visitors and potential customers.
              </p>
            </div>
          </div>
        </div>

        <div id="professional-web-design" className="container my-5">
          <div className="row align-items-center">
            <div className="col-md-6 order-md-2">
              <img
                src={medium}
                alt="Professional Web Design & Development Plans"
                className="img-fluid"
              />
            </div>
            <div className="col-md-6 order-md-1">
              <h3 className="section-subheading mb-4 top-para">
                Professional Web Design
              </h3>
              <hr
                style={{
                  borderTop: "3px solid #ffc107",
                  width: "10%",
                  margin: "20px ",
                  opacity: "1",
                }}
              />
              <p className="section-text">
                The Professional Web Design package is ideal for businesses
                aiming for a more polished and professional look. This package
                offers advanced design features, enhanced SEO, and integration
                with social media platforms.
              </p>
              <p className="section-text">
                Designed to cater to growing businesses, this package ensures
                that your website stands out in a competitive market. You'll
                benefit from a custom design that aligns with your brand's
                vision and message, making your website an effective tool for
                customer engagement and lead generation.
              </p>
              <p className="section-text">
                Furthermore, the Professional Web Design package includes
                additional services such as analytics integration, allowing you
                to track your website's performance and make data-driven
                decisions. With a focus on user experience and functionality,
                this package is tailored to meet the needs of businesses looking
                to elevate their online presence.
              </p>
            </div>
          </div>
        </div>

        <div id="premium-web-design" className="container my-5">

          <div className="row align-items-center">
            <div className="col-md-6">
              <img
                src={premium}
                alt="Premium Web Design & Development Packages and Pricing"
                className="img-fluid"
              />
            </div>
            <div className="col-md-6">
              <h3 className="section-subheading mb-4 top-para">
                Premium Web Design
              </h3>
              <hr
                style={{
                  borderTop: "3px solid #ffc107",
                  width: "10%",
                  margin: "20px",
                  opacity: "1",
                }}
              />
              <p className="section-text">
                Our Premium Web Design package is tailored for large enterprises
                and eCommerce businesses requiring a robust and comprehensive
                online presence. This package includes custom design, full
                eCommerce functionality, and ongoing maintenance and support.
              </p>
              <p className="section-text">
                This package is ideal for companies that need more than just a
                standard website. It offers a fully customized design that
                reflects the scale and sophistication of your business, ensuring
                a powerful online representation. The Premium Web Design package
                is perfect for those who require advanced features such as
                dynamic content, complex user interactions, and integration with
                third-party services.
              </p>
              <p className="section-text">
                In addition to the design and development, our team provides
                ongoing maintenance and support, ensuring that your website
                remains up-to-date and secure. This package also includes
                comprehensive training for your team, so you can effectively
                manage your website's content and functionality. With the
                Premium Web Design package, you'll have all the tools needed to
                drive your business forward in the digital age.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="pack">

        <PricingSection />
      </div>
      <div
          style={{ background: "var(--footer-bg)", padding: "50px 0" }}
          className="cta-section mb-4"
        >
          <div className="container text-center">
            <h3
              className="top-para text-center">
              Are you Looking For Custom Quotation ?
              </h3>
              <p>Receive a tailored estimate of website design within one hour after you send us the details</p>
             
            <div className="d-flex justify-content-center mb-2">
              <Link to="/contact-us">
                <button className="button-33 mt-3">
                Request Proposal

                </button>
              </Link>
            </div>
          </div>
        </div>
    </Layouts>
  );
};

export default PricingPlan;
