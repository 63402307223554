import React from "react";

import Demo from "./Demo";
import QuickLinks from "./QuickLinks";
import Layouts from "../layout/Layouts";
import webdesign from "../../assets/WebpImages/webdesign.webp";
import res2 from "../../assets/WebpImages/res2.webp";
import web3 from "../../assets/WebpImages/web32.webp";

const WebDesignTokyo = () => {
  const heroSection = {
    backgroundImage: "https://via.placeholder.com/1600x400",
    heading: "Web Design in Tokyo ",
    paragraph:
      "Transform Your Digital Presence with Bytefaze's Premier Web Design & development services in Tokyo",
  };
  const contentArray = [
    {
      image: webdesign,
      heading: "Custom Website Design in Tokyo",
      text: "In a city as dynamic as Tokyo, your website should stand out. We create custom website designs that are not only visually captivating but also highly responsive. Whether you’re a startup or an established business, our designs are tailored to reflect your brand’s identity and engage your audience effectively. With a focus on user experience (UX) and user interface (UI), we ensure that your website is not just a digital brochure but a powerful tool for business growth.",
    },
    {
      image: res2,
      heading: "Best Website Design Services in Tokyo",
      text: "Our commitment to excellence makes us one of the best website design services in Tokyo. We combine creativity with technology to deliver websites that are both aesthetically pleasing and functionally robust. From corporate websites to complex ecommerce platforms, our solutions are designed to help you succeed in the competitive Tokyo market.",
    },
    {
      image: web3,
      heading: "Web Development Company in Tokyo",
      text: "As a leading web development company, we go beyond design. We offer full-cycle web development services, from initial concept to final deployment. Our team is proficient in the latest technologies such as React JS, Node JS, Advance PHP, ensuring your website is fast, secure, and scalable. Whether you need a simple content management system (CMS) or a fully integrated ecommerce solution, we have the expertise to deliver.",
    },
    
    // more content objects
  ];

  const ctaContent = {
    text: "Take the Next Step Towards Digital Excellence!",
    para : "Ready to transform your online presence? Contact us today to discuss your website design needs in Tokyo. Let’s create a website that not only represents your brand but drives your business forward. Get in touch now and let’s build something extraordinary together!",
    buttonLabel: "Request A Meeting",
  };

  const ctaIndex = 2;

  const textSections = [
    {
      index: 0,
      heading: "Top Website Designing Company",
      text: "Elevate your online presence with Bytefaze Web Solutions - the trusted web development company, specializing in creative and functional website design in Tokyo. Our team, based in Ahmedabad, India, brings global expertise to your doorstep, offering the best website design services tailored to meet the unique demands of the Tokyo market.",
    },
    {
      index: 2,
      heading: "Why Choose Us?",
      text: "<ul><li><b>Global Expertise, Local Insights:</b> We understand the Tokyo market and tailor our solutions to meet its unique needs.</li><li><b>Custom Solutions:</b> Our designs are customized to reflect your brand and engage your audience.</li><li><b>End-to-End Services:</b> From design to development and digital marketing, we offer comprehensive solutions.</li></ul>",
    },

    // Add as many text sections as needed
  ];

  const linksData = [
    {
      text: "Custom Web Design in Boston",
      url: "/web-design-boston",
    },
    { text: "Best Website Redesign Services London ", url: "/web-design-development-in-london" },
    { text: "Website Designing in New York", url: "website-design-development-in-newyork" },
    { text: "Web Application Development Los Angeles", url: "web-design-development-in-losangeles" },
    { text: "Affordable Website Development Paris", url: "/web-design-paris" },
    
    // Add more links as needed
  ];

  const searchKeywordsData = [
    "Top Web Designers in Tokyo",
    "Web Development Company in Japan",
    "Best Web Designing Services in Tokyo","Customized Web Designing Services","Mobile Optimized Web Apps Developer","Web Marketing Agency in Tokyo", "Social Media Marketing in Tokyo",
    // Add more keywords as needed
  ];

  return (

    <Layouts
      title={"Best Web Design & Development Company in Tokyo"}
      keywords={
        "Website Design in Tokyo, Custom website Design Tokyo, Best Website Design Services in Tokyo, Web Development company in Tokyo"
      }
      description={
        "India’s trusted Website design & development company offering custom web design, Responsive Ecommerce Website, Application Development, Digital Marketing services in Tokyo with timely delivery."
      }
      canonical={"https://www.bytefaze.com/web-design-development-in-tokyo"}
      ldName={"Web Design & Development in Tokyo"}
      serviceType={"Website Designing & Development Service in Tokyo"}
    >

    

      <Demo
        contentArray={contentArray}
        textSections={textSections}
        ctaContent={ctaContent}
        ctaIndex={ctaIndex}
        heroSection={heroSection}
        myQuicklinks={<QuickLinks links={linksData} searchKeywords={searchKeywordsData} />}
      />
  </Layouts>


  );
};

export default WebDesignTokyo;
