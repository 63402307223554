import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
//import { LazyLoadImage } from "react-lazy-load-image-component";
import Layout from "../layout/Layouts";
// import "../../Styles/Services.css";
import logo1 from "../../assets/WebpImages/webs-design.webp";
import logo2 from "../../assets/WebpImages/logo2.webp";

import { Link } from "react-router-dom";
const LandingPage = () => {
  return (
    <Layout 
    title={"Custom Landing Page Website Designing Company in Ahmedabad | Bytefaze"}
    keywords={"Landing Page Website, Landing Page Creation, Landing Page Web Design Services, Responsive Landing Webage Developer"} 
    description={"Boost your conversions with our expert landing page design services. We specialize in creating visually stunning, user-friendly, and data-driven landing page websites that increase CTR and leads"}
    canonical={"https://www.bytefaze.com/landing-page-website-designing"}
    ldName={"Landing Page Website Designing"}
    serviceType={"Landing Page Web Design Services"}
    ldImage={logo2}
    >

<div style={{height:"250px"}} className="col-12  webdesign_bg">
  <h1 className="display-4 fw-bold">Landing Page Website Designing</h1>
  <p className="text-center col-6">"Crafting High-Converting Landing Pages with Bytefaze, Your Gateway to More Leads and Conversions"</p>
  <div className='d-flex justify-content-center '>
             <Link to="/contact-us">
              <button className="button-33 mb-2 col-12 col-lg-12 mt-3">Get A Free Quote Now!</button>
                </Link>
                </div>
</div>

<div>
<section> 
<section className="py-3 py-md-5 py-xl-8">
    <div className="container" id="what-is-landing-page-design">
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-6 d-flex align-items-center justify-content-center">
         <img  
            src={logo1}
            alt="Landing Page Designining in Ahmedabad"
            className="img-fluid mt-1"
            width={500}
          />
        </div>
        <div className="col-lg-6 mt-2">
          <div>
            <h2 className="top-heading">What is Landing Page Design</h2>
            <hr
              style={{
                borderTop: "3px solid #ffc107",
                width: "100px",
                margin: "20px",
                opacity: "1",
              }}
            />
            <p className="top-para">A landing page is a single page website designed to capture visitor attention and drive conversions.  It's where users “land” after clicking on links in emails or ads from platforms like Google, YouTube, Facebook, Instagram, and others. Unlike traditional web pages, landing pages minimize distractions, presenting clear and compelling calls to action (CTAs) that align with campaign goals. For businesses looking to increase leads or sales, our landing page design services create visually appealing, high-performance pages that guide users to take decisive steps.  Whether you're aiming for boosting sales, or promoting a particular product or service, Bytefaze ensure each element—from headlines to CTAs—is optimized for success. 
           </p>
          </div>
        </div>
      </div>
    </div>
    <div style={{ background: "var(--footer-bg)" }} className="mt-5 " >
          <div className="container row  text-center pt-5 pb-5">

            <h3 className="top-para text-center">"Boost your campaign's effectiveness and get measurable results with expertly crafted landing pages tailored to your business goals."</h3>
            <div className="d-flex justify-content-center">
              <Link to="/contact-us">
                <button className="button-33 mb-2 col-12 col-lg-12 mt-3">Request a Quote </button>
              </Link>
            </div>

          </div>
        </div>
    <section className="py-4  mt-3 ">
            <div className="container w-100 h-100">
              <div className="row ">
              <h2 className="text-center top-para">
            Why Choose Us for Your Landing Page Design?
            </h2>
            <hr
              style={{
                borderTop: "3px solid #ffc107",
                width: "100px",
                margin: "20px auto",
                opacity: "1",
              }}
            />
                <p className="small-heading-2 col-12  b-heading text-center">
                Crafting a high-performing landing page requires a blend of creativity, technical expertise, and a deep understanding of user behavior. Our team brings together these elements to deliver landing pages that truly stand out. Here's why you should choose us:
                </p>
                <div className="col-md-6 mt-4">
                  <div className="classWithPad border1 padd">
                    <h4 className="mt-1">Expert Design</h4>
                    <p className="mt-4">
                    Our team of skilled designers specializes in creating landing pages that are visually appealing, user-friendly, and optimized for conversions.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="classWithPad border1 padd">
                    <h4 className="mt-1">Data-Driven Approach</h4>
                    <p className="mt-4">
                    We leverage analytics and A/B testing to ensure your landing pages are performing at their best.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="classWithPad border1 padd">
                    <h4 className="mt-1">Custom Tailoring</h4>
                    <p className="mt-4">
                    Each landing page we design is tailored to your unique brand, target audience, and marketing goals.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="classWithPad border1 padd">
                    <h4 className="mt-1">Responsive Design</h4>
                    <p className="mt-4">
                    Your landing pages will look great on all devices, from desktop computers to smartphones and tablets.
                    </p>
                  </div>
                </div>
                
              </div>
            </div>
          </section>
    
    </section>
  
<div style={{ backgroundColor: "var(--footer-bg)" }}>
          <div className="container row ">
            <div className="row">
              <h2 className="col-md-12 heading text-center col-12 mt-4">
              Our Landing Page Design Services
              </h2>
              <hr
              style={{
                borderTop: "3px solid #ffc107",
                width: "100px",
                margin: "20px auto",
                opacity: "1",
              }}
            />
            </div>
          </div>
          <div className="container w-75 mt-2 ">
            <div className="row ">
              <p className=" heading-small col-md-12 text-center mt-2 w-100 ">
              We'll design a landing page from scratch, incorporating your brand identity and messaging. If you have an existing landing page that's underperforming, we can revamp it to improve its effectiveness. Bytefaze also analyze your landing page's performance and make recommendations for improvements, such as optimizing headlines, calls to action, and content.
              </p>
            </div>
          </div>
          <div className="container  font01">
            <div className=" row  mt-3 mb-5 d-flex justify-content-center text-center gap-3 ">
              <div className="col-md-12 col-sm-12 col-lg-3 border paddi parh  mb-2 ">
                <i className="ri-checkbox-circle-line"></i>
                &nbsp; &nbsp;Landing Page Creation
              </div>
              <div className="col-md-12  col-sm-12 col-lg-3  border paddi mb-2 text-al task ">
                <i className="ri-checkbox-circle-line"></i>
                &nbsp; &nbsp;Landing Page Redesign
              </div>
              <div className="col-md-12 col-sm-12 col-lg-3 border mb-2 paddi ">
                <i className="ri-checkbox-circle-line"></i>
                &nbsp;&nbsp;Landing Page Optimization
              </div>
            </div>
          </div>
        </div>
        <Container className="my-5 how">
          <h2 className="text-center" style={{ color: "var(--link-color" }}>Key Features of Our Landing Pages</h2>
          <hr
              style={{
                borderTop: "3px solid #ffc107",
                width: "100px",
                margin: "20px auto",
                opacity: "1",
              }}
            />
          <Row className="text-center align-items-center">
            <Col md={3} className="position-relative">
              <div className="mb-3">
                <div className="circle mb-2">1</div>
                <h5>Clear and Concise Messaging</h5>
                <p>We'll ensure your landing page communicates your value proposition clearly and effectively.</p>
              </div>
              
            </Col>
            <Col md={3} className="position-relative">
              <div className="mb-3">
                <div className="circle mb-2">2</div>
                <h5>Strong Calls to Action</h5>
                <p>Our landing pages will feature compelling calls to action that encourage visitors to take the desired action.</p>
              </div>
              
            </Col>
            <Col md={3}>
              <div className="mb-3">
                <div className="circle mb-2">3</div>
                <h5>Optimized for Conversions</h5>
                <p>We'll optimize your landing pages for search engines and user experience to maximize conversions.</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="mb-3">
                <div className="circle mb-2">4</div>
                <h5>Mobile-Friendly Design</h5>
                <p>Your landing pages will be fully responsive, ensuring they look great on all devices.</p>
              </div>
            </Col>
          </Row>
        </Container>
        <div style={{ background: "var(--footer-bg)" }} className=" " >
          <div className="container row  text-center pt-5 pb-5">

            <h3 className="top-para text-center">"Contact us today to discuss your landing page design needs. Our team will work closely with you to create a Single Page Website that drives results."</h3>
            <div className="d-flex justify-content-center">
              <Link to="/contact-us">
                <button className="button-33 mb-2 col-12 col-lg-12 mt-3">Schedule a Consultation </button>
              </Link>
            </div>

          </div>
        </div>
            </section>
 
</div>
    </Layout>
  );
};

export default LandingPage;